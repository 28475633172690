import { CloseOutlined, LeftOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, Form, Row, Space, Spin, Table, message } from 'antd'
import ButtonGroup from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
  getQuestionAnswList,
  removeQuestionAnsw,
} from 'reduxs/advertising/questionAnsw'
import AddEditeModal from '../Compontents/AddEditeModal'
import LangModal from '../Compontents/LangModal'
import { columns } from './_help'
import styles from './index.module.less'
let options = {}
const AnswerManage = props => {
  const { history } = props
  const { state, data } = props.location?.state || {}
  const [selectedRowKeys, setSelectedRowKeys] = useState([]) // 选中的数据
  const [editeData, setEditeData] = useState({}) // 编辑存放每一条的数据
  const [loading, setLoading] = useState(false)
  const [pageData, setPageData] = useState([]) // 列表
  useEffect(() => {
    options = { page: 1, limit: 10 }
    getList()
    // getTypeData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取题目列表
  const getList = async () => {
    const { questionTitleId } = data
    setLoading(true)
    const res = await getQuestionAnswList({
      questionTitleId: questionTitleId,
    })
    setLoading(false)
    if (res.result === 200) {
      const data = res.data || []
      data.forEach((item, index) => {
        item.index = index + 1
      })
      setPageData(data || [])
    }
  }
  // 判断只能选择一条数据
  const isSeleteData = () => {
    if (!selectedRowKeys.length) {
      message.warn('请选择数据~')
      return
    }
    if (selectedRowKeys.length > 1) {
      message.warn('只能选择一条数据~')
      return
    }
    const key = selectedRowKeys[0]
    const findData = pageData.find(item => item.questionAnswId === key)
    return findData
  }
  // 编辑
  const handEdite = () => {
    const data = isSeleteData()
    if (data) {
      setEditeData({ ...data, _status: true, isUpdate: true })
    }
  }
  // 多语言
  const handLang = () => {
    const data = isSeleteData()
    if (data) {
      setEditeData({ ...data, langStatus: true })
    }
  }
  // 删除
  const handDelete = async () => {
    const data = isSeleteData()
    if (data) {
      const { questionAnswId } = data
      const res = await removeQuestionAnsw(questionAnswId)
      if (res.result === 200) {
        message.success('删除成功~')
        setSelectedRowKeys([])
        getList()
      }
    }
  }
  // table可选中
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: selectedRowKeys => {
      setSelectedRowKeys([selectedRowKeys[selectedRowKeys.length - 1]])
    },
  }
  // 新增测评
  const handAdd = () => {
    setEditeData({ _status: true })
  }
  // 监听table变化
  const changePage = page => {
    options.page = page.current
    options.limit = page.pageSize
    getList()
  }
  // 搜索
  const onFinish = values => {
    options = { page: 1, limit: 10, ...values }
    getList()
  }
  // 新增编辑成功
  const handSuccess = () => {
    setSelectedRowKeys([])
    setEditeData({})
    getList()
  }
  // 返回
  const handBack = () => {
    history.push({
      pathname: '/index/advertising/subject',
      state: state,
    })
  }
  return (
    <>
      <span className={styles.back} onClick={handBack}>
        <LeftOutlined />
        返回
      </span>
      <div className="pageContainer">
        <Spin tip="加载中" spinning={loading}>
          {editeData._status && (
            <AddEditeModal
              editeData={editeData}
              handleCancel={() => setEditeData({})}
              handSuccess={handSuccess}
              state={state}
              data={data}
            />
          )}
          {editeData.langStatus && (
            <LangModal
              editeData={editeData}
              handleCancel={() => setEditeData({})}
              handSuccess={handSuccess}
              state={state}
              data={data}
            />
          )}
          <Form onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="测评分类">{state?.clasz?.name}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="名称">{state?.title}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="解析方式">{state?.at?.name}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="题目">{data.title}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="数据">0</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="维度">
                  {data?.wd ? (
                    <span className={styles.listItem}>{data.wd?.name}</span>
                  ) : null}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="基准">
                  {data?.jz ? (
                    <span className={styles.listItem}>{data.jz?.name}</span>
                  ) : null}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <div className={styles.buttonList}>
            <Space>
              <ButtonGroup
                onClick={handAdd}
                icon={<PlusOutlined />}
                btnId={btnApiList.answerAdd}
              >
                新增
              </ButtonGroup>
              <ButtonGroup
                onClick={handEdite}
                icon={<PlusOutlined />}
                btnId={btnApiList.answerEdite}
              >
                编辑
              </ButtonGroup>
              <ButtonGroup
                onClick={handLang}
                icon={<PlusOutlined />}
                btnId={btnApiList.answerLang}
              >
                多语言
              </ButtonGroup>
              <ButtonGroup
                onClick={handDelete}
                icon={<CloseOutlined />}
                btnId={btnApiList.answerDelete}
              >
                删除
              </ButtonGroup>
            </Space>
          </div>
          <Table
            dataSource={pageData}
            rowKey={item => item.questionAnswId} //React规范中的array都必须有key
            columns={columns}
            size="small"
            onChange={changePage}
            rowSelection={rowSelection}
            pagination={false}
          />
        </Spin>
      </div>
    </>
  )
}
export default withRouter(AnswerManage)
