import {
  AimOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Col, Form, Modal, Popconfirm, Row, message } from 'antd'
import React, { useEffect, useState } from 'react'
import {
  deleteQuestionCondit,
  questionConditList,
} from 'reduxs/advertising/question'
import AddModal from './Add'
import LangModal from './LangModal'
import styles from './index.module.less'
const DimensionModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel } = props
  const [addData, setAddData] = useState({})
  const [jzList, seJzList] = useState([]) // 基准列表
  const [wdList, setWdList] = useState([]) // 维度列表
  const [activeWd, setActiveWd] = useState(null) // 当前激活的维度
  const [activeJz, setActiveJz] = useState(null) // 当前激活的基准
  useEffect(() => {
    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getList = async () => {
    const { questionInfoId } = editeData
    const res = await questionConditList({
      status: 1,
      paging: false,
      questionInfoId: questionInfoId,
    })
    if (res.result === 200) {
      const data = res.data || []
      let jzList = data.filter(item => item.conditType === 'JZ')
      let wdList = data.filter(item => item.conditType === 'WD')
      seJzList(jzList)
      setWdList(wdList)
    }
  }
  // 添加
  const handAdd = str => {
    setAddData({ ...editeData, conditType: str, _status: true })
  }
  // 编辑维度
  const handEditeWd = () => {
    if (!activeWd) {
      return message.error('请选择维度')
    }
    setAddData({ ...activeWd, _status: true })
  }
  // 编辑基准
  const handEditeJz = () => {
    if (!activeJz) {
      return message.error('请选择基准')
    }
    setAddData({ ...activeJz, _status: true })
  }
  // 维度多语言
  const handLangWd = () => {
    if (!activeWd) {
      return message.error('请选择维度')
    }
    setAddData({ ...activeWd, langStatus: true })
  }
  // 基准多语言
  const handLangJz = () => {
    if (!activeJz) {
      return message.error('请选择基准')
    }
    setAddData({ ...activeJz, langStatus: true })
  }
  //新增编辑成功
  const handOk = () => {
    setAddData({})
    getList()
  }
  const handRemove = async (e, item) => {
    console.log(e)
    e.stopPropagation()
    const { questionConditId } = item
    const res = await deleteQuestionCondit(questionConditId)
    if (res.result === 200) {
      message.success('删除成功~')
      getList()
    }
  }
  return (
    <Modal
      width={600}
      title="维度/基准管理"
      open={editeData?.dimensionStatus}
      onCancel={handleCancel}
      onOk={handleCancel}
      confirmLoading={true}
      footer={[]}
    >
      <div className={styles.formData}>
        {addData._status && (
          <AddModal
            addData={addData}
            handSuccess={handOk}
            handleCancel={() => setAddData({})}
            editeData={editeData}
          />
        )}
        {addData.langStatus && (
          <LangModal
            addData={addData}
            handSuccess={handOk}
            handleCancel={() => setAddData({})}
            editeData={editeData}
          />
        )}

        <Form form={form} initialValues={{ status: true }}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="测评分类">{editeData.clasz?.name}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="名称">{editeData.title}</Form.Item>
            </Col>
            <Col span={24}>
              <div className={styles.list}>
                <span className={styles.title}>维度</span>
                {wdList.map(item => {
                  return (
                    <span
                      className={`${styles.listItem} ${
                        activeWd?.questionConditId === item.questionConditId
                          ? styles.activeItem
                          : ''
                      }`}
                      onClick={() => setActiveWd(item)}
                    >
                      {item.name}
                      <Popconfirm
                        title="你确定要删除该配置吗？"
                        onClick={e => e.stopPropagation()}
                        onConfirm={e => handRemove(e, item)}
                        onCancel={e => e.stopPropagation()}
                        okText="确定"
                        cancelText="取消"
                      >
                        <span className={styles.remove}>
                          <CloseOutlined />
                        </span>
                      </Popconfirm>
                    </span>
                  )
                })}
                <span className={styles.listItem} onClick={() => handAdd('WD')}>
                  <PlusOutlined />
                </span>
                <span className={styles.listItem} onClick={handEditeWd}>
                  <EditOutlined />
                </span>
                <span className={styles.listItem} onClick={handLangWd}>
                  <AimOutlined />
                </span>
              </div>
            </Col>
            <Col span={24}>
              <div className={styles.list}>
                <span className={styles.title}>基准</span>
                {jzList.map(item => {
                  return (
                    <span
                      className={`${styles.listItem} ${
                        activeJz?.questionConditId === item.questionConditId
                          ? styles.activeItem
                          : ''
                      }`}
                      onClick={() => setActiveJz(item)}
                    >
                      {item.name}
                      <span
                        className={styles.remove}
                        onClick={e => handRemove(e, item)}
                      >
                        <CloseOutlined />
                      </span>
                    </span>
                  )
                })}
                <span className={styles.listItem} onClick={() => handAdd('JZ')}>
                  <PlusOutlined style={{ fontSize: '12px' }} />
                </span>
                <span className={styles.listItem} onClick={handEditeJz}>
                  <EditOutlined />
                </span>
                <span className={styles.listItem} onClick={handLangJz}>
                  <AimOutlined />
                </span>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default DimensionModal
