import {
  _addAdvertising,
  _adsCopy,
  _adsEmail,
  _adsExport,
  _adsLangSeq,
  _adsPayment,
  _advertisingDetails,
  _deleteLang,
  _deletePayment,
  _editeAdvertising,
  _getAdsLang,
  _getAdvertisingList,
  _getPayment,
  _removeAdvertising,
  _setAdsLang,
} from 'servers/advertising/advertising'
import { changeList } from '../../../Selectors/Common/tree'
// 获取投放管理列表
export const getAdvertisingList = async (data = {}) => {
  const res = await _getAdvertisingList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除投放管理
export const removeAdvertising = async (data = {}) => {
  const res = await _removeAdvertising(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改投放管理
export const editeAdvertising = async (data = {}) => {
  const res = await _editeAdvertising(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增投放管理
export const addAdvertising = async (data = {}) => {
  const res = await _addAdvertising(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取投放管理详情
export const advertisingDetails = async (data = {}) => {
  const res = await _advertisingDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改邮箱
export const adsEmail = async (data = {}) => {
  const res = await _adsEmail(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取投放语言
export const getAdsLang = async (data = {}) => {
  const res = await _getAdsLang(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改投放语言
export const setAdsLang = async (data = {}) => {
  const res = await _setAdsLang(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改投放语言序号
export const adsLangSeq = async (id1, id2, id3) => {
  const res = await _adsLangSeq(id1, id2, id3)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 投放语言删除
export const deleteLang = async (id, lang) => {
  const res = await _deleteLang(id, lang)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 投放商户绑定
export const adsPayment = async (data = {}) => {
  const res = await _adsPayment(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 删除商户绑定
export const deletePayment = async (id, payId) => {
  const res = await _deletePayment(id, payId)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取投放商户列表
export const getPayment = async (id, payId) => {
  const res = await _getPayment(id, payId)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// adsExport
// 导出投放列表
export const adsExport = async (data = {}) => {
  const res = await _adsExport(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 投放管理复制
export const adsCopy = async (data = {}) => {
  const res = await _adsCopy(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
