import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取支付类型列表
export const _getList = async (params, url) => {
  return await request({
    method: 'GET',
    url: url,
    params: params,
  })
}
// 删除支付类型
export const _removeList = async (url, path) => {
  // const path = btnApiList.RegionManageDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: url,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改支付类型
export const _editeList = async (params, url, path) => {
  // const path = btnApiList.RegionManageEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: url,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增支付类型
export const _addList = async (params, url, path) => {
  // const path = btnApiList.RegionManageAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: url,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取地区详情
export const _getDetails = async (params, url) => {
  return await request({
    method: 'GET',
    url: url,
  })
}
