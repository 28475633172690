import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取大洲列表
export const _getRegionStateList = async params => {
  return await request({
    method: 'GET',
    url: Api.OPERATION.regionState.regionStateList,
    params: params,
  })
}
// 删除大洲
export const _removeRegionState = async params => {
  const path = btnApiList.regionStateDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.OPERATION.regionState.regionStateInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改大洲
export const _editeRegionState = async params => {
  const path = btnApiList.regionStateEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.OPERATION.regionState.regionStateInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增大洲
export const _addRegionState = async params => {
  const path = btnApiList.regionStateAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.OPERATION.regionState.regionStateInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取大洲详情
export const _regionStateDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.regionState.regionStateInfo}/${params}`,
  })
}
