import { Button } from 'antd'
import ButtonGroup, { TipButton } from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React from 'react'
const columns = eventData => {
  const { handEdite, handRemove, handRole, handReset } = eventData
  return [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: '工号',
      dataIndex: 'number',
      key: 'number',
      ellipsis: true,
    },
    {
      title: '登陆账号',
      dataIndex: 'account',
      key: 'account',
      ellipsis: true,
    },
    {
      title: '部门',
      dataIndex: 'orgs',
      key: 'orgs',
      ellipsis: true,
      render: list => {
        const lists = (list || []).map(item => item.name)
        return lists.join('、')
      },
    },
    {
      title: '岗位',
      dataIndex: 'posts',
      key: 'posts',
      ellipsis: true,
      render: list => {
        const lists = (list || []).map(item => item.name)
        return lists.join('、')
      },
    },
    {
      title: '手机号',
      dataIndex: 'phone',
      key: 'phone',
      ellipsis: true,
    },
    {
      title: '角色',
      dataIndex: 'address',
      key: 'address',
      ellipsis: true,
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: status => {
        return status === 1 ? '启用' : '禁用'
      },
    },
    {
      title: '说明',
      dataIndex: 'descr',
      key: 'descr',
      ellipsis: true,
    },
    {
      title: '操作',
      key: 'address',
      fixed: 'right',
      width: 280,
      render: row => {
        return (
          <>
            <ButtonGroup
              onClick={() => handEdite(row)}
              btnId={btnApiList.staffEdite}
              type="link"
            >
              编辑
            </ButtonGroup>
            <ButtonGroup
              onClick={() => handRole(row)}
              btnId={btnApiList.staffRole}
              type="link"
            >
              角色
            </ButtonGroup>
            <TipButton
              title="你确定要重置该员工吗？"
              onConfirm={() => handReset(row)}
              okText="确定"
              cancelText="取消"
              btnId={btnApiList.staffReset}
            >
              <Button type="link">重置</Button>
            </TipButton>
            <TipButton
              title="你确定要该员工账号吗？"
              onConfirm={() => handRemove(row)}
              okText="确定"
              cancelText="取消"
              btnId={btnApiList.staffDelete}
            >
              <Button type="link">删除</Button>
            </TipButton>
          </>
        )
      },
    },
  ]
}

export { columns }
