import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取权益类型列表
export const _getBenefitList = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.benefit.benefitList,
    params: params,
  })
}
// 删除权益类型
export const _removeBenefit = async params => {
  const path = btnApiList.equityDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.ADVERSRIING.benefit.benefitInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改权益类型
export const _editeBenefit = async params => {
  const path = btnApiList.equityEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.ADVERSRIING.benefit.benefitInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增权益类型
export const _addBenefit = async params => {
  const path = btnApiList.equityAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.benefit.benefitInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取权益类型详情
export const _benefitDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.benefit.benefitInfo}/${params}`,
  })
}
// 获取权益内容详情
export const _benefitContent = async (id, lang) => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.benefit.benefitContent}/${id}/${lang}`,
  })
}
// 修改权益内容
export const _editeBenefitContent = async params => {
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.benefit.benefitContent,
    data: params,
  })
}
