import {
  Button,
  Cascader,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Switch,
  message,
} from 'antd'
import UploadIcon from 'components/UploadIcon'
import React, { useEffect, useState } from 'react'
import {
  addEnun,
  editeEnun,
  enunDetails,
  getEnunList,
} from 'reduxs/system/enun'
import { getSubData } from 'utils/Tools'
import styles from './add.module.less'
const { TextArea } = Input
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel } = props
  const [treeList, setTreeList] = useState([]) // 直接上级的结构
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getTreeList()
    getTreeOneList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getTreeOneList = async () => {
    const res = await getEnunList({ paging: false, status: 1 })
    if (res.result === 200) {
      getDetails(res.data || [])
    }
  }
  // 获取详情
  const getDetails = async list => {
    const { enumId } = editeData
    if (enumId) {
      const res = await enunDetails(enumId)
      if (res.result === 200) {
        const { data } = res
        const parentdIds = getParentdIds(list, data.parentId)
        data.parentId = [
          ...parentdIds,
          ...(data.parentId ? [] : [data.parentId]),
        ]
        form.setFieldsValue(data)
      }
    }
  }
  const getParentdIds = (list, parentId, ids = []) => {
    list.forEach(item => {
      if (item.enumId === parentId) {
        ids.unshift(item.enumId)
        if (item.parentId) {
          getParentdIds(list, item.parentId, ids)
        }
      }
    })
    return ids
  }
  // 获取直接上级
  const getTreeList = async () => {
    const res = await getEnunList({ tree: true, status: 1 })
    if (res.result === 200) {
      const { data } = res
      setTreeList(data || [])
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    values.parentId = values.parentId[values.parentId.length - 1]
    const { enumId } = editeData
    const data = getSubData(values)
    if (enumId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 修改
  const editeList = async values => {
    values.enumId = editeData.enumId
    const res = await editeEnun(values)
    setLoading(true)
    if (res.result === 200) {
      message.success('字典修改成功~')
      props.handSuccess()
    }
  }
  // 新增菜单
  const addList = async values => {
    const res = await addEnun(values)
    setLoading(true)
    if (res.result === 200) {
      message.success('字典新增成功~')
      props.handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title={editeData.enumId ? '编辑字典' : '新增字典'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{
            status: true,
          }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="直接上级"
                name="parentId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择直接上级',
                  },
                ]}
              >
                <Cascader
                  options={treeList}
                  placeholder="请选择上级"
                  changeOnSelect
                />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item
                label="名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="ICON" name="icon">
                <UploadIcon />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="值"
                name="val"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入值',
                  },
                ]}
              >
                <TextArea rows={4} placeholder="请输入值" maxLength={120} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="排序" name="seq">
                <InputNumber min={0} defaultValue={0} precision={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="状态"
                name="status"
                valuePropName="checked"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Switch defaultChecked />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="说明" name="descr">
                <TextArea rows={4} placeholder="请输入说明" maxLength={120} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
