import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取业务分类列表
export const _getClaszList = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.clasz.claszList,
    params: params,
  })
}
// 删除业务分类
export const _removeClasz = async params => {
  const path = btnApiList.businessTypeDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.ADVERSRIING.clasz.claszInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改业务分类
export const _editeClasz = async params => {
  const path = btnApiList.businessTypeEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.ADVERSRIING.clasz.claszInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增业务分类
export const _addClasz = async params => {
  const path = btnApiList.businessTypeAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.clasz.claszInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取业务分类详情
export const _claszDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.clasz.claszInfo}/${params}`,
  })
}
// 业务分类默认
export const _claszDef = async params => {
  return await request({
    method: 'PUT',
    url: Api.ADVERSRIING.clasz.claszDef,
    data: params,
  })
}
