import Icon, * as icons from '@ant-design/icons'
import { Button } from 'antd'
import ButtonGroup, { TipButton } from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React from 'react'
const typeData = [
  {
    status: 5,
    name: '导航',
  },
  {
    status: 10,
    name: '菜单',
  },
  {
    status: 20,
    name: '按钮',
  },
]
const columns = eventData => {
  const { handEdite, handRemove } = eventData
  return [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      fixed: 'left',
      width: 100,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      ellipsis: true,
    },
    {
      title: '编号',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
      width: 100,
    },
    {
      title: '类型',
      dataIndex: 'type',
      key: 'type',
      width: 100,
      ellipsis: true,
      render: type => {
        const findData = typeData.find(item => item.status === type)
        return findData ? findData.name : type
      },
    },
    {
      title: '图标',
      dataIndex: 'icon',
      key: 'icon',
      width: 70,
      render: icon =>
        icon ? (
          <Icon component={icons[icon]} style={{ fontSize: '20px' }} />
        ) : (
          ''
        ),
    },
    {
      title: '排序',
      dataIndex: 'seq',
      key: 'seq',
      width: 70,
    },
    {
      title: '路由',
      dataIndex: 'view',
      key: 'view',
      ellipsis: true,
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 70,
      render: status => {
        return status === 1 ? '启用' : '禁用'
      },
    },
    {
      title: '操作',
      key: 'change',
      fixed: 'right',
      width: 140,
      render: row => {
        return (
          <>
            <ButtonGroup
              onClick={() => handEdite(row)}
              btnId={btnApiList.menuManageEdite}
              type="link"
            >
              编辑
            </ButtonGroup>
            <TipButton
              title="你确定要删除该菜单吗？"
              onConfirm={() => handRemove(row)}
              okText="确定"
              cancelText="取消"
              btnId={btnApiList.menuManageDelete}
            >
              <Button type="link">删除</Button>
            </TipButton>
          </>
        )
      },
    },
  ]
}
export { columns }
