import { Button, Image } from 'antd'
import ButtonGroup, { TipButton } from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React from 'react'
const columns = eventData => {
  const { handEdite, handRemove, handPay } = eventData
  return [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 70,
    },
    {
      title: '图标',
      dataIndex: 'icon',
      key: 'icon',
      render: text => (text ? <Image src={text} width={24} /> : ''),
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: '代码',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
    },
    {
      title: '大洲',
      dataIndex: 'continent',
      key: 'continent',
      ellipsis: true,
      render: row => {
        if (row) {
          return row.name
        }
        return row
      },
    },
    {
      title: '货币',
      dataIndex: 'currency',
      key: 'currency',
      ellipsis: true,
      render: row => {
        if (row) {
          return row.name
        }
        return row
      },
    },
    {
      title: '语言',
      dataIndex: 'language',
      key: 'language',
      ellipsis: true,
      render: row => {
        if (row) {
          return row.name
        }
        return row
      },
    },
    {
      title: '排序',
      dataIndex: 'seq',
      key: 'seq',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 70,
      render: status => {
        return status === 1 ? '启用' : '禁用'
      },
    },
    {
      title: '操作',
      key: 'change',
      fixed: 'right',
      width: 240,
      render: row => {
        return (
          <>
            <ButtonGroup
              onClick={() => handEdite(row)}
              btnId={btnApiList.countryEdite}
              type="link"
            >
              编辑
            </ButtonGroup>
            <ButtonGroup
              onClick={() => handPay(row)}
              btnId={btnApiList.countryPayType}
              type="link"
            >
              支付方式
            </ButtonGroup>
            <TipButton
              title="你确定要该国家吗？"
              onConfirm={() => handRemove(row)}
              okText="确定"
              cancelText="取消"
              btnId={btnApiList.countryDelete}
            >
              <Button type="link">删除</Button>
            </TipButton>
          </>
        )
      },
    },
  ]
}
export { columns }
