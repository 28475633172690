import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取渠道商列表
export const _getChannelMahList = async params => {
  return await request({
    method: 'GET',
    url: Api.OPERATION.channel.channelMahList,
    params: params,
  })
}
// 删除渠道商
export const _removeChannelMah = async params => {
  const path = btnApiList.channelmMchDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.OPERATION.channel.channelMahInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改渠道商
export const _editeChannelMah = async params => {
  const path = btnApiList.channelmMchEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.OPERATION.channel.channelMahInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增渠道商
export const _addChannelMah = async params => {
  const path = btnApiList.channelmMchAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.OPERATION.channel.channelMahInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取渠道商详情
export const _channelMahDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.channel.channelMahInfo}/${params}`,
  })
}
