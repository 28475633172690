// servers/system

// Selectors

// Actions

// Action Creators

// init data
import {
  _addConfig,
  _editeConfig,
  _getConfigList,
  _removeConfig,
  _configDetails,
} from 'servers/system/config'
import { changeList } from '../../../Selectors/Common/index'
// 获取配置列表
export const getConfigList = async (data = {}) => {
  const res = await _getConfigList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除配置
export const removeConfig = async (data = {}) => {
  const res = await _removeConfig(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改配置
export const editeConfig = async (data = {}) => {
  const res = await _editeConfig(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增配置
export const addConfig = async (data = {}) => {
  const res = await _addConfig(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取配置详情
export const configDetails = async (data = {}) => {
  const res = await _configDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
