/* eslint-disable array-callback-return */
import { Layout, Menu } from 'antd'
import Router from 'apis/Router'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getLocal } from 'utils/Help'
import { getMenuItem } from './_help'
import styles from './index.module.less'
const { SubMenu } = Menu
const { Sider } = Layout
const Index = props => {
  const path = window.location.pathname
  const { collapsed } = props
  const [router, setRouter] = useState([])
  // const [current, setCurrent] = useState('') // 当前侧边栏激活的menu

  useEffect(() => {
    getMenus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path])

  const getMenus = () => {
    const routerList = Router
    const menus = routerList.find(item => path.includes(item.key))
    const promisMenus = getLocal('menus') ? JSON.parse(getLocal('menus')) : []
    const children = getMenuItem(menus?.children || [], promisMenus)
    const routers = getRouters(children)
    setRouter(routers)
  }
  const getRouters = list => {
    return list.map(item => {
      const list = (item.children || []).filter(item => !item.hidden)
      if (item.children?.length && !item.hidden && list.length) {
        return (
          <SubMenu
            style={{
              fontSize: '14px',
              fontWeight: 'bold',
              fontFamily: 'MicrosoftYaHei-Bold, MicrosoftYaHei',
            }}
            key={item.key}
            title={item.name}
            icon={item.Icon}
          >
            {getRouters(item.children)}
          </SubMenu>
        )
      } else if (!item.hidden) {
        return (
          <Menu.Item key={item.key} icon={item.Icon}>
            <Link className={styles.link} to={item.path}>
              {item.name}
            </Link>
          </Menu.Item>
        )
      }
    })
  }
  // 监听展开或者收起
  // const onCollapse = () => {
  //   toggle(collapsed)
  // }

  const handleClickMenu = keys => {
    console.log(keys)
    // const data = MENULIST.find(item => item.key === keys.key)
    // if (data) {
    //   trackEvent(`控制中心-${data.title}`)
    // }
    // setLocal('siderMenuKey', keys.key)
    // setCurrent(keys.key)
  }

  return (
    <div className={styles.solidForm}>
      <Sider
        style={{
          background: '#fff',
        }}
        className={styles.solder}
        trigger={null}
        collapsed={collapsed}
        width={160}
      >
        <Menu
          // items={router}
          mode="inline"
          className={styles.menu}
          // oneMenuType="blod"
          onClick={handleClickMenu}
          // selectedKeys={[current]}
        >
          {router}
        </Menu>
        {/* {React.createElement(collapsed ? RightOutlined : LeftOutlined, {
          className: 'trigger',
          style: { left: collapsed ? '64px' : '160px' },
          onClick: onCollapse,
        })} */}
      </Sider>
    </div>
  )
}
export default Index
