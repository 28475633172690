const btnApiList = {
  currencyAdd: '/index/operation/currency:add',
  currencyDelete: '/index/operation/currency:delete',
  currencyEdite: '/index/operation/currency:edite',
  langAdd: '/index/operation/lang:add',
  langDelete: '/index/operation/lang:delete',
  langEdite: '/index/operation/lang:edite',
  mailboxAdd: '/index/operation/mailbox:add',
  mailboxEdite: '/index/operation/mailbox:edite',
  mailboxDelete: '/index/operation/mailbox:delete',
  regionStateAdd: '/index/operation/regionState:add',
  regionStateDelete: '/index/operation/regionState:delete',
  regionStateEdite: '/index/operation/regionState:edite',
  countryAdd: '/index/region/country:add',
  countryDelete: '/index/region/country:delete',
  countryEdite: '/index/region/country:edite',
  countryPayType: '/index/region/country:payType',
  merchantAdd: '/index/pay/merchant:add',
  merchantEdite: '/index/pay/merchant:edite',
  merchantDelete: '/index/pay/merchant:delete',
  companyTypeAdd: '/index/company/type:add',
  companyTypeEdite: '/index/company/type:edite',
  companyTypeDelete: '/index/company/type:delete',
  payTypeAdd: '/index/pay/type:add',
  payTypeEdite: '/index/pay/type:edite',
  payTypeDelete: '/index/pay/type:delete',
  companyAdd: '/index/company/business:add',
  companyEdite: '/index/company/business:edite',
  companyDelete: '/index/company/business:delete',
  contractAdd: '/index/company/contract:add',
  contractEdite: '/index/company/contract:edite',
  contractDelete: '/index/company/contract:delete',
  protocolAdd: '/index/company/protocol:add',
  protocolEdite: '/index/company/protocol:edite',
  protocolDelete: '/index/company/protocol:delete',
  protocolContent: '/index/company/protocol:content',
  addProvince: '/index/region/manageIndex:addProvince',
  editeProvince: '/index/region/manageIndex:editeProvince',
  deleteProvince: '/index/region/manageIndex:deletProvince',
  addCity: '/index/region/manageIndex:addCity',
  editeCity: '/index/region/manageIndex:editeCity',
  deleteCity: '/index/region/manageIndex:deleteCity',
  addArea: '/index/region/manageIndex:addArea',
  editeArea: '/index/region/manageIndex:editeArea',
  deleteArea: '/index/region/manageIndex:deleteArea',
  domainAdd: '/index/domain/index:add',
  domainEdite: '/index/domain/index:edite',
  domainDelete: '/index/domain/index:delete',
  domainListAdd: '/index/domain/domainList:add',
  domainListEdite: '/index/domain/domainList:edite',
  domainListDelete: '/index/domain/domainList:delete',
  channelTypeAdd: '/index/channel/type:add',
  channelTypeEdite: '/index/channel/type:edite',
  channelTypeDelete: '/index/channel/type:delete',
  channelmMchAdd: '/index/channel/merchant:add',
  channelmMchEdite: '/index/channel/merchant:edite',
  channelmMchDelete: '/index/channel/merchant:delete',
  channelAccAdd: '/index/channel/account:add',
  channelAccEdite: '/index/channel/account:edite',
  channelAccDelete: '/index/channel/account:delete',
  channelmMchAdvert: '/index/channel/account:Advert',
  businessTypeAdd: '/index/advertising/businessType:add',
  businessTypeEdite: '/index/advertising/businessType:edite',
  businessTypeDelete: '/index/advertising/businessType:delete',
  equityAdd: '/index/equity/index:add',
  equityEdite: '/index/equity/index:edite',
  equityDelete: '/index/equity/index:delete',
  equityContnet: '/index/equity/index:content',
  equityTypeAdd: '/index/equity/type:add',
  equityTypeEdite: '/index/equity/type:edite',
  equityTypeDelete: '/index/equity/type:delete',
  analysisAdd: '/index/question/analysis:add',
  analysisEdite: '/index/question/analysis:edite',
  analysisDelete: '/index/question/analysis:delete',
  questionAdd: '/index/question/index:add',
  questionEdite: '/index/question/index:edite',
  questionDelete: '/index/question/index:delete',
  questionLang: '/index/question/index:lang',
  questionDimension: '/index/question/index:dimension', // 维度
  questionSubject: '/index/question/index:subject', // 题目
  questionResult: '/index/question/index:result', // 结果
  questionEmail: '/index/question/index:email', // 题库管理邮件配置
  subjectAnswer: '/index/question/subject:answer', // 题目答案
  subjectAdd: '/index/question/subject:add', // 题目新增
  subjectEdite: '/index/question/subject:edite', // 题目编辑
  subjectDelete: '/index/question/subject:delete', // 题目删除
  subjectLang: '/index/question/subject:delete', // 题目与狼
  answerAdd: '/index/question/answer:add', // 答案新增
  answerEdite: '/index/question/answer:edite', // 答案编辑
  answerDelete: '/index/question/answer:delete', // 答案删除
  answerLang: '/index/question/answer:lang', // 答案多语言
  resultAdd: '/index/advertising/result:add', // 新增结果
  resultEdite: '/index/advertising/result:edite', // 编辑结果
  resultDelete: '/index/advertising/result:delete', // 删除结果
  resultLang: '/index/advertising/result:lang', // 结果多语言
  manageAdd: '/index/advertising/manage:add', // 新增投放
  manageDownLoad: '/index/advertising/manage:downLoad', // 下载投放
  manageEdite: '/index/advertising/manage:edite', // 编辑投放
  manageDelete: '/index/advertising/manage:delete', // 删除投放
  manageLang: '/index/advertising/manage:lang', // 投放多语言
  manageProgramme: '/index/advertising/manage:programme', // 新增投放
  managePay: '/index/advertising/manage:pay', // 新增投放
  manageEmail: '/index/advertising/manage:email', // 新增投放
  programmeAdd: '/index/advertising/programme:add', // 方案新增
  programmeDelete: '/index/advertising/programme:delete', // 方案删除
  programmeEdite: '/index/advertising/programme:edite', // 方案编辑
  progCopy: '/index/advertising/programme:copy', // 方案复制
  programmeContent: '/index/advertising/programme:content', // 方案内容
  programmeEquity: '/index/advertising/programme:equity', // 方案内容
  programmeCopy: '/index/advertising/manage:copy', // 投放复制

  staffAdd: '/index/system/staff:add', // 员工
  staffRole: '/index/system/staff:role',
  staffReset: '/index/system/staff:reset',
  staffEdite: '/index/system/staff:edite',
  staffDelete: '/index/system/staff:delete',
  orgMangeAdd: '/index/system/orgMange:add', // 组织架构
  orgMangeEdite: '/index/system/orgMange:add', // 组织架构
  orgMangeDelete: '/index/system/orgMange:delete', // 组织架构
  postManageAdd: '/index/system/postManage:add', // 新增岗位
  postManageEdite: '/index/system/postManage:edite', // 新增岗位
  postManageDelete: '/index/system/postManage:delete', // 新增岗位
  roleManageAuthority: '/index/system/roleManage:authority', // 角色
  roleManageAdd: '/index/system/roleManage:add',
  roleManageEdite: '/index/system/roleManage:edite',
  roleManageDelete: '/index/system/roleManage:delete',
  menuManageAdd: '/index/system/menuManage:add', // 菜单
  menuManageEdite: '/index/system/menuManage:edite',
  menuManageDelete: '/index/system/menuManage:delete',
  dicManageAdd: '/system/dicManage:add', // 字典
  dicManageEdite: '/system/dicManage:edite',
  dicManageType: '/system/dicManage:type',
  dicManageDelete: '/system/dicManage:delete',
  allocationAdd: '/system/allocationManage:add', // 系统配置
  allocationEdite: '/system/allocationManage:edite',
  allocationDelete: '/system/allocationManage:delete',

  mailboxTypeAdd: '/index/operation/mailboxType:add', // 邮件类型
  mailboxTypeEdite: '/index/operation/mailboxType:edite',
  mailboxTypeDelete: '/index/operation/mailboxType:delete',

  advertisingOrderLoad: '/index/advertising/advertisingOrder:download', // 投放订单下载
  payOrderLoad: '/index/advertising/payOrder:downLoad', // 支付订单下载
  businessTypeDefault: '/index/advertising/businessType:default', // 业务分类设置默认
}
export { btnApiList }
