import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Col,
  Form,
  InputNumber,
  Modal,
  Row,
  Table,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  adsLangSeq,
  deleteLang,
  getAdsLang,
} from 'reduxs/advertising/advertising'
import LangList from './LangList'
import styles from './index.module.less'
const LangModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel } = props
  const { adsId } = editeData
  const [langData, setLangData] = useState({})
  const [tableData, setTabData] = useState([])
  const [key, setKey] = useState(false)
  const [number, setNumber] = useState(0)
  const [detailsLoading, setDetailsLoading] = useState(false)
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取语言列表
  const getDetails = async () => {
    setDetailsLoading(true)
    const res = await getAdsLang(adsId)
    setDetailsLoading(false)
    if (res.result === 200) {
      const data = res.data || []
      data.forEach((item, index) => {
        item.index = index + 1
      })
      setTabData(data || [])
      setKey(!key)
    }
  }

  // 添加语言
  const handLang = () => {
    setLangData({ status: true })
  }
  // 选择语言确定
  const langSuccess = () => {
    setLangData({})
    getDetails()
  }
  // 监听input的变化
  const handSeq = e => {
    setNumber(e)
  }
  // 删除
  const handDelete = async row => {
    const { index, adsId, code } = row
    if (adsId) {
      const res = await deleteLang(adsId, code)
      if (res.result === 200) {
        message.success('删除成功~')
        getDetails()
      }
      return
    }

    const list = [...tableData].filter(item => item.index !== index)
    list.forEach((item, index) => (item.index = index + 1))
    setTabData(list)
  }
  // 编辑
  const handUpdate = row => {
    const { index } = row
    const list = [...tableData]
    list.forEach(item => {
      item.isUpdate = false
      if (item.index === index) {
        item.isUpdate = true
        setNumber(item.seq)
      }
    })
    setTabData(list)
  }
  // 取消编辑
  const handBack = () => {
    const list = [...tableData]
    list.forEach(item => {
      item.isUpdate = false
    })
    setTabData(list)
  }
  // 保存序号
  const handSave = async row => {
    setDetailsLoading(true)
    const { adsId } = editeData
    const { code } = row
    const res = await adsLangSeq(adsId, code, number)
    if (res.result === 200) {
      message.success('保存成功~')
      getDetails()
    } else {
      setDetailsLoading(false)
    }
  }
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 70,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '代码',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '语言',
      dataIndex: 'lang',
      key: 'lang',
    },
    {
      title: '排序',
      key: 'seq',
      render: row => {
        const { isUpdate } = row
        return isUpdate ? (
          <InputNumber defaultValue={row.seq} onChange={e => handSeq(e, row)} />
        ) : (
          row.seq
        )
      },
    },
    {
      title: '操作',
      key: 'changge',
      render: row => {
        const { isUpdate } = row
        return isUpdate ? (
          <>
            <Button type="link" onClick={() => handSave(row)}>
              保存
            </Button>
            <Button type="link" onClick={() => handBack(row)}>
              取消
            </Button>
          </>
        ) : (
          <>
            <Button type="link" onClick={() => handUpdate(row)}>
              编辑
            </Button>
            <Button type="link" onClick={() => handDelete(row)}>
              删除
            </Button>
          </>
        )
      },
    },
  ]
  return (
    <Modal
      width={600}
      title="投放多语言"
      open={editeData.type ? true : false}
      onCancel={handleCancel}
      onOk={handleCancel}
      confirmLoading={true}
      footer={[]}
    >
      {langData.status && (
        <LangList
          langData={langData}
          handleCancel={() => setLangData({})}
          handSuccess={langSuccess}
          tableData={tableData}
          adsId={adsId}
        />
      )}

      <div className={styles.formData}>
        <Form form={form}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="名称">{editeData.title}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="代码">{editeData.code}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="投放语言" required>
                <Button icon={<PlusOutlined />} onClick={handLang}>
                  添加
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          dataSource={tableData}
          rowKey={item => item.lang} //React规范中的array都必须有key
          columns={columns}
          size="small"
          pagination={false}
          loading={detailsLoading}
          key={key}
        />
      </div>
    </Modal>
  )
}
export default LangModal
