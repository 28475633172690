import {
  _adsOrderBenefit,
  _adsOrderDetails,
  _adsOrderList,
  _adsOrderListLoad,
  _adsOrderMail,
  _analyzing,
  _backAbsList,
  _payOrderList,
  _payOrderListLoad,
  _emaiSend,
} from 'servers/advertising/common'
import { changeList } from '../../../Selectors/Common/index'
// 补发邮件
export const emaiSend = async data => {
  const res = await _emaiSend(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 解析
export const analyzing = async data => {
  const res = await _analyzing(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}

// 获取归因列表
export const backAbsList = async (data = {}) => {
  const res = await _backAbsList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 投放订单
export const adsOrderList = async (data = {}) => {
  const res = await _adsOrderList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 投放订单详情
export const adsOrderDetails = async (data = {}) => {
  const res = await _adsOrderDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 投放订单邮件列表
export const adsOrderMail = async (data = {}) => {
  const res = await _adsOrderMail(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 投放订单权益
export const adsOrderBenefit = async (data = {}) => {
  const res = await _adsOrderBenefit(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 支付订单
export const payOrderList = async (data = {}) => {
  const res = await _payOrderList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 导出投放订单
export const adsOrderListLoad = async (data = {}) => {
  const res = await _adsOrderListLoad(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 导出支付订单
export const payOrderListLoad = async (data = {}) => {
  const res = await _payOrderListLoad(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
