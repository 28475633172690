import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  message,
} from 'antd'
import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import React, { useEffect, useState } from 'react'
import { getCountryList } from 'reduxs/operation/country'
import {
  addList as _addList,
  editeList as _editeList,
  getDetails as _getDetails,
} from 'reduxs/operation/regionManage'
import { getSubData } from 'utils/Tools'
import styles from './add.module.less'
const addPath = btnApiList.addProvince
const editePath = btnApiList.editeProvince
const url = Api.OPERATION.regionManage.provinceInfo
const { Option } = Select
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [country, setCountry] = useState([]) // 国家列表
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getDetails()
    getCountry()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const { districtProvinceId } = editeData
    if (districtProvinceId) {
      const urlStr = `${url}/${districtProvinceId}`
      const res = await _getDetails(districtProvinceId, urlStr)
      if (res.result === 200) {
        const { data } = res
        data.status = data.status === 1 ? true : false
        form.setFieldsValue(data)
      }
    }
  }
  // 获取国家列表
  const getCountry = async () => {
    const res = await getCountryList({ paging: false, status: 1 })
    if (res.result === 200) {
      const { data } = res
      setCountry(data || [])
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { provinceId } = editeData
    const data = getSubData(values)
    if (provinceId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 编辑
  const editeList = async values => {
    const { districtProvinceId } = editeData
    values.districtProvinceId = districtProvinceId
    const res = await _editeList(values, url, editePath)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await _addList(values, url, addPath)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title={editeData.districtProvinceId ? '编辑省' : '新增省'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{
            status: true,
          }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="国家"
                name="countryId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择国家',
                  },
                ]}
              >
                <Select
                  placeholder="请选择国家"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {country.map((item, index) => (
                    <Option value={item.countryId} key={index}>
                      {`${item.name} (${item.code})`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item
                label="名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="代码"
                name="provinceId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入代码',
                  },
                ]}
              >
                <Input placeholder="请输入代码" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="排序" name="seq">
                <InputNumber min={0} defaultValue={0} precision={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="状态"
                valuePropName="checked"
                required={true}
                name="status"
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
