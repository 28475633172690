import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Switch,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  addMailBox,
  editeMailbox,
  mailBoxDetails,
} from 'reduxs/operation/mailBox'
import { getSubData } from 'utils/Tools'
import styles from './add.module.less'
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [detailsLoading, setDetailsLoading] = useState(false)
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const { emailId } = editeData
    if (emailId) {
      setDetailsLoading(true)
      const res = await mailBoxDetails(emailId)
      setDetailsLoading(false)
      if (res.result === 200) {
        const { data } = res
        data.status = data.status === 1 ? true : false
        data.defStatus = data.defStatus === 1 ? true : false
        form.setFieldsValue(data)
      }
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { emailId } = editeData
    const data = getSubData(values)
    data.sysn = undefined
    data.defStatus = data.defStatus ? 1 : 0
    if (emailId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 编辑
  const editeList = async values => {
    const { emailId } = editeData
    values.emailId = emailId
    const res = await editeMailbox(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改邮箱成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addMailBox(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增邮箱成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title={editeData.emailId ? '编辑邮箱' : '新增邮箱'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Spin tip="加载中" spinning={detailsLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={() => setLoading(false)}
            initialValues={{ status: true, defStatus: true }}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="邮箱"
                  name="email"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请输入邮箱',
                    },
                  ]}
                >
                  <Input placeholder="请输入邮箱" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="服务地址"
                  name="serviceUrl"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请输入服务地址',
                    },
                  ]}
                >
                  <Input placeholder="请输入服务地址" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="服务密码" name="servicePwd">
                  <Input placeholder="请输入服务密码" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="服务端口" name="servicePort">
                  <Input placeholder="请输入服务端口" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="默认"
                  name="defStatus"
                  valuePropName="checked"
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="状态"
                  name="status"
                  valuePropName="checked"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请选择状态',
                    },
                  ]}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </Modal>
  )
}
export default AddModal
