import {
  _addMerchantType,
  _editeMerchantType,
  _getMerchantTypeList,
  _merchantTypeDetails,
  _removeMerchantType,
} from 'servers/operation/merchantType'
import { changeList } from '../../../Selectors/Common/index'
// 获取支付类型列表
export const getMerchantTypeList = async (data = {}) => {
  const res = await _getMerchantTypeList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除支付类型
export const removeMerchantType = async (data = {}) => {
  const res = await _removeMerchantType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改支付类型
export const editeMerchantType = async (data = {}) => {
  const res = await _editeMerchantType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增支付类型
export const addMerchantType = async (data = {}) => {
  const res = await _addMerchantType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取支付类型详情
export const merchantTypeDetails = async (data = {}) => {
  const res = await _merchantTypeDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
