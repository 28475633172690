// servers/system

// Selectors

// Actions

// Action Creators

// init data
import {
  _addMailbox,
  _editeMailBox,
  _getMailBoxList,
  _mailBoxDetails,
  _removeMailbox,
} from 'servers/operation/mailBox'
import { changeList } from '../../../Selectors/Common/index'
// 获取邮箱列表
export const getMailBoxList = async (data = {}) => {
  const res = await _getMailBoxList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除邮箱
export const removeMailBox = async (data = {}) => {
  const res = await _removeMailbox(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改邮箱
export const editeMailbox = async (data = {}) => {
  const res = await _editeMailBox(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增邮箱
export const addMailBox = async (data = {}) => {
  const res = await _addMailbox(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取邮箱详情
export const mailBoxDetails = async (data = {}) => {
  const res = await _mailBoxDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
