import { Button, Col, Form, Modal, Row, Space, Spin, Tabs, message } from 'antd'
import UploadIcon from 'components/UploadIcon'
import WangEditor from 'components/WangEditor'
import React, { useEffect, useState } from 'react'
import {
  editeResultContent,
  questionResultContent,
} from 'reduxs/advertising/questionResult'
import { getLangList } from 'reduxs/operation/lang'
import { contentList } from './_help'
import styles from './index.module.less'
let allValueData = {}
const LangModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, state } = props
  const [loading, setLoading] = useState(false)
  const [langList, setLangList] = useState([])
  const [activeData, setActiveData] = useState({}) // 当前激活的语言
  const [imageUrl, setImageUrl] = useState('')
  const [key, setKey] = useState(false)
  const [valueData, setValueData] = useState({}) // 存储当前语言的富文本
  const [defalueValue, setDefaultValue] = useState({}) // 存储初始化的富文本
  const [detailsLoading, setDetailsLoading] = useState(false)
  // const [formData, setFormData] = useState({}) // 全部数据
  useEffect(() => {
    getLang()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取语言列表
  const getLang = async () => {
    setDetailsLoading(true)
    const res = await getLangList({ paging: false, status: 1 })
    if (res.result === 200) {
      const data = res.data || []
      data.forEach(item => {
        item.label = item.name
        item.key = item.languageId
      })
      if (data.length) {
        setActiveData(data[0])
        getContent(data[0])
      }
      setLangList(data)
    } else {
      setDetailsLoading(false)
    }
  }
  // 获取语言详情
  const getContent = async data => {
    setDetailsLoading(true)
    form.resetFields()
    const { code } = data
    const { questionResultId } = editeData
    const res = await questionResultContent(questionResultId, code)
    setDetailsLoading(false)
    if (res.result === 200) {
      const { data } = res
      console.log(data)
      setValueData(data || {})
      allValueData = data || {}
      setDefaultValue(data || {})
      setImageUrl(data?.icon || '')
      if (data) {
        form.setFieldsValue(data)
      } else {
        form.resetFields()
      }
      // setKey(!key)
    }
  }

  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = async values => {
    const { code } = activeData
    const { questionResultId } = editeData
    const data = {
      ...values,
      lang: code,
      questionResultId: questionResultId,
      ...valueData,
    }
    data.icon = imageUrl
    const res = await editeResultContent(data)
    setLoading(false)
    if (res.result === 200) {
      message.success('结果多语言编辑成功~')
      // handSuccess()
    }
  }
  // 语言切换
  const langChange = key => {
    const findData = langList.find(item => item.languageId * 1 === key * 1)
    if (findData) {
      setActiveData(findData)
      getContent(findData)
    }
  }
  // 上传图片成功
  const imgSuccess = str => {
    setImageUrl(str)
  }
  // 监听富文本的变化
  const handChang = (html, item) => {
    const { name } = item
    const data = JSON.parse(JSON.stringify(allValueData))
    data[name] = html
    console.log(data)
    allValueData = data
    setValueData(allValueData)
  }
  return (
    <Modal
      width={800}
      title="结果多语言"
      open={editeData?.langStatus}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Spin tip="加载中" spinning={detailsLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={() => setLoading(false)}
            initialValues={{ status: true }}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="测评分类">{state?.clasz?.name}</Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="名称">{editeData?.title}</Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="维度">{editeData?.wd?.name}</Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="基准">{editeData?.jz?.name}</Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="分数">{`${editeData.minScore}-${editeData.maxScore}`}</Form.Item>
              </Col>
              <Col span={24}>
                <Tabs
                  activeKey={activeData?.languageId}
                  tabPosition="top"
                  items={langList}
                  onChange={langChange}
                />
              </Col>
              <Col span={24}>
                <Form.Item label="ICON" name="icon">
                  <UploadIcon handSuccess={imgSuccess} imageUrl={imageUrl} />
                </Form.Item>
              </Col>
              <div className={styles.content} key={key}>
                {contentList.map(item => {
                  console.log(item)
                  console.log(defalueValue[item.name])
                  return (
                    <div>
                      <div className={styles.listItem}>
                        <span className={styles.name}>{item.title}</span>
                        <div className={styles.content}>
                          <WangEditor
                            handChang={html => handChang(html, item)}
                            htmlValue={defalueValue[item.name]}
                            //htmlValue={mdEditorValue}
                          />
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </Row>
          </Form>
        </Spin>
      </div>
    </Modal>
  )
}
export default LangModal
