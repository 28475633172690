import { CloseOutlined, LeftOutlined, PlusOutlined } from '@ant-design/icons'
import { Col, Form, Row, Space, Spin, Table, Tabs, message } from 'antd'
import ButtonGroup from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { questionConditList } from 'reduxs/advertising/question'
import {
  getQuestionResultList,
  removeQuestionResult,
} from 'reduxs/advertising/questionResult'
import AddEditeModal from '../Compontents/AddEditeModal'
import LangModal from '../Compontents/LangModal'
import { columns } from './_help'
import styles from './index.module.less'
const TabsItem = [
  {
    label: '通用结果',
    key: 'TY',
  },
  {
    label: '综合结果',
    key: 'ZH',
  },
]
let options = {}
const SubjectManage = props => {
  const { history } = props
  const { state } = props.location
  const [selectedRowKeys, setSelectedRowKeys] = useState([]) // 选中的数据
  const [editeData, setEditeData] = useState({}) // 编辑存放每一条的数据
  const [loading, setLoading] = useState(false)
  const [pageData, setPageData] = useState([]) // 列表
  const [jzList, seJzList] = useState([]) // 基准列表
  const [wdList, setWdList] = useState([]) // 维度列表
  const [jzActiveData, setJzActiveData] = useState(null) // 激活的基准
  const [wdActiveData, setWdActiveData] = useState(null) // 激活的维度
  const [activeData, setActiveData] = useState({})
  useEffect(() => {
    setActiveData(TabsItem[0])
    options = {}
    getList(TabsItem[0])
    getTypeData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getTypeData = async () => {
    if (!state?.questionInfoId) {
      return
    }
    const { questionInfoId } = state
    const res = await questionConditList({
      status: 1,
      paging: false,
      questionInfoId: questionInfoId,
    })
    if (res.result === 200) {
      const data = res.data || []
      let jzList = data.filter(item => item.conditType === 'JZ')
      let wdList = data.filter(item => item.conditType === 'WD')
      seJzList(jzList)
      setWdList(wdList)
    }
  }
  // 获取题目列表
  const getList = async data => {
    const { questionInfoId } = state
    setLoading(true)
    const res = await getQuestionResultList({
      // ...options,
      questionInfoId: questionInfoId,
      // status: 1,
      resultType: data.key,
    })
    setLoading(false)
    if (res.result === 200) {
      const data = res.data || []
      data.forEach((item, index) => {
        item.index = index + 1
      })
      setPageData(data || [])
    }
  }
  // 判断只能选择一条数据
  const isSeleteData = () => {
    if (!selectedRowKeys.length) {
      message.warn('请选择数据~')
      return
    }
    if (selectedRowKeys.length > 1) {
      message.warn('只能选择一条数据~')
      return
    }
    const key = selectedRowKeys[0]
    const findData = pageData.find(item => item.questionResultId === key)
    return findData
  }
  // 编辑
  const handEdite = () => {
    const data = isSeleteData()
    if (data) {
      setEditeData({ ...data, _status: true, isUpdate: true })
    }
  }
  // 多语言
  const handLang = () => {
    const data = isSeleteData()
    if (data) {
      setEditeData({ ...data, langStatus: true })
    }
  }
  // 删除
  const handDelete = async () => {
    const data = isSeleteData()
    if (data) {
      const { questionResultId } = data
      const res = await removeQuestionResult(questionResultId)
      if (res.result === 200) {
        message.success('删除成功~')
        setSelectedRowKeys([])
        getList(activeData)
      }
    }
  }
  // table可选中
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: selectedRowKeys => {
      setSelectedRowKeys([selectedRowKeys[selectedRowKeys.length - 1]])
    },
  }
  // 新增测评
  const handAdd = () => {
    if (!jzActiveData || !wdActiveData) {
      return message.error('请选择维度/基准')
    }
    setEditeData({ _status: true })
  }
  // 监听table变化
  const changePage = page => {
    options.page = page.current
    options.limit = page.pageSize
    getList(activeData)
  }
  // 搜索
  const onFinish = values => {
    options = { ...values }
    getList(activeData)
  }
  // 新增编辑成功
  const handSuccess = () => {
    setSelectedRowKeys([])
    setEditeData({})
    getList(activeData)
  }
  // 监听table变化
  const tabsChange = e => {
    const findData = TabsItem.find(item => item.key === e)
    if (findData) {
      setActiveData(findData)
      getList(findData)
      setSelectedRowKeys([])
    }
  }
  // 返回
  const handBack = () => {
    history.push({
      pathname: '/index/advertising/question',
    })
  }
  // 激活维度
  const handWd = item => {
    if (wdActiveData?.questionConditId === item.questionConditId) {
      setWdActiveData(null)
      options.questionConditId1 = ''
      getList(activeData)
      return
    }
    options.questionConditId1 = item.questionConditId
    setWdActiveData(item)
    getList(activeData)
  }
  // 激活的基准
  const handJz = item => {
    if (jzActiveData?.questionConditId === item.questionConditId) {
      options.questionConditId2 = ''
      setJzActiveData(null)
      getList(activeData)
      return
    }
    options.questionConditId2 = item.questionConditId
    setJzActiveData(item)
    getList(activeData)
  }
  return (
    <>
      <span className={styles.back} onClick={handBack}>
        <LeftOutlined />
        返回
      </span>

      <div className="pageContainer">
        <Spin tip="加载中" spinning={loading}>
          {editeData._status && (
            <AddEditeModal
              editeData={editeData}
              handleCancel={() => setEditeData({})}
              handSuccess={handSuccess}
              state={state}
              jzActiveData={jzActiveData}
              wdActiveData={wdActiveData}
              activeData={activeData}
            />
          )}
          {editeData.langStatus && (
            <LangModal
              editeData={editeData}
              handleCancel={() => setEditeData({})}
              handSuccess={handSuccess}
              state={state}
              jzList={jzList || []}
              wdList={wdList || []}
              activeData={activeData}
            />
          )}
          <Form onFinish={onFinish}>
            <Row gutter={24}>
              <Col span={8}>
                <Form.Item label="测评分类">{state?.clasz?.name}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="名称">{state?.title}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="解析方式">{state?.at?.name}</Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="题目">
                  {`${state?.wds}/${state?.jzs}/${state?.tms}`}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="数据">0</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="维度">
                  {wdList.map(item => {
                    return (
                      <span
                        className={`${styles.listItem} ${
                          wdActiveData?.questionConditId ===
                          item.questionConditId
                            ? styles.activeItem
                            : ''
                        }`}
                        onClick={() => handWd(item)}
                      >
                        {item.name}
                      </span>
                    )
                  })}
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="基准">
                  {jzList.map(item => {
                    return (
                      <span
                        className={`${styles.listItem} ${
                          jzActiveData?.questionConditId ===
                          item.questionConditId
                            ? styles.activeItem
                            : ''
                        }`}
                        onClick={() => handJz(item)}
                      >
                        {item.name}
                      </span>
                    )
                  })}
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Tabs defaultActiveKey="1" items={TabsItem} onChange={tabsChange} />
          <div className={styles.buttonList}>
            <Space>
              <ButtonGroup
                onClick={handAdd}
                icon={<PlusOutlined />}
                btnId={btnApiList.resultAdd}
              >
                新增
              </ButtonGroup>
              <ButtonGroup
                onClick={handEdite}
                icon={<PlusOutlined />}
                btnId={btnApiList.resultEdite}
              >
                编辑
              </ButtonGroup>
              <ButtonGroup
                onClick={handLang}
                icon={<PlusOutlined />}
                btnId={btnApiList.resultLang}
              >
                多语言
              </ButtonGroup>
              <ButtonGroup
                onClick={handDelete}
                icon={<CloseOutlined />}
                btnId={btnApiList.resultDelete}
              >
                删除
              </ButtonGroup>
            </Space>
          </div>
          <Table
            dataSource={pageData}
            rowKey={item => item.questionResultId} //React规范中的array都必须有key
            columns={columns}
            size="small"
            onChange={changePage}
            rowSelection={rowSelection}
            pagination={false}
          />
        </Spin>
      </div>
    </>
  )
}
export default withRouter(SubjectManage)
