import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { addRole, editeRole, roleDetails } from 'reduxs/system/role'
import { getSubData } from 'utils/Tools'
import styles from './add.module.less'
const { TextArea } = Input
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [codeRequre, setCodeRequre] = useState(true)
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const { roleId } = editeData
    if (roleId) {
      const res = await roleDetails(roleId)
      if (res.result === 200) {
        const { data } = res
        if (data.sysn) {
          data.sysn = true
        }
        setCodeRequre(data.sysn ? false : true)
        data.status = data.status === 1 ? true : false
        form.setFieldsValue(data)
      }
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { roleId } = editeData
    const data = getSubData(values)
    if (roleId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 编辑
  const editeList = async values => {
    const { roleId } = editeData
    values.roleId = roleId
    const res = await editeRole(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改角色成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addRole(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增角色成功~')
      handSuccess()
    }
  }
  // 监听系统编号变化
  const handBox = e => {
    const { checked } = e.target
    setCodeRequre(!checked)
  }
  return (
    <Modal
      width={600}
      title={editeData.roleId ? '编辑角色' : '新增角色'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{
            status: true,
            sysn: false,
          }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="角色名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入角色名称',
                  },
                ]}
              >
                <Input placeholder="请输入角色名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="角色编号" key={codeRequre}>
                <Input.Group compact>
                  <Form.Item
                    noStyle
                    name="code"
                    required={codeRequre ? true : false}
                    rules={[
                      {
                        required: codeRequre,
                        message: '请输入编号',
                      },
                    ]}
                  >
                    <Input
                      placeholder="请输入角色编号"
                      style={{ width: '50%' }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="sysn"
                    className={styles.boxItem}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={handBox}>系统编号</Checkbox>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="状态"
                name="status"
                required={true}
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="说明" name="descr">
                <TextArea rows={4} placeholder="请输入说明" maxLength={120} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
