import { Button, DatePicker, Form, Input, Select, Spin, Table } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { backAbsList } from 'reduxs/advertising/common'
import { getChannelAccList } from 'reduxs/operation/channelAcc'
import { getChannelTypeList } from 'reduxs/operation/channelType'
import { columns } from './_help'
const { Option } = Select
let options = {}
const { RangePicker } = DatePicker
const Attribution = () => {
  const [loading, setLoading] = useState(false)
  const [pages, setPages] = useState({}) // 页码相关
  const [pageData, setPageData] = useState([]) // 列表
  const [typeList, setTypeList] = useState([]) // 渠道类型
  const [accountList, setAccountList] = useState([]) // 渠道账户类型
  useEffect(() => {
    options = {
      page: 1,
      limit: 10,
    }
    getType()
    getAccount()
    getList()
  }, [])
  // 获取渠道类型
  const getType = async () => {
    const res = await getChannelTypeList({ paging: false })
    if (res.result === 200) {
      const { data } = res
      setTypeList(data || [])
    }
  }
  // 获取渠道账户
  const getAccount = async () => {
    const res = await getChannelAccList({ paging: false })
    if (res.result === 200) {
      const { data } = res
      setAccountList(data || [])
    }
  }
  // 获取归因列表
  const getList = async () => {
    setLoading(true)
    const res = await backAbsList({ ...options })
    setLoading(false)
    if (res.result === 200) {
      const { data, page } = res
      setPageData(data || [])
      if (page) {
        setPages(page || 0)
      }
    }
  }
  // 搜索
  const onFinish = values => {
    if (values.time?.length) {
      values.stime = moment(values.time[0]).format('YYYY-MM-DD')
      values.etime = moment(values.time[1]).format('YYYY-MM-DD')
      delete values.time
    }
    options = {
      page: 1,
      limit: 10,
      ...values,
    }
    getList()
  }
  // 监听table变化
  const changePage = page => {
    options.page = page.current
    options.limit = page.pageSize
    getList()
  }
  return (
    <div className="pageContainer">
      <Spin tip="加载中" spinning={loading}>
        <Form onFinish={onFinish}>
          <Form.Item name="searchKey" className="formItem">
            <Input placeholder="请搜索" style={{ width: '100px' }} />
          </Form.Item>
          <Form.Item name="channelType" className="formItem">
            <Select placeholder="请选择" style={{ width: '100px' }} allowClear>
              {typeList.map((item, index) => (
                <Option value={item.code} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="channelAccountId" className="formItem">
            <Select placeholder="请选择" style={{ width: '100px' }} allowClear>
              {accountList.map((item, index) => (
                <Option value={item.channelAccountId} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="status" className="formItem">
            <Select placeholder="请选择" style={{ width: '100px' }} allowClear>
              <Option value={0}>待回传</Option>
              <Option value={5}>成功</Option>
              <Option value={10}>失败</Option>
            </Select>
          </Form.Item>
          <Form.Item name="time" className="formItem">
            <RangePicker />
          </Form.Item>
          <Form.Item className="formItem">
            <Button type="primary" htmlType="submit">
              查询
            </Button>
          </Form.Item>
        </Form>
        <Table
          dataSource={pageData}
          rowKey={item => item.orgId} //React规范中的array都必须有key
          columns={columns}
          size="small"
          scroll={{
            x: 1500,
          }}
          onChange={changePage}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            total: pages.count || 0,
            showTotal: total => `共${total}条`,
            defaultPageSize: 10,
            pageSize: options.limit,
            defaultCurrent: 1,
            current: options.page,
            // pageSizeOptions: [10, 30, 50],
            position: ['bottomLeft'],
          }}
        />
      </Spin>
    </div>
  )
}
export default Attribution
