import {
  _addBenefitType,
  _benefitTypeDetails,
  _editeBenefitType,
  _getBenefitTypeList,
  _removeBenefitType,
  _equityFreeList,
} from 'servers/advertising/benefitType'
import { changeList } from '../../../Selectors/Common'
// 获取权益类型列表
export const getBenefitTypeList = async (data = {}) => {
  const res = await _getBenefitTypeList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除权益类型
export const removeBenefitType = async (data = {}) => {
  const res = await _removeBenefitType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改权益类型
export const editeBenefitType = async (data = {}) => {
  const res = await _editeBenefitType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增权益类型
export const addBenefitType = async (data = {}) => {
  const res = await _addBenefitType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取权益类型详情
export const benefitTypeDetails = async (data = {}) => {
  const res = await _benefitTypeDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 绑定测评题
export const equityFreeList = async (data = {}) => {
  const res = await _equityFreeList(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
