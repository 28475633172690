import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Cascader,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Table,
  message,
} from 'antd'
import ButtonGroup from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getAnalyList } from 'reduxs/advertising/analy'
import { getClaszList } from 'reduxs/advertising/clasz'
import { getQuestionList, removeQuestion } from 'reduxs/advertising/question'
import AddEditeModal from '../Compontents/AddEditeModal'
import DimensionModal from '../Compontents/DimensionModal'
import LangModal from '../Compontents/LangModal'
import { columns } from './_help'
import styles from './index.module.less'
const { Option } = Select
let options = {}
const Question = props => {
  const { history } = props
  const [selectedRowKeys, setSelectedRowKeys] = useState([]) // 选中的数据
  const [editeData, setEditeData] = useState({}) // 编辑存放每一条的数据
  const [loading, setLoading] = useState(false)
  const [pages, setPages] = useState({}) // 页码相关
  const [pageData, setPageData] = useState([]) // 列表
  const [classType, setClassType] = useState([]) // 分类
  const [analy, setAnaly] = useState([]) // 解析方式
  useEffect(() => {
    options = { page: 1, limit: 10 }
    getType()
    getAnaly()
    getList()
  }, [])
  // 获取题库列表
  const getList = async () => {
    setLoading(true)
    const res = await getQuestionList(options)
    setLoading(false)
    if (res.result === 200) {
      const { data, page } = res
      setPageData(data || [])
      if (page) {
        setPages(page || 0)
      }
    }
  }
  // 获取类型
  const getType = async () => {
    const res = await getClaszList({ status: 1, tree: true, paging: false })
    if (res.result === 200) {
      setClassType(res.data || [])
    }
  }
  // 获取解析方式
  const getAnaly = async () => {
    const res = await getAnalyList({ status: 1, paging: false })
    if (res.result === 200) {
      setAnaly(res.data || [])
    }
  }
  // 判断只能选择一条数据
  const isSeleteData = () => {
    if (!selectedRowKeys.length) {
      message.warn('请选择数据~')
      return
    }
    if (selectedRowKeys.length > 1) {
      message.warn('只能选择一条数据~')
      return
    }
    const key = selectedRowKeys[0]
    const findData = pageData.find(item => item.questionInfoId === key)
    return findData
  }
  // 编辑
  const handEdite = () => {
    const data = isSeleteData()
    if (data) {
      setEditeData({ ...data, _status: true, isUpdate: true })
    }
  }
  // 多语言
  const handLang = () => {
    const data = isSeleteData()
    if (data) {
      setEditeData({ ...data, langStatus: true })
    }
  }
  // 维度/基准
  const handQuestion = () => {
    const data = isSeleteData()
    if (data) {
      setEditeData({ ...data, dimensionStatus: true })
    }
  }
  // 题目管理
  const handSubject = () => {
    const data = isSeleteData()
    if (data) {
      history.push({ pathname: '/index/advertising/subject', state: data })
    }
  }
  // 结果管理
  const handResult = () => {
    const data = isSeleteData()
    if (data) {
      history.push({ pathname: '/index/advertising/result', state: data })
    }
  }
  // 删除
  const handDelete = async () => {
    const data = isSeleteData()
    if (data) {
      const { questionInfoId } = data
      const res = await removeQuestion(questionInfoId)
      if (res.result === 200) {
        message.success('删除成功~')
        setSelectedRowKeys([])
        getList()
      }
    }
  }
  // table可选中
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: selectedRowKeys => {
      setSelectedRowKeys([selectedRowKeys[selectedRowKeys.length - 1]])
    },
  }
  // 新增测评
  const handAdd = () => {
    setEditeData({ _status: true })
  }
  // 监听table变化
  const changePage = page => {
    options.page = page.current
    options.limit = page.pageSize
    getList()
  }
  // 搜索
  const onFinish = values => {
    const { claszId } = values
    if (claszId) {
      values.claszId = claszId[claszId.length - 1]
    }
    options = { page: 1, limit: 10, ...values }
    getList()
  }
  // 新增编辑成功
  const handSuccess = () => {
    setSelectedRowKeys([])
    setEditeData({})
    getList()
  }
  // 邮箱配置
  const handEmail = () => {
    const data = isSeleteData()
    if (data) {
      history.push({
        pathname: '/index/advertising/questionEmail',
        state: data,
      })
    }
  }
  return (
    <div className="pageContainer">
      <Spin tip="加载中" spinning={loading}>
        {editeData._status && (
          <AddEditeModal
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
            classType={classType}
            analy={analy}
          />
        )}
        {editeData.langStatus && (
          <LangModal
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
          />
        )}
        {editeData.dimensionStatus && (
          <DimensionModal
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
          />
        )}
        <Form onFinish={onFinish}>
          <Form.Item name="key1" className="formItem">
            <Input placeholder="请搜索" style={{ width: '200px' }} />
          </Form.Item>
          <Form.Item name="claszId" className="formItem">
            <Cascader
              options={classType}
              placeholder="请选择"
              changeOnSelect={false}
              style={{ minWidth: '200px' }}
            />
          </Form.Item>
          <Form.Item name="analyType" className="formItem">
            <Select placeholder="请选择" allowClear>
              {analy.map((item, index) => (
                <Option value={`${item.analyType}`} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="status" className="formItem">
            <Select placeholder="请选择" style={{ width: '200px' }} allowClear>
              <Option value={1}>启用</Option>
              <Option value={0}>禁用</Option>
            </Select>
          </Form.Item>
          <Form.Item className="formItem">
            <Button type="primary" htmlType="submit">
              查询
            </Button>
          </Form.Item>
        </Form>
        <div className={styles.buttonList}>
          <Space>
            <ButtonGroup
              onClick={handAdd}
              icon={<PlusOutlined />}
              btnId={btnApiList.questionAdd}
            >
              新增
            </ButtonGroup>
            <ButtonGroup
              onClick={handEdite}
              icon={<PlusOutlined />}
              btnId={btnApiList.questionEdite}
            >
              编辑
            </ButtonGroup>
            <ButtonGroup
              onClick={handLang}
              icon={<PlusOutlined />}
              btnId={btnApiList.questionLang}
            >
              多语言
            </ButtonGroup>
            <ButtonGroup
              onClick={handQuestion}
              icon={<PlusOutlined />}
              btnId={btnApiList.questionDimension}
            >
              维度/基准
            </ButtonGroup>
            <ButtonGroup
              onClick={handSubject}
              icon={<PlusOutlined />}
              btnId={btnApiList.questionSubject}
            >
              题目管理
            </ButtonGroup>
            <ButtonGroup
              onClick={handResult}
              icon={<PlusOutlined />}
              btnId={btnApiList.questionResult}
            >
              结果管理
            </ButtonGroup>
            <ButtonGroup
              onClick={handEmail}
              icon={<PlusOutlined />}
              btnId={btnApiList.questionEmail}
            >
              邮件配置
            </ButtonGroup>
            <ButtonGroup
              onClick={handDelete}
              icon={<CloseOutlined />}
              btnId={btnApiList.questionDelete}
            >
              删除
            </ButtonGroup>
          </Space>
        </div>
        <Table
          dataSource={pageData}
          rowKey={item => item.questionInfoId} //React规范中的array都必须有key
          columns={columns}
          size="small"
          onChange={changePage}
          rowSelection={rowSelection}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            total: pages.count || 0,
            showTotal: total => `共${total}条`,
            defaultPageSize: 10,
            pageSize: options.limit,
            defaultCurrent: 1,
            current: options.page,
            // pageSizeOptions: [10, 30, 50],
            position: ['bottomLeft'],
          }}
        />
      </Spin>
    </div>
  )
}
export default withRouter(Question)
