export const changeList = (res, key) => {
  if (res.result !== 200) {
    return res
  }
  const { data } = res
  if (!data) {
    return res
  }
  data.forEach((item, index) => {
    item.index = index + 1
    item.label = item.name
    item.value = key ? item[key] : item.menuId
    if (item.children?.length) {
      item.children = getChildren(item.children, key)
    }
  })
  return res
}
// 获取children
const getChildren = (list, key) => {
  list.forEach((item, index) => {
    item.index = index + 1
    item.label = item.name
    item.value = key ? item[key] : item.menuId
    if (item.children?.length) {
      getChildren(item.children, key)
    }
  })
  return list
}
