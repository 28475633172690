import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  message,
} from 'antd'
import Address from 'components/Address/Index'
import React, { useEffect, useState } from 'react'
import {
  addChannelMah,
  channelMahDetails,
  editeChannelMah,
} from 'reduxs/operation/channelMah'
import { getChannelTypeList } from 'reduxs/operation/channelType'
import { getSubData } from 'utils/Tools'
import styles from './add.module.less'
const { Option } = Select
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [typeList, setTypeList] = useState([])
  useEffect(() => {
    getType()
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const { channelMchId } = editeData
    if (channelMchId) {
      const res = await channelMahDetails(channelMchId)
      if (res.result === 200) {
        const { data } = res
        data.status = data.status === 1 ? true : false
        const cts = data.cts || []
        data.cts = cts.map(item => item.code)
        form.setFieldsValue(data)
      }
    }
  }
  // 获取渠道类型
  const getType = async () => {
    const res = await getChannelTypeList({ status: 1, paging: false })
    if (res.result === 200) {
      const { data } = res
      setTypeList(data || [])
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { channelMchId } = editeData
    const data = getSubData(values)
    data.sysn = undefined
    if (channelMchId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 编辑
  const editeList = async values => {
    const { channelMchId } = editeData
    values.channelMchId = channelMchId
    const res = await editeChannelMah(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改渠道商成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addChannelMah(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增渠道商成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title={editeData.channelMchId ? '编辑渠道商' : '新增渠道商'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{ status: true }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="全称"
                name="fullName"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入全称',
                  },
                ]}
              >
                <Input placeholder="请输入全称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="代码"
                name="mchId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入代码',
                  },
                ]}
              >
                <Input placeholder="请输入代码" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="状态"
                name="status"
                valuePropName="checked"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="渠道"
                name="cts"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择渠道',
                  },
                ]}
              >
                <Select placeholder="请选择渠道" mode="multiple">
                  {typeList.map((item, index) => (
                    <Option value={item.code} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="联系人" name="linkName">
                <Input placeholder="请输入联系人姓名" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="联系人电话" name="linkTel">
                <Input placeholder="请输入联系人电话" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Address _form={form} editeData={editeData} label="通讯地址" />
            </Col>
            <Col span={24}>
              <Form.Item name="address" style={{ marginLeft: '80px' }}>
                <Input placeholder="请输入联系人电话" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
