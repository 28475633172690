import axios from 'axios'

import { message } from 'antd'
import { getLocal, removeLocal } from 'utils/Help'
const version = process.env.REACT_APP_VERSION

/**
 * 请求拦截器
 * 处理http请求request，添加token等
 */
axios.interceptors.request.use(
  config => {
    // 自动设置 accessToken
    // config.headers.accessToken = accessToken
    // 自动设置 accessToken
    const token = getLocal('token')
    if (token !== null) {
      config.headers['access-token'] = token
    }
    config.headers['app-version'] = version
    if (
      process.env.NODE_ENV === 'development' ||
      process.env.NODE_ENV === 'mock'
    ) {
      //console.log('---request--->', config)
    }
    // 设置请求超时时长为10秒
    if (!config.timeout) {
      config.timeout = 10000
    }
    return config
  },
  error => {
    return Promise.error(error)
  }
)
/**
 * 响应拦截器
 * 处理http响应response，判断异常或错误等
 */
axios.interceptors.response.use(
  response => {
    const header = response.headers
    if (header['content-type'] === 'application/vnd.ms-excel;charset=UTF-8') {
      return Promise.resolve(response)
    }
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response.status === 200) {
      if (response.data.result === 200) {
        return Promise.resolve(response.data)
      } else {
        if (response.data.result === -1) {
          removeLocal('token')
          removeLocal('btns')
          removeLocal('menus')
          removeLocal('userInfo')
          removeLocal('permissionList')
          window.location.href = '/login'
        }
        message.error(response.data.message)
        return Promise.resolve(response.data)
      }
    } else {
      // console.log('失败了')
      return Promise.resolve(response)
    }
  },
  // 服务器状态码不是2开头的的情况
  // 下面列举几个常见的操作，其他需求可自行扩展
  error => {
    if (error.message === 'Network Error') {
      message.error('网络错误')
      return Promise.reject(error.toJSON())
    }
    if (error.message.includes('timeout of')) {
      // timeout of 10000ms exceeded
      message.error('响应超时')
      return Promise.reject(error.toJSON())
    }
    if (error.response && error.response.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          message.error('身份验证失败，请关闭重新进入。')
          break
        case 500:
          message.error('请求失败')
          break
        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          message.error('登录过期，请关闭重新进入。')
          // 清除token
          break

        // 404请求不存在
        case 404:
          message.error('您访问的网页不存在。')
          break
        // 其他错误，直接抛出错误提示
        default:
          message.error(error.response.data.message)
      }
      return Promise.reject(error.response)
    }
    return Promise.reject(error.toJSON())
  }
)

/**
 *
 * @param {*} options
 *
 * 用法
 *
  request({
      method: 'post',
      url: 'www.baidu.com',
      headers: { 'Content-Type': 'blob' },
      data: {
        firstName: 'Fred',
        lastName: 'Flintstone',
      },
    })
 */

export default function request(options) {
  return axios(options)
}
