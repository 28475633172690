import { Button } from 'antd'
import ButtonGroup, { TipButton } from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React from 'react'
const statusList = [
  { type: 5, name: '续存' },
  { type: 10, name: '停业' },
  { type: 15, name: '注销' },
]
const columns = eventData => {
  const { handEdite, handRemove } = eventData
  return [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 70,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: '全称',
      dataIndex: 'fullName',
      key: 'fullName',
      ellipsis: true,
    },
    {
      title: '代码',
      dataIndex: 'code',
      key: 'address',
      ellipsis: true,
    },
    {
      title: '注册地',
      dataIndex: 'address',
      key: 'address',
      ellipsis: true,
    },
    {
      title: '成立时间',
      dataIndex: 'rtime',
      key: 'rtime',
      ellipsis: true,
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      render: status => {
        const findData = statusList.find(item => item.type === status)
        return findData ? findData.name : ''
      },
    },
    {
      title: '操作',
      key: 'changge',
      fixed: 'right',
      width: 140,
      render: row => {
        return (
          <>
            <ButtonGroup
              onClick={() => handEdite(row)}
              btnId={btnApiList.companyEdite}
              type="link"
            >
              编辑
            </ButtonGroup>
            <TipButton
              title="你确定要该公司吗？"
              onConfirm={() => handRemove(row)}
              okText="确定"
              cancelText="取消"
              btnId={btnApiList.companyDelete}
            >
              <Button type="link">删除</Button>
            </TipButton>
          </>
        )
      },
    },
  ]
}
export { columns, statusList }
