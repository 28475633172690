// servers/system

// Selectors

// Actions

// Action Creators

// init data
import {
  _addRegionState,
  _editeRegionState,
  _getRegionStateList,
  _regionStateDetails,
  _removeRegionState,
} from 'servers/operation/regionState'
import { changeList } from '../../../Selectors/Common/index'
// 获取大洲列表
export const getRegionStateList = async (data = {}) => {
  const res = await _getRegionStateList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除大洲
export const removeRegionState = async (data = {}) => {
  const res = await _removeRegionState(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改大洲
export const editeRegionState = async (data = {}) => {
  const res = await _editeRegionState(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增大洲
export const addRegionState = async (data = {}) => {
  const res = await _addRegionState(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取大洲详情
export const regionStateDetails = async (data = {}) => {
  const res = await _regionStateDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
