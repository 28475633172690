import * as icons from '@ant-design/icons'
import Icon, { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  addMenu,
  editeMenu,
  getMenuList,
  menuDetails,
} from 'reduxs/system/menu'
import { getSubData } from 'utils/Tools'
import AddIcon from './AddIcon'
import styles from './add.module.less'
const { Option } = Select
const allOpt = {
  value: 0,
  label: '一级菜单',
}
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel } = props
  const [loading, setLoading] = useState(false)
  const [treeList, setTreeList] = useState([]) // 直接上级的结构
  const [iconData, setIconData] = useState({}) // 选择图标
  const [iconsStr, setIconsStr] = useState('')
  const [codeRequre, setCodeRequre] = useState(true)
  useEffect(() => {
    getTreeList()
    getTreeOneList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getTreeOneList = async () => {
    const res = await getMenuList({ paging: false, status: 1 })
    if (res.result === 200) {
      getDetails(res.data || [])
    }
  }
  // 获取直接上级
  const getTreeList = async () => {
    const res = await getMenuList({ paging: false, tree: true, status: 1 })
    if (res.result === 200) {
      const data = res.data || []
      const list = data.filter(item => item.menuId !== editeData.menuId)
      const treeData = getTreeData(list)
      setTreeList([allOpt, ...treeData])
    }
  }
  const getTreeData = list => {
    list.forEach(item => {
      item.children = (item.children || []).filter(
        item => item.type !== 20 && item.menuId !== editeData.menuId
      )
      if (item.children?.length) {
        getTreeData(item.children)
      }
    })
    return list
  }
  // 获取上个页面传递过来的详情
  const getDetails = async treeList => {
    if (editeData?.menuId) {
      const { menuId } = editeData
      const res = await menuDetails(menuId)
      if (res.result === 200) {
        const { data } = res
        setCodeRequre(data.sysn ? false : true)
        const parentdIds = getParentdIds(treeList, data.parentId)
        data.parentId = [
          ...parentdIds,
          ...(data.parentId ? [] : [data.parentId]),
        ]
        setIconsStr(data.icon)
        form.setFieldsValue(data)
      }
    }
  }
  const getParentdIds = (list, parentId, ids = []) => {
    list.forEach(item => {
      if (item.menuId === parentId) {
        ids.unshift(item.menuId)
        if (item.parentId) {
          getParentdIds(list, item.parentId, ids)
        }
      }
    })
    return ids
  }
  // 点击确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }

  // 提交form表单
  const onFinish = values => {
    values.parentId = values.parentId[values.parentId.length - 1]
    const data = getSubData(values)
    if (editeData?.menuId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 修改
  const editeList = async values => {
    values.menuId = editeData.menuId
    const res = await editeMenu(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('菜单修改成功~')
      props.handSuccess()
    }
  }
  // 新增菜单
  const addList = async values => {
    const res = await addMenu(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('菜单新增成功~')
      props.handSuccess()
    }
  }
  // 选择图标弹窗
  const handAddIcon = () => {
    setIconData({ status: true })
  }
  // 添加图标
  const handSuccess = str => {
    const data = form.getFieldsValue()
    data.icon = str
    form.setFieldsValue(data)
    setIconsStr(str)
    setIconData({})
  }
  // 监听系统编号变化
  const handBox = e => {
    const { checked } = e.target
    setCodeRequre(!checked)
  }
  return (
    <Modal
      width={600}
      title={editeData.menuId ? '编辑菜单' : '新增菜单'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        {iconData?.status && (
          <AddIcon
            iconData={iconData}
            handleCancel={() => setIconData({})}
            handSuccess={handSuccess}
            iconsStr={iconsStr}
          />
        )}

        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{
            status: true,
            sysn: false,
          }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="上级"
                name="parentId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择上级',
                  },
                ]}
              >
                <Cascader
                  options={treeList}
                  placeholder="请选择上级"
                  changeOnSelect
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="编号" key={codeRequre}>
                <Input.Group compact>
                  <Form.Item
                    name="code"
                    required={codeRequre ? true : false}
                    noStyle
                    rules={[
                      {
                        required: codeRequre,
                        message: '请输入编号',
                      },
                    ]}
                  >
                    <Input
                      placeholder="请输入编号"
                      style={{
                        width: '50%',
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="sysn"
                    className={styles.boxItem}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={handBox}>系统编号</Checkbox>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="类型"
                name="type"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择类型',
                  },
                ]}
              >
                <Select placeholder="请选择类型">
                  <Option value={5}>导航</Option>
                  <Option value={10}>菜单</Option>
                  <Option value={20}>按钮</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="图标">
                <Icon
                  component={icons[iconsStr]}
                  style={{
                    fontSize: '20px',
                  }}
                />
                <span onClick={handAddIcon} className={styles.icononyent}>
                  <PlusOutlined />
                </span>
              </Form.Item>
            </Col>
            <Col span={8} style={{ display: 'none' }}>
              <Form.Item label="图标" name="icon">
                <Input />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="排序" name="seq">
                <InputNumber min={0} defaultValue={0} precision={0} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="状态"
                name="status"
                required={true}
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Switch defaultChecked />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="路由" name="view">
                <Input placeholder="请输入路由" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
