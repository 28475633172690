import { Button, Modal, Space, Table, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { setAdsLang } from 'reduxs/advertising/advertising'
import { getLangList } from 'reduxs/operation/lang'
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 70,
  },
  {
    title: '名称',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: '代码',
    dataIndex: 'code',
    key: 'code',
  },
  {
    title: '语言',
    dataIndex: 'lang',
    key: 'lang',
  },
]
const LangList = props => {
  const { langData, handleCancel, handSuccess, tableData, adsId } = props
  const [pageData, setPageData] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  useEffect(() => {
    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getList = async () => {
    const checkIds = tableData.map(item => item.lang)
    const res = await getLangList({ status: 1, paging: false })
    if (res.result === 200) {
      const data = (res.data || []).filter(
        item => !checkIds.includes(item.lang)
      )
      data.forEach((item, index) => {
        item.index = index + 1
      })
      setPageData(data)
    }
  }
  // table可选中
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: selectedRowKeys => {
      setSelectedRowKeys(selectedRowKeys)
    },
  }
  // 确定
  const handleSubmit = async () => {
    if (!selectedRowKeys.length) {
      return message.error('请选择语言')
    }
    const list = (pageData || []).filter(item =>
      selectedRowKeys.includes(item.languageId)
    )
    list.forEach(item => {
      item.lang = item.code
    })
    const res = await setAdsLang({ langs: list, adsId: adsId })
    if (res.result === 200) {
      message.success('添加语言成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title="语言选择"
      open={langData.status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button key="submit" type="primary" onClick={handleSubmit}>
            确定
          </Button>
        </Space>,
      ]}
    >
      <Table
        dataSource={pageData}
        rowKey={item => item.languageId} //React规范中的array都必须有key
        columns={columns}
        size="small"
        rowSelection={rowSelection}
        pagination={false}
      />
    </Modal>
  )
}
export default LangList
