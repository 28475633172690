import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取组织列表
export const _getOrgList = async params => {
  return await request({
    method: 'GET',
    url: Api.SYSTEM.org.orgList,
    params: params,
  })
}
// 删除组织
export const _removeOrg = async params => {
  const path = btnApiList.orgMangeDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.SYSTEM.org.powerInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改组织
export const _editeOrg = async params => {
  const path = btnApiList.orgMangeEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.SYSTEM.org.powerInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增组织
export const _addOrg = async params => {
  const path = btnApiList.orgMangeAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.SYSTEM.org.powerInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取组织详情
export const _orgDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.SYSTEM.org.powerInfo}/${params}`,
  })
}
