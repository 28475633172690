import {
  _addQuestionAnsw,
  _editeQuestionAnsw,
  _getQuestionAnswList,
  _editeAnswContent,
  _questionAnswDetails,
  _questionAnswContent,
  _removeQuestionAnsw,
} from 'servers/advertising/questionAnsw'
import { changeList } from '../../../Selectors/Common/'
// 获取答案列表
export const getQuestionAnswList = async (data = {}) => {
  const res = await _getQuestionAnswList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除答案
export const removeQuestionAnsw = async (data = {}) => {
  const res = await _removeQuestionAnsw(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改答案
export const editeQuestionAnsw = async (data = {}) => {
  const res = await _editeQuestionAnsw(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增答案
export const addQuestionAnsw = async (data = {}) => {
  const res = await _addQuestionAnsw(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取答案详情
export const questionAnswDetails = async (data = {}) => {
  const res = await _questionAnswDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 答案多语言
export const questionAnswContent = async (id, lang) => {
  const res = await _questionAnswContent(id, lang)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
export const editeAnswContent = async data => {
  const res = await _editeAnswContent(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
