import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取业务域名列表
export const _getDomainList = async params => {
  return await request({
    method: 'GET',
    url: Api.OPERATION.domain.domain,
    params: params,
  })
}
// 删除业务域名
export const _removeDomain = async params => {
  const path = btnApiList.domainDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.OPERATION.domain.domainInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改业务域名
export const _editeDomain = async params => {
  const path = btnApiList.domainEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.OPERATION.domain.domainInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增业务域名
export const _addDomain = async params => {
  const path = btnApiList.domainAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.OPERATION.domain.domainInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取业务域名详情
export const _domainDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.domain.domainInfo}/${params}`,
  })
}

// 获取域名列表列表
export const _getDomainInfoList = async params => {
  return await request({
    method: 'GET',
    url: Api.OPERATION.domain.domainList,
    params: params,
  })
}
// 删除域名列表
export const _removeInfoDomain = async params => {
  const path = btnApiList.domainListDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.OPERATION.domain.domainListInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改域名列表
export const _editeInfoDomain = async params => {
  const path = btnApiList.domainListEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.OPERATION.domain.domainListInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增域名列表
export const _addDomainInfo = async params => {
  const path = btnApiList.domainListAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.OPERATION.domain.domainListInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取域名列表
export const _domainInfoDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.domain.domainListInfo}/${params}`,
  })
}
