// servers/system

// Selectors

// Actions

// Action Creators

// init data
import {
  _addLang,
  _editeLang,
  _getLangList,
  _langDetails,
  _removeLang,
} from 'servers/operation/lang'
import { changeList } from '../../../Selectors/Common/index'
// 获取货币列表
export const getLangList = async (data = {}) => {
  const res = await _getLangList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除货币
export const removeLang = async (data = {}) => {
  const res = await _removeLang(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改货币
export const editeLang = async (data = {}) => {
  const res = await _editeLang(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增货币
export const addLang = async (data = {}) => {
  const res = await _addLang(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取货币详情
export const langDetails = async (data = {}) => {
  const res = await _langDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
