import {
  _addQuestion,
  _addQuestionCondit,
  _deleteqMailInfo,
  _deleteQuestionCondit,
  _editeLang,
  _editeQuestion,
  _editeQuestionCondit,
  _getContent,
  _getQuestionConditInfoContent,
  _getQuestionList,
  _questionCondDetails,
  _questionConditInfoContent,
  _questionConditList,
  _questionDetails,
  _questionMailAdd,
  _questionMailDetail,
  _questionMailEdite,
  _questionMailLang,
  _questionMailList,
  _removeQuestion,
  _updateContent,
} from 'servers/advertising/question'
import { changeList } from '../../../Selectors/Common'
// 获取题库列表
export const getQuestionList = async (data = {}) => {
  const res = await _getQuestionList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除题库
export const removeQuestion = async (data = {}) => {
  const res = await _removeQuestion(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改题库
export const editeQuestion = async (data = {}) => {
  const res = await _editeQuestion(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增题库
export const addQuestion = async (data = {}) => {
  const res = await _addQuestion(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取题库详情
export const questionDetails = async (data = {}) => {
  const res = await _questionDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 题库内容编辑
export const updateContent = async (data = {}) => {
  const res = await _updateContent(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取题库内容
export const getContent = async (id, lang) => {
  const res = await _getContent(id, lang)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 维度基准列表
export const questionConditList = async params => {
  const res = await _questionConditList(params)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 维度新增
export const addQuestionCondit = async params => {
  const res = await _addQuestionCondit(params)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取维度详情
export const questionCondDetails = async params => {
  const res = await _questionCondDetails(params)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 维度基准多语言
export const questionConditInfoContent = async params => {
  const res = await _questionConditInfoContent(params)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取维度多语言
export const getQuestionConditInfoContent = async (id1, id2) => {
  const res = await _getQuestionConditInfoContent(id1, id2)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 编辑维度
export const editeQuestionCondit = async params => {
  const res = await _editeQuestionCondit(params)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 删除维度
export const deleteQuestionCondit = async params => {
  const res = await _deleteQuestionCondit(params)
  return new Promise(function (resolve) {
    resolve(res)
  })
}

// 题库邮箱列表
export const questionMailList = async params => {
  const res = await _questionMailList(params)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 邮箱类型新增
export const questionMailAdd = async params => {
  const res = await _questionMailAdd(params)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
export const questionMailEdite = async params => {
  const res = await _questionMailEdite(params)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 邮箱类型详情
export const questionMailDetail = async (id1, id2) => {
  const res = await _questionMailDetail(id1, id2)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
export const questionMailLang = async (id1, id2) => {
  const res = await _questionMailLang(id1, id2)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
export const editeLang = async params => {
  const res = await _editeLang(params)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 邮箱类型删除
export const deleteqMailInfo = async (id1, id2) => {
  const res = await _deleteqMailInfo(id1, id2)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
