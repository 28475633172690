import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  message,
} from 'antd'
import Address from 'components/Address/Index'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import {
  addCompany,
  companyDetails,
  editeCompany,
} from 'reduxs/operation/company'
import styles from './add.module.less'
const { Option } = Select
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const { companyId } = editeData
    if (companyId) {
      const res = await companyDetails(companyId)
      if (res.result === 200) {
        const { data } = res
        data.rtime = moment(data.rtime, 'YYYY-MM-DD')
        form.setFieldsValue(data)
      }
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { companyId } = editeData
    values.rtime = moment(values.rtime).format('YYYY-MM-DD')
    if (companyId) {
      editeList(values)
      return
    }
    addList(values)
  }
  // 编辑
  const editeList = async values => {
    const { companyId } = editeData
    values.companyId = companyId
    const res = await editeCompany(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改公司成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addCompany(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增公司成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title={editeData.companyId ? '编辑业务公司' : '新增业务公司'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="全称"
                name="fullName"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入全称',
                  },
                ]}
              >
                <Input placeholder="请输入全称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="代码"
                name="code"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入代码',
                  },
                ]}
              >
                <Input placeholder="请输入代码" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="成立时间"
                name="rtime"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入成立时间',
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Address _form={form} editeData={editeData} />
            </Col>
            <Col span={24}>
              <Form.Item label="地址" name="address">
                <Input placeholder="请输入地址" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="状态"
                name="status"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Select
                  placeholder="请选择"
                  style={{ width: '200px' }}
                  allowClear
                >
                  <Option value={5}>续存</Option>
                  <Option value={10}>停业</Option>
                  <Option value={15}>注销</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
