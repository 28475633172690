import { Button, Image, Modal, Space, Table, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { setPaymentType } from 'reduxs/operation/country'

const PayTypeModal = props => {
  const { status, handleCancel, editeData, handSuccess, typeList } = props
  const [loading, setLoading] = useState(false)
  const [pageData, setPageData] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  useEffect(() => {
    setPageData(typeList)
  }, [typeList])
  // 编辑确定
  const handleSubmit = async () => {
    if (!selectedRowKeys.length) {
      return message.warning('请选择输入')
    }
    const { countryId } = editeData
    const list = pageData.filter(item =>
      selectedRowKeys.includes(item.paymentTypeId)
    )
    const paymentTypes = list.map(item => {
      return {
        paymentMchTypeId: item.paymentMchTypeId,
        paymentType: item.paymentTypeId,
        seq: 0,
      }
    })
    setLoading(true)
    const res = await setPaymentType({
      countryId: countryId,
      paymentTypes: paymentTypes,
    })
    if (res.result === 200) {
      message.success('绑定成功~')
      handSuccess()
    }
  }
  // table可选中
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: selectedRowKeys => {
      setSelectedRowKeys(selectedRowKeys)
    },
  }
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 70,
    },
    {
      title: '商户类型',
      dataIndex: 'paymentMchType',
      key: 'paymentMchType',
      render: row => row?.name || '',
    },
    {
      title: 'ICON',
      dataIndex: 'icon',
      key: 'icon',
      render: text => {
        return text ? <Image src={text} width={24} /> : text
      },
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '代码',
      dataIndex: 'code',
      key: 'code',
    },
  ]
  return (
    <Modal
      width={800}
      title="添加支付方式"
      open={status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <Table
        dataSource={pageData}
        rowKey={item => item.paymentTypeId} //React规范中的array都必须有key
        columns={columns}
        size="small"
        rowSelection={rowSelection}
        // onChange={changePage}
        pagination={false}
      />
    </Modal>
  )
}
export default PayTypeModal
