import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Tabs,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  getContent as _getContent,
  updateContent,
} from 'reduxs/advertising/question'
import { getLangList } from 'reduxs/operation/lang'
import styles from './index.module.less'
const { TextArea } = Input
const LangModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel } = props
  const [loading, setLoading] = useState(false)
  const [langList, setLangList] = useState([])
  const [activeData, setActiveData] = useState({}) // 当前激活的语言
  useEffect(() => {
    getLang()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取语言列表
  const getLang = async () => {
    const res = await getLangList({ paging: false, status: 1 })
    if (res.result === 200) {
      const data = res.data || []
      data.forEach(item => {
        item.label = item.name
        item.key = item.languageId
      })
      if (data.length) {
        setActiveData(data[0])
        getContent(data[0])
      }
      setLangList(data)
    }
  }
  // 获取语言详情
  const getContent = async data => {
    console.log(data)
    form.resetFields()
    const { code } = data
    const { questionInfoId } = editeData
    const res = await _getContent(questionInfoId, code)
    if (res.result === 200) {
      const { data } = res
      if (data) {
        form.setFieldsValue(data)
      } else {
        form.resetFields()
      }
    }
  }

  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = async values => {
    const { code } = activeData
    const { questionInfoId } = editeData
    const data = {
      ...values,
      lang: code,
      questionInfoId: questionInfoId,
    }
    const res = await updateContent(data)
    setLoading(false)
    if (res.result === 200) {
      message.success('测评题多语言编辑成功~')
      // handSuccess()
    }
  }
  // 语言切换
  const langChange = key => {
    const findData = langList.find(item => item.languageId * 1 === key * 1)
    if (findData) {
      setActiveData(findData)
      getContent(findData)
    }
  }
  return (
    <Modal
      width={600}
      title="测评题多语言"
      open={editeData?.langStatus}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{ status: true }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="测评分类">{editeData.clasz?.name}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="名称">{editeData.title}</Form.Item>
            </Col>
            <Col span={24}>
              <Tabs
                items={langList}
                activeKey={activeData?.languageId}
                tabPosition="top"
                onChange={langChange}
              />
            </Col>
            <Col span={24}>
              <Form.Item
                label="名称"
                name="title"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="主题" name="subject">
                <TextArea rows={4} placeholder="请输入主题" maxLength={120} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="支付说明" name="payDescr">
                <TextArea
                  rows={4}
                  placeholder="请输入支付说明"
                  maxLength={120}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default LangModal
