import {
  _addAnaly,
  _analyDetails,
  _editeAnaly,
  _getAnalyList,
  _removeAnaly,
} from 'servers/advertising/analy'
import { changeList } from '../../../Selectors/Common'
// 获取解析方式列表
export const getAnalyList = async (data = {}) => {
  const res = await _getAnalyList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除解析方式
export const removeAnaly = async (data = {}) => {
  const res = await _removeAnaly(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改解析方式
export const editeAnaly = async (data = {}) => {
  const res = await _editeAnaly(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增解析方式
export const addAnaly = async (data = {}) => {
  const res = await _addAnaly(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取解析方式详情
export const analyDetails = async (data = {}) => {
  const res = await _analyDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
