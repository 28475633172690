import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Cascader,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Table,
  message,
} from 'antd'
import ButtonGroup from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import {
  adsCopy,
  adsExport,
  getAdvertisingList,
  removeAdvertising,
} from 'reduxs/advertising/advertising'
import { getClaszList } from 'reduxs/advertising/clasz'
import { getQuestionList } from 'reduxs/advertising/question'
import { getChannelAccList } from 'reduxs/operation/channelAcc'
import { getDomainList } from 'reduxs/operation/domain'
import { enumCodeList } from 'reduxs/system/enun'
import { downloadFile } from 'utils/Tools'
import AddEditeModal from '../Compontents/AddEditeModal'
import LangModal from '../Compontents/LangModal'
import MailManage from '../Compontents/MailManage'
import MoreModal from '../Compontents/MoreModal'
import PayMerchant from '../Compontents/PayMerchant'
import { btnList, columns } from './_help'
import styles from './index.module.less'
let options = {}
const { Option } = Select
const otherOption = { status: 1, paging: false }
const SubjectManage = props => {
  const [form] = Form.useForm()
  const { history } = props
  const [selectedRowKeys, setSelectedRowKeys] = useState([]) // 选中的数据
  const [editeData, setEditeData] = useState({}) // 编辑存放每一条的数据
  const [loading, setLoading] = useState(false)
  const [pageData, setPageData] = useState([]) // 列表
  const [pages, setPages] = useState({}) // 页码相关
  const [businessType, setBusinessType] = useState([]) // 业务分类
  const [tyList, setTypeLIst] = useState([]) // 所有下拉数据
  const [loadLoading, setLoadLoading] = useState(false)
  useEffect(() => {
    options = { page: 1, limit: 10 }
    getList()
    getTypeData()
    getBtypeList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取下拉类型
  const getTypeData = async () => {
    Promise.all([
      await getQuestionList(otherOption),
      await enumCodeList('PROFIT_TYPE'), // 模式
      await getChannelAccList(otherOption), // 渠道账户
      await getDomainList(otherOption), // 业务域名
    ]).then(res => {
      setTypeLIst(res)
    })
  }
  // 获取业务分类
  const getBtypeList = async () => {
    const res = await getClaszList({ status: 1, paging: false, tree: true })
    if (res.result === 200) {
      const { data } = res
      setBusinessType(data || [])
    }
  }
  // 获取题目列表
  const getList = async () => {
    setLoading(true)
    const res = await getAdvertisingList(options)
    setLoading(false)
    if (res.result === 200) {
      const { data, page } = res
      setPageData(data || [])
      if (page) {
        setPages(page || 0)
      }
    }
  }
  // 判断只能选择一条数据
  const isSeleteData = () => {
    if (!selectedRowKeys.length) {
      message.warn('请选择数据~')
      return
    }
    if (selectedRowKeys.length > 1) {
      message.warn('只能选择一条数据~')
      return
    }
    const key = selectedRowKeys[0]
    const findData = pageData.find(item => item.adsId === key)
    return findData
  }
  // table可选中
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: selectedRowKeys => {
      setSelectedRowKeys([selectedRowKeys[selectedRowKeys.length - 1]])
    },
  }
  // 监听table变化
  const changePage = page => {
    options.page = page.current
    options.limit = page.pageSize
    getList()
  }
  // 搜索
  const onFinish = values => {
    const { claszId } = values
    if (claszId?.length) {
      values.claszId = claszId[claszId.length - 1]
    }
    options = { page: 1, limit: 10, ...values }
    getList()
  }
  // 新增编辑成功
  const handSuccess = () => {
    setSelectedRowKeys([])
    setEditeData({})
    getList()
  }
  //
  const handRemove = async () => {
    const data = isSeleteData()
    if (data) {
      const res = await removeAdvertising(data.adsId)
      if (res.result === 200) {
        message.success('删除成功~')
        getList()
      }
    }
  }
  // 方案
  const handProgramme = () => {
    const data = isSeleteData()
    if (data) {
      history.push({
        pathname: '/index/advertising/programme',
        state: data,
      })
    }
  }
  // 点击复制
  const handCopy = async () => {
    const data = isSeleteData()
    if (data) {
      setLoading(true)
      const { adsId } = data
      const res = await adsCopy({ adsId: adsId })
      if (res.result === 200) {
        message.success('复制成功~')
        getList()
      } else {
        setLoading(false)
      }
    }
  }
  // 按钮点击
  const btnClick = item => {
    const { type } = item
    if (type === 'copy') {
      handCopy()
      return
    }
    if (type === 'delete') {
      handRemove()
      return
    }
    if (type === 'programme') {
      handProgramme()
      return
    }
    let dedata = {}
    if (!item.notIsSelect) {
      dedata = isSeleteData()
      if (!dedata) {
        return
      }
    }
    const data = { ...item, ...dedata }
    if (data) {
      setEditeData(data)
    }
  }
  const getOptions = (num, id, name) => {
    if (!tyList) {
      return []
    }
    const res = tyList[num] || []
    const { data } = res
    return data
      ? data.map((item, index) => (
          <Option key={index} value={item[id]}>
            {item[name]}
          </Option>
        ))
      : []
  }
  // 更多
  const handMore = row => {
    const data = { ...row, type: 'more' }
    setEditeData(data)
  }
  // 下载
  const handDownLoad = async () => {
    setLoadLoading(true)
    const formData = form.getFieldsValue()
    const data = { ...formData, page: undefined, limit: undefined }
    const res = await adsExport(data)
    setLoadLoading(false)
    try {
      downloadFile(res)
      message.success('下载成功')
    } catch (error) {
      message.success('失败成功')
    }
  }
  return (
    <div className="pageContainer">
      <Spin tip="加载中" spinning={loading}>
        {editeData.type === 'add' && (
          <AddEditeModal
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
            tyList={tyList}
          />
        )}
        {editeData.type === 'email' && (
          <MailManage
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
          />
        )}

        {editeData.type === 'lang' && (
          <LangModal
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
          />
        )}
        {editeData.type === 'pay' && (
          <PayMerchant
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
          />
        )}
        {editeData.type === 'more' && (
          <MoreModal
            editeData={editeData}
            handleCancel={() => setEditeData({})}
          />
        )}

        <Form onFinish={onFinish} form={form}>
          <Form.Item name="searchKey" className="formItem">
            <Input placeholder="请搜索" style={{ width: '100px' }} />
          </Form.Item>
          <Form.Item name="claszId" className="formItem">
            <Cascader
              options={businessType}
              placeholder="请选择"
              changeOnSelect={false}
              style={{ minWidth: '200px' }}
            />
          </Form.Item>
          <Form.Item name="infoId" className="formItem">
            <Select
              placeholder="请选择题库"
              style={{ width: '100px' }}
              allowClear
            >
              {getOptions(0, 'questionInfoId', 'title')}
            </Select>
          </Form.Item>
          <Form.Item name="putin" className="formItem">
            <Select
              placeholder="请选择模式"
              style={{ width: '100px' }}
              allowClear
            >
              {getOptions(1, 'val', 'name')}
            </Select>
          </Form.Item>
          <Form.Item name="channelAccountId" className="formItem">
            <Select
              placeholder="请选择渠道账户"
              style={{ width: '100px' }}
              allowClear
            >
              {getOptions(2, 'channelAccountId', 'name')}
            </Select>
          </Form.Item>
          <Form.Item name="domainId" className="formItem">
            <Select
              placeholder="请选择业务域名"
              style={{ width: '100px' }}
              allowClear
            >
              {getOptions(3, 'domainId', 'domain')}
            </Select>
          </Form.Item>
          <Form.Item name="status" className="formItem">
            <Select placeholder="请选择" style={{ width: '100px' }} allowClear>
              <Option value={1}>启用</Option>
              <Option value={0}>禁用</Option>
            </Select>
          </Form.Item>
          <Form.Item className="formItem">
            <Space>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <ButtonGroup
                btnId={btnApiList.manageDownLoad}
                onClick={handDownLoad}
                loading={loadLoading}
              >
                下载
              </ButtonGroup>
            </Space>
          </Form.Item>
        </Form>
        <div className={styles.buttonList}>
          <Space>
            {btnList.map(item => (
              <ButtonGroup
                onClick={() => btnClick(item)}
                icon={<PlusOutlined />}
                btnId={btnApiList[item.status]}
              >
                {item.name}
              </ButtonGroup>
            ))}
          </Space>
        </div>
        <Table
          dataSource={pageData}
          rowKey={item => item.adsId} //React规范中的array都必须有key
          columns={columns(handMore)}
          size="small"
          scroll={{
            x: 1800,
          }}
          onChange={changePage}
          rowSelection={rowSelection}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            total: pages.count || 0,
            showTotal: total => `共${total}条`,
            defaultPageSize: 10,
            pageSize: options.limit,
            defaultCurrent: 1,
            current: options.page,
            // pageSizeOptions: [10, 30, 50],
            position: ['bottomLeft'],
          }}
        />
      </Spin>
    </div>
  )
}
export default withRouter(SubjectManage)
