import { Button, Checkbox, Col, Form, Input, Row, Spin, message } from 'antd'
import Images from 'assets/Images/index'
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import { btns, login, menus, userInfo } from 'reduxs/login'
import { setLocal } from 'utils/Help'
import styles from './index.module.less'
const Login = props => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const { history } = props
  // 提交
  const handleClickSubmit = async params => {
    setLoading(true)
    const res = await login(params)
    setLoading(false)
    if (res.result === 200) {
      const { data } = res
      setLocal('token', data.accessToken)
      getUserInfo()
      getBtns()
      getMenus()
    }
  }
  // 获取个人信息
  const getUserInfo = async () => {
    const res = await userInfo()
    if (res.result === 200) {
      message.success('登录成功')
      const { data } = res
      setLocal('userInfo', JSON.stringify(data))
    }
  }
  // 获取按钮权限
  const getBtns = async () => {
    const res = await btns()
    if (res.result === 200) {
      const { data } = res
      setLocal('btns', JSON.stringify(data))
    }
  }
  // 获取菜单权限
  const getMenus = async () => {
    const res = await menus()
    if (res.result === 200) {
      const { data } = res
      setLocal('menus', JSON.stringify(data))
      setTimeout(() => {
        history.push('')
      }, 1000)
    }
  }
  return (
    <div
      className={styles.login}
      style={{
        background: `url(${Images.loginBg}) no-repeat center center`,
        backgroundSize: 'cover',
      }}
    >
      <div className={styles.loginForm}>
        <div className={styles.loginFrom}>
          <Spin tip="登录中..." type="beat" spinning={false}>
            {/* <div className={styles.loginLogo}>
              <Image
                src={Images.loginLogo}
                preview={false}
                key={Images.loginLogo}
              />
            </div> */}
            <Form
              className={styles.form}
              onFinish={handleClickSubmit}
              form={form}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="账号"
                    name="account"
                    required
                    rules={[{ required: true, message: '请输入账号' }]}
                    className={styles.from_item}
                  >
                    <Input bordered={false} placeholder="请输入账号" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label="密码"
                    name="password"
                    required
                    rules={[{ required: true, message: '请输入密码' }]}
                    className={styles.from_item}
                  >
                    <Input.Password bordered={false} placeholder="请输入密码" />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    style={{
                      height: '3.5em',
                      marginTop: '1.5em',
                      background: '#005BB5',
                    }}
                    loading={loading}
                  >
                    登录
                  </Button>
                </Col>
                <Col span={10}>
                  <Form.Item
                    className={styles.remenber}
                    // name="remember"
                    valuePropName="checked"
                    style={{ marginTop: '10px' }}
                  >
                    <Checkbox className={styles.check}>记住我</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  )
}
export default withRouter(Login)
