import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取题目列表
export const _getQuestionAnswList = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.question.questionAnswList,
    params: params,
  })
}
// 删除题目
export const _removeQuestionAnsw = async params => {
  const path = btnApiList.subjectDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.ADVERSRIING.question.questionAnswInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改题目
export const _editeQuestionAnsw = async params => {
  const path = btnApiList.subjectEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.ADVERSRIING.question.questionAnswInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增题目
export const _addQuestionAnsw = async params => {
  const path = btnApiList.subjectAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.question.questionAnswInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取题目详情
export const _questionAnswDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.question.questionAnswInfo}/${params}`,
  })
}
// 获取多语言
export const _questionAnswContent = async (id, lang) => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.question.questionAnswContent}/${id}/${lang}`,
  })
}
export const _editeAnswContent = async data => {
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.question.questionAnswContent,
    data: data,
  })
}
