import {
  Button,
  Cascader,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Switch,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  addClasz,
  claszDetails,
  editeClasz,
  getClaszList,
} from 'reduxs/advertising/clasz'
import { getSubData } from 'utils/Tools'
import styles from './add.module.less'
const { TextArea } = Input
const oneData = {
  value: 0,
  label: '顶级类型',
}
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [parentList, setParentList] = useState([])
  useEffect(() => {
    getParentList()
    getTreeOneList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getTreeOneList = async () => {
    const res = await getClaszList({ paging: false, status: 1 })
    if (res.result === 200) {
      getDetails(res.data || [])
    }
  }
  // 获取详情
  const getDetails = async treeList => {
    const { claszId } = editeData
    if (claszId) {
      const res = await claszDetails(claszId)
      if (res.result === 200) {
        const { data } = res
        data.status = data.status === 1 ? true : false
        // const { parentId } = data
        const parentdIds = getParentdIds(treeList, data.parentId)
        data.parentId = [
          ...parentdIds,
          ...(data.parentId ? [] : [data.parentId]),
        ]
        // data.parentId = [parentId]
        form.setFieldsValue(data)
      }
    }
  }
  const getParentdIds = (list, parentId, ids = []) => {
    list.forEach(item => {
      if (item.claszId === parentId) {
        ids.unshift(item.claszId)
        if (item.parentId) {
          getParentdIds(list, item.parentId, ids)
        }
      }
    })
    return ids
  }
  // 获取父级
  const getParentList = async () => {
    const res = await getClaszList({ paging: false, status: 1, tree: true })
    if (res.result === 200) {
      const data = res.data || []
      const list = data.filter(item => item.claszId !== editeData.claszId) // 过滤掉自己
      const treeData = getTreeData(list || [])
      setParentList([oneData, ...(treeData || [])])
    }
  }
  const getTreeData = list => {
    list.forEach(item => {
      item.children = (item.children || []).filter(
        item => item.claszId !== editeData.claszId // 过滤掉自己
      )
      if (item.children?.length) {
        getTreeData(item.children)
      }
    })
    return list
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { claszId } = editeData
    const { parentId } = values
    values.parentId = parentId[parentId.length - 1]
    const data = getSubData(values)
    data.sysn = undefined
    if (claszId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 编辑
  const editeList = async values => {
    const { claszId } = editeData
    values.claszId = claszId
    const res = await editeClasz(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改业务分类成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addClasz(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增业务分类成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title={editeData.claszId ? '编辑分类' : '新增分类'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{
            status: true,
          }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="上级"
                name="parentId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择分类',
                  },
                ]}
              >
                <Cascader
                  options={parentList}
                  placeholder="请选择上级"
                  changeOnSelect
                />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item
                label="名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="代码"
                name="code"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入代码',
                  },
                ]}
              >
                <Input
                  placeholder="请输入代码"
                  disabled={editeData.claszId ? true : false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="排序" name="seq">
                <InputNumber min={0} defaultValue={0} precision={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="状态"
                name="status"
                required={true}
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="说明" name="descr">
                <TextArea rows={4} placeholder="请输入说明" maxLength={120} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
