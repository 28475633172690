import headerBg from './headerBg.png'
import loginBg from './loginBg.jpg'
import Logo from './logo.png'

const Imgs = {
  Logo,
  loginBg,
  headerBg,
}

export default Imgs
