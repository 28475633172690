import {
  _addChannelMah,
  _channelMahDetails,
  _editeChannelMah,
  _getChannelMahList,
  _removeChannelMah,
} from 'servers/operation/channelMah'
import { changeList } from '../../../Selectors/Common/index'
// 获取渠道商列表
export const getChannelMahList = async (data = {}) => {
  const res = await _getChannelMahList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除渠道商
export const removeChannelMah = async (data = {}) => {
  const res = await _removeChannelMah(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改渠道商
export const editeChannelMah = async (data = {}) => {
  const res = await _editeChannelMah(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增渠道商
export const addChannelMah = async (data = {}) => {
  const res = await _addChannelMah(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取渠道商详情
export const channelMahDetails = async (data = {}) => {
  const res = await _channelMahDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
