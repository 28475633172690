import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons'

import { Dropdown, Image, Layout, Menu, message } from 'antd'
import { PAGE_PATH } from 'apis/Router'
import Imgs from 'imgs'
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { _getBiurl, _logout } from 'servers/login'
import { getLocal, removeLocal } from 'utils/Help'
import { isLocal } from 'utils/Tools'
import InfoMadol from './Components/InfoMadol'
import PasswordModal from './Components/PasswordModal'
import { defalutList, getMenuList } from './_help'
import styles from './index.module.less'
const { Header } = Layout

export const SiderList = []
const MianHeader = props => {
  const userInfo = isLocal('userInfo')
  const pathname = props.location.pathname
  const { history } = props
  const [downStatus, setDownStatus] = useState(false) // 下拉状态
  const [current, setCurrent] = useState('index') // 当前头部激活的menu
  const [menuItems, setMenuItems] = useState([]) // 动态头部导航
  const [infoData, setInfoData] = useState({}) // 个人信息
  const [biData, setBiData] = useState({})
  // const [menuKey, setMenuKey] = useState(false) // menu的key值
  useLayoutEffect(() => {
    getPathStatus()
    getMenuItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])
  useEffect(() => {
    getBiurl()
  }, [])
  // 获取biurl
  const getBiurl = async () => {
    const res = await _getBiurl({})
    if (res.result === 200) {
      const { data } = res
      setBiData(data || {})
    }
  }
  // 获取是否有头部导航权限
  const getMenuItems = () => {
    const menus = getLocal('menus') ? JSON.parse(getLocal('menus')) : []
    const menuList = getMenuList(menus)
    if (pathname === '/' && menuList?.length) {
      history.push(menuList[0].path)
    }
    setMenuItems(menuList)
  }
  // 点击loggo
  const hancLogo = () => {
    const list = [...menuItems]
    if (list?.length) {
      history.push(list[0].path)
    }
  }
  const getPathStatus = () => {
    const findData = defalutList.find(item => pathname.includes(item.key))
    setCurrent(findData?.key)
  }
  // 监听下拉
  const onOpenChange = e => {
    setDownStatus(e)
  }
  // 个人信息
  const handleInfo = () => {
    setInfoData({ _status: true })
  }
  // 修改密码
  const handlePassword = () => {
    setInfoData({ wordStatus: true })
  }
  const handleFolw = () => {
    const { biUrl } = biData
    window.open(biUrl)
  }
  const menu = () => {
    const { biUrl } = biData
    const list = [
      {
        key: '2',
        label: <span onClick={() => handleInfo()}> 个人信息 </span>,
      },
      {
        key: '3',
        label: <span onClick={() => handlePassword()}> 修改密码 </span>,
      },
      {
        key: '4',
        label: <span onClick={() => handleFolw()}> 运营数据 </span>,
        isBi: true,
      },
      {
        key: '1',
        label: <span onClick={() => handleLoginOut()}> 退出登录 </span>,
      },
    ]
    return biUrl ? list : list.filter(item => !item.isBi)
  }
  const onSelect = keys => {
    const { key } = keys
    const findData = menuItems.find(item => item.key === key)
    if (findData) {
      history.push(findData.path)
    }
  }
  // 退出登陆
  const handleLoginOut = async () => {
    const res = await _logout()
    if (res.result === 200) {
      message.success('退出登录成功！')
      removeLocal('token')
      removeLocal('userInfo')
      removeLocal('permissionList')
      removeLocal('btns')
      removeLocal('menus')
      // removeLocal('cosSecret')
      history.push(PAGE_PATH.login)
    }
  }
  const handSuccess = () => {
    removeLocal('token')
    removeLocal('userInfo')
    removeLocal('permissionList')
    removeLocal('btns')
    removeLocal('menus')
    // removeLocal('cosSecret')
    history.push(PAGE_PATH.login)
  }
  return (
    <Header className={styles.headerContainer}>
      {infoData._status && (
        <InfoMadol
          infoData={infoData}
          handleCancel={() => setInfoData({})}
          handSuccess={handSuccess}
        />
      )}
      {infoData.wordStatus && (
        <PasswordModal
          infoData={infoData}
          handleCancel={() => setInfoData({})}
          handSuccess={handSuccess}
        />
      )}

      <div className={styles.headerBg}>
        <Image src={`${Imgs.headerBg}`} preview={false} width={1920} />
      </div>
      <div className={styles.centenContainer}>
        <div className={styles.containerLeft} onClick={hancLogo}>
          <Image src={`${Imgs.Logo}`} preview={false} />
        </div>
        <div className={styles.index}>
          <Menu
            selectedKeys={[current]}
            mode="horizontal"
            items={menuItems}
            onSelect={onSelect}
          />
        </div>

        <div className={styles.containerRight}>
          <Dropdown
            menu={{ items: menu() }}
            placement="bottomLeft"
            onOpenChange={onOpenChange}
          >
            <span className={styles.pointer}>
              {/* <Avatar className={styles.avatar} src={userInfo?.head} /> */}
              {userInfo?.account?.account}
              {!downStatus ? (
                <CaretDownOutlined className={styles.arrow} />
              ) : (
                <CaretUpOutlined className={styles.arrow} />
              )}
            </span>
          </Dropdown>
        </div>
      </div>
    </Header>
  )
}

export default withRouter(MianHeader)
