import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取支付商户列表
export const _getPaymentList = async params => {
  return await request({
    method: 'GET',
    url: Api.OPERATION.payManage.paymentList,
    params: params,
  })
}
// 删除支付商户
export const _removePayment = async params => {
  const path = btnApiList.merchantDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.OPERATION.payManage.paymentInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改支付商户
export const _editePayment = async params => {
  const path = btnApiList.merchantEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.OPERATION.payManage.paymentInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增支付商户
export const _addPayment = async params => {
  const path = btnApiList.merchantAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.OPERATION.payManage.paymentInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取支付商户详情
export const _paymentDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.payManage.paymentInfo}/${params}`,
  })
}
// 是否默认收款
export const _adsPaymentListStatus = async id1 => {
  return await request({
    method: 'PUT',
    url: `${Api.ADVERSRIING.advertising.adsPaymentListStatus}/${id1}`,
  })
}
