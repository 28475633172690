import Icon, * as icons from '@ant-design/icons'
import Router from 'apis/Router'
import React from 'react'
const defalutList = [
  {
    label: '投放管理',
    key: '/index/advertising',
    path: '/index/advertising/advertising',
    view: '/index/advertising',
  },
  {
    label: '运维配置',
    key: '/operation',
    path: '/operation/channelAccount',
    view: '/operation',
  },
  {
    label: '系统管理',
    key: '/system',
    path: '/system/staff',
    view: '/systemManage',
  },
]
// 获取头部菜单
const getMenuList = lists => {
  const list = defalutList.filter(item => {
    const findData = lists.find(l => l.view === item.view && l.type === 5)
    const iconStr = findData?.icon
    const data = Router.find(route => route.key === item.key)
    const pathList = oneList(data.children, lists)
    if (pathList.length) {
      item.path = pathList[0].path
    }
    if (iconStr) {
      item.icon = <Icon component={icons[findData?.icon]} />
    }
    return findData ? true : false
  })
  console.log(list)
  return list
}
// 菜单转为一维数组
const oneList = (router = [], list, lists = []) => {
  router.forEach(item => {
    const data = list.find(l => l.view === item.key)
    if (data && item.path) {
      lists.push(item)
    } else if (item.children?.length) {
      oneList(item.children, list, lists)
    }
  })
  return lists
}
// 获取头部导航
export { defalutList, getMenuList }
