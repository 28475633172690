import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取解析方式列表
export const _getAnalyList = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.question.analyList,
    params: params,
  })
}
// 删除解析方式
export const _removeAnaly = async params => {
  const path = btnApiList.analysisDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.ADVERSRIING.question.analyInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改解析方式
export const _editeAnaly = async params => {
  const path = btnApiList.analysisEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.ADVERSRIING.question.analyInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增解析方式
export const _addAnaly = async params => {
  const path = btnApiList.analysisAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.question.analyInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取解析方式详情
export const _analyDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.question.analyInfo}/${params}`,
  })
}
