import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { accountInfo, addAccount, editeAccount } from 'reduxs/system/account'
import { getOrgList } from 'reduxs/system/org'
import { getPostList } from 'reduxs/system/post'
import { getSubData } from 'utils/Tools'
import styles from './add.module.less'
const { TextArea } = Input
const { Option } = Select
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [orgList, setOrgList] = useState([]) // 组织下拉列表
  const [postList, setPostList] = useState([]) // 岗位下拉列表
  const [codeRequre, setCodeRequre] = useState(true)
  useEffect(() => {
    getDetails()
    getOrg()
    getPost()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取组织列表
  const getOrg = async () => {
    const res = await getOrgList({ paging: false, status: 1 })
    if (res.result === 200) {
      const { data } = res
      setOrgList(data || [])
    }
  }
  // 获取岗位列表
  const getPost = async () => {
    const res = await getPostList({ paging: false, status: 1 })
    if (res.result === 200) {
      const { data } = res
      setPostList(data || [])
    }
  }
  // 获取详情
  const getDetails = async () => {
    if (editeData?.powerInfoId) {
      const { powerInfoId } = editeData
      const res = await accountInfo(powerInfoId)
      if (res.result === 200) {
        const { data } = res
        if (data.sysn) {
          data.sysn = true
        }
        setCodeRequre(data.sysn ? false : true)
        form.setFieldsValue(data)
      }
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  const onFinish = async values => {
    const { powerInfoId } = editeData
    const data = getSubData(values)
    if (powerInfoId) {
      handEdite(data)
      return
    }
    const res = await addAccount(data)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增账号成功~')
      handSuccess()
    }
  }
  // 修改
  const handEdite = async values => {
    const { powerInfoId } = editeData
    values.powerInfoId = powerInfoId
    const res = await editeAccount(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改账号成功~')
      handSuccess()
    }
  }
  // 监听系统编号变化
  const handBox = e => {
    const { checked } = e.target
    setCodeRequre(!checked)
  }
  return (
    <Modal
      width={600}
      title={editeData.powerInfoId ? '编辑员工' : '新增员工'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{
            status: true,
            sysn: false,
          }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="姓名"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入姓名',
                  },
                ]}
              >
                <Input placeholder="请输入姓名" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="工号" key={codeRequre}>
                <Input.Group compact>
                  <Form.Item
                    name="number"
                    required={codeRequre ? true : false}
                    noStyle
                    rules={[
                      {
                        required: codeRequre,
                        message: '请输入编号',
                      },
                    ]}
                  >
                    <Input
                      placeholder="请输入员工工号"
                      style={{
                        width: '50%',
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="sysn"
                    className={styles.boxItem}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={handBox}>系统编号</Checkbox>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="登陆账号"
                name="account"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入登陆账号',
                  },
                ]}
              >
                <Input placeholder="请输入登陆账号" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="手机号"
                name="phone"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入手机号',
                  },
                ]}
              >
                <Input placeholder="请输入手机号" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="性别"
                name="sex"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入性别',
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={1}>男</Radio>
                  <Radio value={2}>女</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="部门" name="orgIds">
                <Select placeholder="请选择" mode="multiple">
                  {orgList.map((item, index) => (
                    <Option value={item.orgId} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="状态"
                name="status"
                required={true}
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Switch defaultChecked />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="岗位" name="postIds">
                <Select placeholder="请选择" mode="multiple">
                  {postList.map((item, index) => (
                    <Option value={item.postId} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="说明" name="descr">
                <TextArea rows={4} placeholder="请输入说明" maxLength={120} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
