import { Button, Popconfirm } from 'antd'
import React, { useEffect, useState } from 'react'
import { getBtnId } from 'utils/Tools'
const ButtonGroup = props => {
  const { btnId, ...restProps } = props
  const [status, setStatus] = useState(false)
  useEffect(() => {
    getStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btnId])
  const getStatus = () => {
    const btnIds = getBtnId(btnId)
    setStatus(btnIds ? true : false)
  }
  return status ? <Button {...restProps} /> : null
}
export default ButtonGroup
// 有提示的button组件
export const TipButton = props => {
  const { btnId, ...restProps } = props
  const [status, setStatus] = useState(false)
  useEffect(() => {
    getStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btnId])
  const getStatus = () => {
    const btnIds = getBtnId(btnId)
    setStatus(btnIds ? true : false)
  }
  return status ? <Popconfirm {...restProps} /> : null
}
