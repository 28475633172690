// servers/system

// Selectors

// Actions

// Action Creators

// init data
import {
  _addPost,
  _editePost,
  _getPostList,
  _postDetails,
  _removePost,
} from 'servers/system/post'
import { changeList } from '../../../Selectors/Common/index'
// 登录
export const getPostList = async (data = {}) => {
  const res = await _getPostList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除岗位
export const removePost = async (data = {}) => {
  const res = await _removePost(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改岗位
export const editePost = async (data = {}) => {
  const res = await _editePost(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增岗位
export const addPost = async (data = {}) => {
  const res = await _addPost(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取岗位详情
export const postDetails = async (data = {}) => {
  const res = await _postDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
