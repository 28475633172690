import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  message,
} from 'antd'
import UploadIcon from 'components/UploadIcon'
import React, { useEffect, useState } from 'react'
import {
  addQuestionAnsw,
  editeQuestionAnsw,
  questionAnswDetails,
} from 'reduxs/advertising/questionAnsw'
import styles from './index.module.less'
const { TextArea } = Input
const AddEditeModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess, state, data } = props
  const { isUpdate, questionAnswId } = editeData
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    if (!isUpdate) {
      return
    }
    const res = await questionAnswDetails(questionAnswId)
    if (res.result === 200) {
      const { data } = res
      setImageUrl(data.icon)
      form.setFieldsValue(data)
    }
  }

  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { questionTitleId } = data
    const { questionInfoId } = state
    values.status = 1
    values.icon = imageUrl
    values.questionTitleId = questionTitleId
    values.questionInfoId = questionInfoId
    if (questionAnswId) {
      editeList(values)
      return
    }
    addList(values)
  }
  // 编辑
  const editeList = async values => {
    values.questionAnswId = questionAnswId
    const res = await editeQuestionAnsw(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改题目成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addQuestionAnsw(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增答案成功~')
      handSuccess()
    }
  }
  // 上传图片成功
  const imgSuccess = str => {
    setImageUrl(str)
  }
  return (
    <Modal
      width={600}
      title={questionAnswId ? '编辑题库' : '新增答案'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{ status: true }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="测评分类">{state?.clasz?.name}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="名称">{state?.title}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="维度">{data.wd?.name}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="基准">{data.jz?.name}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="题目">{data.title}</Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="ICON" name="icon">
                <UploadIcon handSuccess={imgSuccess} imageUrl={imageUrl} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="标题"
                name="title"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="说明" name="descr">
                <TextArea rows={4} placeholder="请输入说明" maxLength={120} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="分数" name="score" required={true}>
                <InputNumber min={0} defaultValue={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="排序" name="seq">
                <InputNumber min={0} defaultValue={0} precision={0} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddEditeModal
