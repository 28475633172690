import { CloseOutlined, CopyOutlined } from '@ant-design/icons'
import { Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { handleUpload } from 'utils/AliOss'
import styles from './index.module.less'
// const s3 = new AWS.S3({
//   accessKeyId: 'AKIAXYKJVQA2TTX4BXD5',
//   secretAccessKey: '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',
//   region: 'ap-southeast-1', // 替换为你的S3区域
// })
const UploadIcon = props => {
  const { fileList, handSuccess, removeIcon } = props
  const [icons, setIcons] = useState([])
  useEffect(() => {
    setIcons(fileList)
  }, [fileList])
  //附件上传
  const uploadProps = {
    showUploadList: false,
    customRequest: async fileObj => {
      const { file } = fileObj
      const res = await handleUpload(file)
      handSuccess(res.url)
    },
  }
  return (
    <div className={styles.contentFlow}>
      {icons.map((item, index) => {
        return (
          <>
            <div className={styles.content}>
              <CopyOutlined />
              <span className={styles.remove} onClick={() => removeIcon(item)}>
                <CloseOutlined />
              </span>
              <p className={styles.name}>{`合同${index + 1}`}</p>
            </div>
          </>
        )
      })}

      <Upload
        {...uploadProps}
        showUploadList={false}
        // beforeUpload={file => uploadFile(file)}
        // customRequest={file => uploadFile(file)}
        action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
        onRemove={() => handSuccess('')}
      >
        <div className={styles.content}></div>
      </Upload>
    </div>
  )
}
export default UploadIcon
