import { ConfigProvider, Empty } from 'antd'
import 'antd/dist/antd.less'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { createRoot } from 'react-dom/client'
import App from './App'
import './index.css'

import zhCN from 'antd/es/locale/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
const container = document.getElementById('root')
const customizeRenderEmpty = () => (
  <div style={{ textAlign: 'center' }}>
    <Empty type="default" />
  </div>
)

const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <ConfigProvider locale={zhCN} renderEmpty={customizeRenderEmpty}>
    <App />
  </ConfigProvider>
)
