// servers/system

// Selectors

// Actions

// Action Creators

// init data
import {
  _accountInfo,
  _addAccount,
  _editeAccount,
  _getPageList,
  _removeAccount,
  _resetPwd,
  _getAccountRole,
  _setAccountRole,
} from 'servers/system/account'
import { changeList } from '../../../Selectors/Common/index'
// 登录
export const getPageList = async (data = {}) => {
  const res = await _getPageList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 新增账号
export const addAccount = async (data = {}) => {
  const res = await _addAccount(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 删除账号
export const removeAccount = async (data = {}) => {
  const res = await _removeAccount(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取账号详情
export const accountInfo = async (data = {}) => {
  const res = await _accountInfo(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 重置密码
export const resetPwd = async (data = {}) => {
  const res = await _resetPwd(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改员工
export const editeAccount = async (data = {}) => {
  const res = await _editeAccount(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 账号关联角色
export const setAccountRole = async (data = {}) => {
  const res = await _setAccountRole(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取账号关联的角色
export const getAccountRole = async (data = {}) => {
  const res = await _getAccountRole(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
