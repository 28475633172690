import Layout from 'layouts/Index'
import React, { Component } from 'react'
import { Route } from 'react-router-dom'
class RouterGuard extends Component {
  render() {
    const { location } = this.props
    const { pathname } = location
    return <Route path={pathname} component={Layout} />
  }
}
export default RouterGuard
