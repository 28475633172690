import {
  _addMenu,
  _editeMenu,
  _getMenuList,
  _menuDetails,
  _removeMenu,
} from 'servers/system/menu'
import { changeList } from '../../../Selectors/Common/tree'
// 获取菜单列表
export const getMenuList = async (data = {}) => {
  const res = await _getMenuList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除菜单
export const removeMenu = async (data = {}) => {
  const res = await _removeMenu(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取菜单详情
export const menuDetails = async (data = {}) => {
  const res = await _menuDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改菜单
export const editeMenu = async (data = {}) => {
  const res = await _editeMenu(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增菜单
export const addMenu = async (data = {}) => {
  const res = await _addMenu(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
