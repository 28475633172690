// import releaseIndex from 'pages/IndexFrom/Release/Containers/Index' // 发版次数明细
import AllocationManage from 'pages/SystemManage/AllocationManage/Containers/Index' // 配置管理
import DicManage from 'pages/SystemManage/DicManage/Containers/Index' // 员工管理
import MenuManage from 'pages/SystemManage/MenuManage/Containers/Index' // 菜单管理
import OrgMange from 'pages/SystemManage/OrgMange/Containers/Index' // 组织管理
import PostManage from 'pages/SystemManage/Postmanage/Containers/Index' // 岗位管理
import RoleManage from 'pages/SystemManage/RoleManage/Containers/Index' // 角色管理
import StaffManage from 'pages/SystemManage/StaffManage/Containers/Index' // 员工管理
export const PAGE_PATH = {
  system: {
    staff: '/system/staff', // 员工管理
    orgMange: '/system/orgMange', // 组织架构
    postManage: '/system/postManage', // 岗位管理
    roleManage: '/system/roleManage', // 角色管理
    menuManage: '/system/menuManage', // 菜单管理
    dicManage: '/system/dicManage', //字典管理
    allocationManage: '/system/allocationManage', //配置管理
  },
}

// 侧边菜单栏白名单
export const whiteList = ['/index']

// 路由
export const OpeRouter = [
  {
    name: '系统管理',
    key: '/system',
    exact: true,
    children: [
      {
        name: '系统管理',
        key: '/system/config',
        exact: true,
        children: [
          {
            name: '员工管理',
            key: '/index/system/staff',
            path: PAGE_PATH.system.staff,
            component: StaffManage,
            exact: true,
            breadcrumb: [{ name: '系统管理' }, { name: '员工管理' }],
          },
          {
            name: '组织架构',
            key: '/index/system/orgMange',
            path: PAGE_PATH.system.orgMange,
            component: OrgMange,
            exact: true,
            breadcrumb: [{ name: '系统管理' }, { name: '组织架构' }],
          },
          {
            name: '岗位管理',
            key: '/index/system/postManage',
            path: PAGE_PATH.system.postManage,
            component: PostManage,
            exact: true,
            breadcrumb: [{ name: '系统管理' }, { name: '岗位管理' }],
          },
          {
            name: '角色管理',
            key: '/index/system/roleManage',
            path: PAGE_PATH.system.roleManage,
            component: RoleManage,
            exact: true,
            breadcrumb: [{ name: '系统管理' }, { name: '角色管理' }],
          },
          {
            name: '菜单管理',
            key: '/index/system/menuManage',
            path: PAGE_PATH.system.menuManage,
            component: MenuManage,
            exact: true,
            breadcrumb: [{ name: '系统管理' }, { name: '菜单管理' }],
          },
        ],
      },
      {
        name: '系统配置',
        key: '/systemManage/index',
        exact: true,
        children: [
          {
            name: '字典管理',
            key: '/system/dicManage',
            path: PAGE_PATH.system.dicManage,
            component: DicManage,
            exact: true,
            breadcrumb: [
              { name: '系统管理' },
              { name: '系统配置' },
              { name: '字典管理' },
            ],
          },
          {
            name: '配置管理',
            key: '/system/allocationManage',
            path: PAGE_PATH.system.allocationManage,
            component: AllocationManage,
            exact: true,
            breadcrumb: [
              { name: '系统管理' },
              { name: '系统配置' },
              { name: '配置管理' },
            ],
          },
        ],
      },
    ],
  },
]

export default OpeRouter
