import Icon, * as icons from '@ant-design/icons'
import React from 'react'
const getMenuItem = (router, list) => {
  router.forEach(item => {
    item.hidden = true
    const findData = list.find(l => l.view === item.key)
    const iconStr = findData?.icon
    if (iconStr) {
      item.icon = <Icon component={icons[findData?.icon]} />
    }
    if (findData) {
      item.hidden = false
      item.icon = findData.icon
    }
    if (item.children?.length) {
      getMenuItem(item.children, list)
    }
  })
  return router
}
export { getMenuItem }
