import { Button, Form, Input, Select, Space, Spin, Table, message } from 'antd'
import ButtonGroup from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getCompanyList, removeCompany } from 'reduxs/operation/company'
import AddModal from '../Compontents/AddModal'
import { columns, statusList } from './_help'
const { Option } = Select
let options = {}
const AhannelType = () => {
  const [editeData, setEditeData] = useState({}) // 编辑一行的数据
  const [loading, setLoading] = useState(false)
  const [pages, setPages] = useState({}) // 页码相关
  const [pageData, setPageData] = useState([]) // 列表
  useEffect(() => {
    options = {
      page: 1,
      limit: 10,
    }
    getList()
  }, [])
  // 获取公司列表
  const getList = async () => {
    setLoading(true)
    const res = await getCompanyList(options)
    setLoading(false)
    if (res.result === 200) {
      const { data, page } = res
      setPageData(data || [])
      if (page) {
        setPages(page || 0)
      }
    }
  }
  // 删除
  const handRemove = async row => {
    const { companyId } = row
    const res = await removeCompany(companyId)
    if (res.result === 200) {
      message.success('删除成功~')
      getList()
    }
  }
  // 编辑
  const handEdite = row => {
    const data = { ...row, _status: true }
    setEditeData(data)
  }
  // 新增
  const handAdd = () => {
    const data = { _status: true }
    setEditeData(data)
  }
  // 事件合集
  const eventData = {
    handEdite,
    handRemove,
  }
  // 监听table变化
  const changePage = page => {
    options.page = page.current
    options.limit = page.pageSize
    getList()
  }
  // 搜索
  const onFinish = values => {
    options = { page: 1, limit: 10, ...values }
    getList()
  }
  // 新增编辑成功
  const handSuccess = () => {
    setEditeData({})
    getList()
  }
  return (
    <div className="pageContainer">
      <Spin tip="加载中" spinning={loading}>
        {editeData._status && (
          <AddModal
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
          />
        )}

        <Form onFinish={onFinish}>
          <Form.Item name="searchKey" className="formItem">
            <Input placeholder="请搜索" style={{ width: '200px' }} />
          </Form.Item>
          <Form.Item name="status" className="formItem">
            <Select placeholder="请选择" style={{ width: '200px' }} allowClear>
              {statusList.map((item, index) => (
                <Option value={item.type} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item className="formItem">
            <Space>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <ButtonGroup onClick={handAdd} btnId={btnApiList.companyAdd}>
                新增
              </ButtonGroup>
            </Space>
          </Form.Item>
        </Form>
        <Table
          dataSource={pageData}
          rowKey={item => item.companyId} //React规范中的array都必须有key
          columns={columns(eventData)}
          size="small"
          onChange={changePage}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            total: pages.count || 0,
            showTotal: total => `共${total}条`,
            defaultPageSize: 10,
            pageSize: options.limit,
            defaultCurrent: 1,
            current: options.page,
            // pageSizeOptions: [10, 30, 50],
            position: ['bottomLeft'],
          }}
        />
      </Spin>
    </div>
  )
}
export default withRouter(AhannelType)
