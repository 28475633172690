import { Button } from 'antd'
import ButtonGroup, { TipButton } from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React from 'react'
const columns = eventData => {
  const { handEdite, handRemove } = eventData
  return [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: '国家',
      dataIndex: 'country',
      key: 'country',
      render: row => {
        return row?.name || ''
      },
    },
    {
      title: '代码',
      dataIndex: 'provinceId',
      key: 'provinceId',
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '排序',
      dataIndex: 'seq',
      key: 'seq',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: status => {
        return status === 1 ? '启用' : '禁用'
      },
    },
    {
      title: '操作',
      key: 'changge',
      fixed: 'right',
      width: 140,
      render: row => {
        return (
          <>
            <>
              <ButtonGroup
                onClick={() => handEdite(row)}
                btnId={btnApiList.editeProvince}
                type="link"
              >
                编辑
              </ButtonGroup>
              <TipButton
                title="你确定要该省吗？"
                onConfirm={() => handRemove(row)}
                okText="确定"
                cancelText="取消"
                btnId={btnApiList.deleteProvince}
              >
                <Button type="link">删除</Button>
              </TipButton>
            </>
          </>
        )
      },
    },
  ]
}
const dataSource = [
  {
    key: '1',
    index: 1,
    id: 1,
  },
  {
    key: '2',
    index: 2,
    id: 2,
  },
]
export { columns, dataSource }
