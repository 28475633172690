import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取邮箱列表
export const _getMailBoxList = async params => {
  return await request({
    method: 'GET',
    url: Api.OPERATION.mailbox.mailboxList,
    params: params,
  })
}
// 删除邮箱
export const _removeMailbox = async params => {
  const path = btnApiList.mailboxDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.OPERATION.mailbox.mailboxInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改邮箱
export const _editeMailBox = async params => {
  const path = btnApiList.mailboxEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.OPERATION.mailbox.mailboxInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增邮箱
export const _addMailbox = async params => {
  const path = btnApiList.mailboxAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.OPERATION.mailbox.mailboxInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取邮箱详情
export const _mailBoxDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.mailbox.mailboxInfo}/${params}`,
  })
}
