import { PlusOutlined } from '@ant-design/icons'
import {
  Button,
  Cascader,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  message,
} from 'antd'
import UploadIcon from 'components/UploadIcon'
import React, { useEffect, useState } from 'react'
import {
  addBenefit,
  benefitDetails,
  editeBenefit,
} from 'reduxs/advertising/benefit'
import { getSubData } from 'utils/Tools'
import ListModule from './ListModule'
import styles from './add.module.less'
const { TextArea } = Input
const { Option } = Select
const AddModal = props => {
  const [form] = Form.useForm()
  const {
    editeData,
    handleCancel,
    handSuccess,
    businessType,
    benefitType,
    // giveList,
  } = props
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [isGive, setIsGive] = useState(false)
  const [giveList, setGiveList] = useState([])
  const [status, setStatus] = useState(false)
  const [detailsLoading, setDetailsLoading] = useState(false)
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const { benefitId } = editeData
    const list = getOneList(businessType)
    if (benefitId) {
      setDetailsLoading(true)
      const res = await benefitDetails(benefitId)
      setDetailsLoading(false)
      if (res.result === 200) {
        const { data } = res
        const { benefitType, obj } = data || {}
        if (benefitType?.benefitType) {
          // benefitType.questionInfoId = benefitType.benefitTypeId
          setIsGive(true)
          setGiveList(obj ? [obj] : [])
        }
        const parentIds = getParentIds(list, data.claszId)
        data.claszId = parentIds
        data.status = data.status === 1 ? true : false
        setImageUrl(data.icon)
        form.setFieldsValue(data)
      }
    }
  }
  // 获取其所有父级
  const getParentIds = (list = [], parent, ids = []) => {
    list.forEach(item => {
      if (item.claszId === parent) {
        ids.unshift(item.claszId)
        if (item.parentId) {
          getParentIds(list, item.parentId, ids)
        }
      }
    })
    return ids
  }
  // 多维数组转一维数据
  const getOneList = (list, lists = []) => {
    list.forEach(item => {
      lists.push(item)
      if (item.children?.length) {
        getOneList(item.children, lists)
      }
    })
    return lists
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const findData = (benefitType || []).find(
      item => item.benefitTypeId === values.benefitTypeId
    )
    // if (findData?.benefitType === 'ZSCPT' && !giveList.length) {
    //   setLoading(false)
    //   return message.error('请选择测评题')
    // }
    if (findData?.benefitType === 'ZSCPT') {
      values.objId = giveList?.length ? giveList[0].adsId : undefined
    }
    const { claszId } = values
    if (claszId?.length) {
      values.claszId = claszId[claszId.length - 1]
    }
    const { benefitId } = editeData
    const data = getSubData(values)
    data.icon = imageUrl
    if (benefitId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 编辑
  const editeList = async values => {
    const { benefitId } = editeData
    values.benefitId = benefitId
    const res = await editeBenefit(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改权益成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addBenefit(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增权益成功~')
      handSuccess()
    }
  }
  // 上传图片成功
  const imgSuccess = str => {
    setImageUrl(str)
  }
  // 监听权限类型选择
  const typeChange = e => {
    const findData = (benefitType || []).find(item => item.benefitTypeId === e)
    if (findData?.benefitType === 'ZSCPT') {
      setIsGive(true)
    } else {
      setIsGive(false)
    }
  }
  const getGiveList = list => {
    return (
      <div className={styles.content}>
        <>
          {list.map(item => (
            <div className={styles.contentItem}>{item.title || item.name}</div>
          ))}
          <div
            className={styles.contentItem}
            style={{ cursor: 'pointer' }}
            onClick={() => setStatus(true)}
          >
            <PlusOutlined />
          </div>
        </>
      </div>
    )
  }
  //
  const handGiveOk = list => {
    setGiveList(list)
    setStatus(false)
  }
  return (
    <Modal
      width={800}
      title={editeData.benefitId ? '编辑权益' : '新增权益'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        {status && (
          <ListModule
            allGiveList={props.giveList}
            giveList={giveList}
            status={status}
            handleCancel={() => setStatus(false)}
            handSuccess={list => handGiveOk(list)}
          />
        )}
        <Spin tip="加载中" spinning={detailsLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={() => setLoading(false)}
            initialValues={{ status: true }}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="分类"
                  name="claszId"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请选择分类',
                    },
                  ]}
                >
                  <Cascader
                    options={businessType}
                    placeholder="请选择"
                    changeOnSelect={false}
                    style={{ minWidth: '200px' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}></Col>
              <Col span={12}>
                <Form.Item
                  label="权益类型"
                  name="benefitTypeId"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请选择权益类型',
                    },
                  ]}
                >
                  <Select placeholder="请选择" onChange={typeChange}>
                    {benefitType.map((item, index) => (
                      <Option value={item.benefitTypeId} key={index}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="ICON" name="icon">
                  <UploadIcon handSuccess={imgSuccess} imageUrl={imageUrl} />
                </Form.Item>
              </Col>
              {isGive && (
                <>
                  <Col span={12}>
                    <Form.Item label="赠送测评题">
                      {giveList.length ? (
                        getGiveList(giveList)
                      ) : (
                        <Button onClick={() => setStatus(true)}>
                          选择测评题
                        </Button>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="赠送次数" name="count">
                      <InputNumber
                        min={0}
                        defaultValue={0}
                        precision={0}
                        placeholder="请输入"
                        style={{ width: '100%' }}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}

              <Col span={12}>
                <Form.Item
                  label="名称"
                  name="name"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请输入名称',
                    },
                  ]}
                >
                  <Input placeholder="请输入名称" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="价值"
                  name="price"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请输入价值',
                    },
                  ]}
                >
                  <Input placeholder="请输入价值" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="排序" name="seq">
                  <InputNumber min={0} defaultValue={0} precision={0} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="状态"
                  name="status"
                  valuePropName="checked"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请选择状态',
                    },
                  ]}
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="说明" name="descr">
                  <TextArea rows={4} placeholder="请输入说明" maxLength={255} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </Modal>
  )
}
export default AddModal
