/**
 * Help是提供特殊模块公用的方法的类
 */

import { isEmpty } from 'lodash/fp'
import React from 'react'
import { Route } from 'react-router-dom'
/**
 * 根据 key 和 value 设置  sessionStorage
 * @param {*} sessionStorageKey key
 * @param {*} sessionStorageValue value
 */
/**
 * 根据 key 和 value 设置  sessionStorage
 * @param {*} sessionStorageKey key
 * @param {*} sessionStorageValue value
 */
export const setSession = (
  sessionStorageKey = '',
  sessionStorageValue = ''
) => {
  const oldValue = sessionStorage.getItem(sessionStorageKey)
  if (isEmpty(oldValue)) {
    sessionStorage.setItem(sessionStorageKey, sessionStorageValue)
  } else {
    sessionStorage.removeItem(sessionStorageKey)
    sessionStorage.setItem(sessionStorageKey, sessionStorageValue)
  }
}
/**
 * 根据 key 获取对应的 sessionStorage 值
 * @param {*} sessionStorageKey key
 */
export const getSession = (sessionStorageKey = '') => {
  const sessionStorageValue = sessionStorage.getItem(sessionStorageKey)
  return sessionStorageValue
}
/**
 * 根据 key 删除对应的 sessionStorage
 * @param {*} sessionStorageKey
 */
export const removeSession = (sessionStorageKey = '') => {
  sessionStorage.removeItem(sessionStorageKey)
}

// 获取全部路由
export const getAllRoutes = (routers = [], allRoutes = []) => {
  routers.forEach(route => {
    if (route.component) {
      allRoutes.push(
        <Route
          key={route.key}
          exact={route.exact}
          path={route.path}
          component={route.component}
        />
      )
    }
    if (route.children) {
      getAllRoutes(route.children, allRoutes)
    }
  })
  return allRoutes
}

export const setLocal = (key = '', value = '') => {
  const oldValue = localStorage.getItem(`interest-${key}`)
  if (isEmpty(oldValue)) {
    localStorage.setItem(`interest-${key}`, value)
  } else {
    localStorage.removeItem(`interest-${key}`)
    localStorage.setItem(`interest-${key}`, value)
  }
}
/**
 * 根据 key 获取对应的 sessionStorage 值
 * @param {*} sessionStorageKey key
 */
export const getLocal = (key = '') => {
  const sessionStorageValue = localStorage.getItem(`interest-${key}`)
  return sessionStorageValue
}
/**
 * 根据 key 删除对应的 sessionStorage
 * @param {*} sessionStorageKey
 */
export const removeLocal = (key = '') => {
  localStorage.removeItem(`interest-${key}`)
}

export const getGuardRouterList = (routes = [], routerList = []) => {
  routes.forEach(route => {
    routerList.push(route)
    if (route.children) {
      getGuardRouterList(route.children, routerList)
    }
  })
  return routerList
}
