import { Button, Form, Input, Select, Space, Spin, Table, message } from 'antd'
import ButtonGroup from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getEnunList, removeEnun } from 'reduxs/system/enun'
import AddModal from '../Compontents/AddModal'
import TypeMadal from '../Compontents/TypeMadal'
import { columns } from './_help'
const { Option } = Select
let options = {}
const StaffManage = () => {
  const [editeData, setEditeData] = useState({}) // 编辑一行的数据
  const [loading, setLoading] = useState(false)
  const [pageData, setPageData] = useState([]) // 列表
  useEffect(() => {
    options = {
      page: 1,
      limit: 10,
    }
    getList()
  }, [])
  // 获取组织列表
  const getList = async () => {
    setLoading(true)
    const res = await getEnunList({ ...options, tree: true })
    setLoading(false)
    if (res.result === 200) {
      const { data } = res
      setPageData(data)
    }
  }
  // 删除
  const handRemove = async row => {
    const { enumId } = row
    const res = await removeEnun(enumId)
    if (res.result === 200) {
      message.success('删除成功~')
      getList()
    }
  }
  // 编辑
  const handEdite = row => {
    if (!row.parentId) {
      const data = { ...row, typeStatus: true }
      setEditeData(data)
      return
    }
    const data = { ...row, _status: true }
    setEditeData(data)
  }
  // 新增
  const handAdd = () => {
    const data = { _status: true }
    setEditeData(data)
  }

  // 新增类型
  const handAddType = () => {
    const data = { typeStatus: true }
    setEditeData(data)
  }
  // 事件合集
  const eventData = {
    handEdite,
    handRemove,
  }
  // 搜索
  const onFinish = values => {
    options = {
      page: 1,
      limit: 10,
      ...values,
    }
    getList()
  }
  // 新增/修改字典成功
  const handSuccess = () => {
    setEditeData({})
    getList()
  }
  return (
    <div className="pageContainer">
      <Spin tip="加载中" spinning={loading}>
        {editeData._status && (
          <AddModal
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
          />
        )}
        {editeData.typeStatus && (
          <TypeMadal
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
          />
        )}
        <Form onFinish={onFinish}>
          <Form.Item name="searchKey" className="formItem">
            <Input placeholder="请搜索" style={{ width: '200px' }} />
          </Form.Item>
          <Form.Item name="status" className="formItem">
            <Select placeholder="请选择" style={{ width: '200px' }} allowClear>
              <Option value={1}>启用</Option>
              <Option value={0}>禁用</Option>
            </Select>
          </Form.Item>
          <Form.Item className="formItem">
            <Space>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <ButtonGroup
                onClick={() => handAddType()}
                btnId={btnApiList.dicManageType}
              >
                类型
              </ButtonGroup>
              <ButtonGroup
                onClick={() => handAdd()}
                btnId={btnApiList.dicManageAdd}
              >
                新增
              </ButtonGroup>
            </Space>
          </Form.Item>
        </Form>
        <Table
          dataSource={pageData}
          rowKey={item => item.enumId} //React规范中的array都必须有key
          columns={columns(eventData)}
          size="small"
          pagination={false}
        />
      </Spin>
    </div>
  )
}
export default withRouter(StaffManage)
