import { Button, Col, Form, message, Modal, Row, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { planCopy } from 'reduxs/advertising/advertisingPlan'
import { getCountryList } from 'reduxs/operation/country'
import styles from './index.module.less'
const { Option } = Select
const LangModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess, state } = props
  const [countryList, setCountryList] = useState([]) // 国家列表
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getLang()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取语言列表
  const getLang = async () => {
    const res = await getCountryList({ status: 1, paging: false })
    if (res.result === 200) {
      setCountryList(res.data || [])
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = async values => {
    setLoading(true)
    const { countryId } = editeData
    const { adsId } = state
    const data = { ...values, oldCountryId: countryId, adsId }
    setLoading(true)
    const res = await planCopy(data)
    setLoading(false)
    if (res.result === 200) {
      message.success('复制方案成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title="复制方案"
      open={editeData.copyStatus}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{ status: true }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="复制国家" required={true}>
                {editeData.country?.name}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="目标国家"
                name="newCountryId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择目标国家',
                  },
                ]}
              >
                <Select
                  placeholder="请选择目标国家"
                  style={{ width: '200px' }}
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {countryList.map((item, index) => (
                    <Option key={index} value={item.countryId}>
                      {`${item.name} (${item.code})`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default LangModal
