import { Button, Col, Form, Input, Modal, Row, Space, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { updatePwd, userInfo } from 'reduxs/login'
import styles from './index.module.less'
const PasswordModal = props => {
  const [form] = Form.useForm()
  const { infoData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState({})
  useEffect(() => {
    getUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取个人信息
  const getUserInfo = async () => {
    const res = await userInfo()
    if (res.result === 200) {
      const { data } = res
      form.setFieldsValue(data)
      setInfo(data)
    }
  }
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = async values => {
    const { password, newPassword } = values
    if (password !== newPassword) {
      message.warning('密码不一致，请重新输入~')
      setLoading(false)
      return
    }
    const res = await updatePwd(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('个人信息修改成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title="个人信息"
      open={infoData?.wordStatus}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinishFailed={() => setLoading(false)}
          onFinish={onFinish}
        >
          <Row>
            <Col span={12}>
              <Form.Item label="登陆账号">{info.account?.account}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="工号">{info.number}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="新密码"
                name="password"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入密码',
                  },
                ]}
              >
                <Input.Password placeholder="请输入密码" />
              </Form.Item>
            </Col>
            <span className={styles.mark}>
              密码长度6-18哥字符，不支持特殊字符
            </span>
            <Col span={12}>
              <Form.Item
                label="确认密码"
                name="newPassword"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入密码',
                  },
                ]}
              >
                <Input.Password placeholder="请输入密码" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default PasswordModal
