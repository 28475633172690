import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取业务公司列表
export const _getContractList = async params => {
  return await request({
    method: 'GET',
    url: Api.OPERATION.company.contractList,
    params: params,
  })
}
// 删除业务公司
export const _removeContract = async params => {
  const path = btnApiList.contractDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.OPERATION.company.contractInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改业务公司
export const _editeContract = async params => {
  const path = btnApiList.contractEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.OPERATION.company.contractInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增业务公司
export const _addContract = async params => {
  const path = btnApiList.contractAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.OPERATION.company.contractInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取业务公司详情
export const _contractDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.company.contractInfo}/${params}`,
  })
}
