// servers/system

// Selectors

// Actions

// Action Creators

// init data
import {
  _addCountry,
  _countryDetails,
  _deleteCountryType,
  _editeCountry,
  _getCountryList,
  _getPaymentType,
  _paymentTypeSeq,
  _removeCountry,
  _setPaymentType,
} from 'servers/operation/country'
import { changeList } from '../../../Selectors/Common/index'
// 获取国家列表
export const getCountryList = async (data = {}) => {
  const res = await _getCountryList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除国家
export const removeCountry = async (data = {}) => {
  const res = await _removeCountry(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改国家
export const editeCountry = async (data = {}) => {
  const res = await _editeCountry(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增国家
export const addCountry = async (data = {}) => {
  const res = await _addCountry(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取国家详情
export const countryDetails = async (data = {}) => {
  const res = await _countryDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 国家绑定支付类类型
export const setPaymentType = async (data = {}) => {
  const res = await _setPaymentType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 国家获取支付类类型
export const getPaymentType = async (data = {}) => {
  const res = await _getPaymentType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改支付类型序号
export const paymentTypeSeq = async (id1, id2, id3, seq) => {
  const res = await _paymentTypeSeq(id1, id2, id3, seq)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 删除支付类型
export const deleteCountryType = async (id1, id2, id3) => {
  const res = await _deleteCountryType(id1, id2, id3)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
