import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取国家列表
export const _getCountryList = async params => {
  return await request({
    method: 'GET',
    url: Api.OPERATION.country.countryList,
    params: params,
  })
}
// 删除国家
export const _removeCountry = async params => {
  const path = btnApiList.countryDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.OPERATION.country.countryInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改国家
export const _editeCountry = async params => {
  const path = btnApiList.countryEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.OPERATION.country.countryInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增国家
export const _addCountry = async params => {
  const path = btnApiList.countryAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.OPERATION.country.countryInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取国家详情
export const _countryDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.country.countryInfo}/${params}`,
  })
}
// 国家绑定支付类类型
export const _setPaymentType = async params => {
  return await request({
    method: 'POST',
    url: Api.OPERATION.country.paymentType,
    data: params,
  })
}
// 国家绑定支付类类型
export const _getPaymentType = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.country.paymentList}/${params}`,
  })
}
// 修改支付类型序号
export const _paymentTypeSeq = async (id1, id2, id3, seq) => {
  return await request({
    method: 'PUT',
    url: `${Api.OPERATION.country.paymentTypeSeq}/${id1}/${id2}/${id3}/${seq}`,
  })
}
// 删除支付类型
export const _deleteCountryType = async (id1, id2, id3) => {
  return await request({
    method: 'DELETE',
    url: `${Api.OPERATION.country.deleteCountryType}/${id1}/${id2}/${id3}`,
  })
}
