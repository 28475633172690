import { Button, Popover, Select, Space } from 'antd'
import React from 'react'
import { getLocal } from './Help'
const { Option } = Select

export const getFullRoute = (url = '', options) => {
  let fullURL = url
  for (const key in options) {
    if (options.hasOwnProperty(key)) {
      fullURL = fullURL.replace(`:${key}`, options[key])
    }
  }
  return fullURL
}

// 获取URL跳转过来的参数
export const getSeachName = pathName => {
  const searchParams = {}
  if (pathName.indexOf('?') !== -1) {
    var str = pathName.substr(1)
    let strs = str.split('&')
    strs.forEach(item => {
      let key = item.split('=')[0]
      let value = item.split('=')[1]
      searchParams[key] = value
    })
  }
  return searchParams
}

/**
 * @param {*} key - 选项中的key值
 * @param {*} feild - 要读取的变量
 * @param {*} option - 选项
 * @param {string} emptyText - 匹配不到时展示
 *
 * @return string - 名称
 *
 * @example:
 *  options = [{key: '1', label: '绿色', color: 'green'}{key: '2', label: '黄色', color: 'yellow'}]
 *  getOption(1, 'color',options)
 *  out -> green
 *  getOption(0, 'color',options, 'no-color')
 *  out -> no-color
 */
export function getOption(key, feild, option, emptyText = '') {
  if (key !== null && key !== undefined) {
    const matched = option.filter(item => item.key === key)
    return matched[0] ? matched[0][feild] : emptyText
  }
  return ''
}

/**
 *
 * @param {*} keysValue - 匹配键值
 * @param {*} keyName - 匹配键名
 * @param {*} valueName - 获取值名
 * @param {*} option  - 选项
 *
 * @return {sting} array - 获取值数组
 *
 * @example:
 *  options = [{name: 'apple', id: '1', color: 'green'},{name: 'banana', id: '2', color: 'yellow'}]
 *  getValuesByKeys('1,2', 'id', 'name', options)
 *  out -> ['apple', 'banana']
 */
export function getValuesByKeys(keysValue, keyName, valueName, option) {
  const keys = keysValue.split(',')
  let lables = []
  for (var i in option) {
    if (keys.includes(option[i][keyName] + '')) {
      lables.push(option[i][valueName])
    }
  }
  return lables
}

/**
 * 过滤对象中的null、undefined、''字段
 * @param {obj} obj 需要被过滤掉的对象
 */
export const removeEmptyField = obj => {
  var newObj = {}
  if (typeof obj == 'string') {
    obj = JSON.parse(obj)
  }
  if (obj instanceof Array) {
    newObj = []
  }
  if (obj instanceof Object) {
    for (var attr in obj) {
      if (
        obj.hasOwnProperty(attr) &&
        obj[attr] !== '' &&
        obj[attr] !== null &&
        obj[attr] !== undefined
      ) {
        if (obj[attr] instanceof Object) {
          newObj[attr] = removeEmptyField(obj[attr])
        } else if (
          typeof obj[attr] == 'string' &&
          ((obj[attr].indexOf('{') > -1 && obj[attr].indexOf('}') > -1) ||
            (obj[attr].indexOf('[') > -1 && obj[attr].indexOf(']') > -1))
        ) {
          try {
            var attrObj = JSON.parse(obj[attr])
            if (attrObj instanceof Object) {
              newObj[attr] = removeEmptyField(attrObj)
            }
          } catch (e) {
            newObj[attr] = obj[attr]
          }
        } else {
          newObj[attr] = obj[attr]
        }
      }
    }
  }
  return newObj
}

// 处理首页的数据
export const getIndexOption = (list, data, value = 'num', key = 'key') => {
  list.forEach(item => {
    item[value] = data[item[key]] || 0
  })
  return list
}
// 判断是否有按钮权限
export const isMenuPermission = key => {
  const permissionList = isLocal('permissionList')
  const data = (permissionList || []).find(item => item === key)
  if (data) {
    return true
  }
  return false
}
// 判断本地储存是否有，有返回，没有返回空数组
export const isLocal = str => {
  const data = getLocal(str) ? JSON.parse(getLocal(str)) : undefined
  return data
}
// 路由转换为一维数组
export const oneRouer = (router, allrouter = []) => {
  router.forEach(item => {
    allrouter.push(item)
    if (item.children?.length) {
      oneRouer(item.children, allrouter)
    }
  })
  return allrouter
}
// 获取路由最顶部的值
export const getParentKey = (list, key, lists = []) => {
  list.forEach(item => {
    if (item.key === key) {
      if (item.parentKey) {
        getParentKey(list, item.parentKey, lists)
      } else {
        lists.push(item.key)
      }
    }
  })
  return lists
}

// 下拉数组组装
export const getOptions = (
  list = [],
  value = 'value',
  name = 'name',
  isCode = false
) => {
  return list.map(item => (
    <Option value={item[value]} key={item[value]}>
      {isCode ? `${item[name]}/${item.code || '无'}` : item[name]}
    </Option>
  ))
}
// 获取首页相关柱状图对象中的值
export const getObjvalue = (data, key = 'sum') => {
  const list = []
  data.forEach(item => {
    list.unshift(item[key])
  })
  return list
}
//解析下载Blob数据文件
export function downloadFile(response) {
  if (response.status !== 200) {
    return false
  }
  const headers = response.headers
  const contentType = headers['content-type']
  //const contentType = 'application/vnd.ms-excel;charset=utf-8'
  const contentDisposition = headers['content-disposition']
  const blob = new Blob([response.data], { type: contentType })
  //下载后文件名
  let fileName = contentDisposition
    ? decodeURI(
        response.headers['content-disposition'].split(';')[1].split('=')[1]
      )
    : new Date().getTime() + '.xlsx'
  console.log(fileName)
  var downloadElement = document.createElement('a')
  var href = window.URL.createObjectURL(blob) //创建下载的链接
  downloadElement.href = href
  downloadElement.download = fileName
  document.body.appendChild(downloadElement)
  downloadElement.click() //点击下载
  document.body.removeChild(downloadElement) //下载完成移除元素
  window.URL.revokeObjectURL(href) //释放掉blob对象
}
// 处理操作中的button
export const changeButton = (list, width, fullScreenRef) => {
  let status = 0 // 是否至少有一个权限
  list.forEach(item => {
    if (item.rol) {
      status = 1
    }
  })
  if (status === 1) {
    return {
      title: '操作',
      key: 'change',
      fixed: 'right',
      width: width || 260,
      render: row => <Space>{getList(list, row, fullScreenRef)}</Space>,
    }
  }
}

const getList = (list, row, fullScreenRef) => {
  let num = 0 // 判断有多少个按钮
  let showList = [] // 直接显示的按钮数组
  let hideList = [] // 需要鼠标移入才显示的按钮数组
  list.forEach(item => {
    if (item.status(row)) {
      num = num + 1
      if (num <= 2) {
        // 前2个button直接显示列表中
        showList.push(
          <Button
            style={item.style}
            type={item.type || 'default'}
            key={item.name}
            onClick={() => item.onClick(row)}
          >
            {item.name}
          </Button>
        )
      } else if (num === 3) {
        showList.push(
          <Button
            style={item.style}
            type={item.type || 'default'}
            key={item.name}
            onClick={() => item.onClick(row)}
          >
            {item.name}
          </Button>
        )
        hideList.push(
          <p key={item.name} style={{ margin: 0 }}>
            <Button
              style={item.style}
              type={item.type || 'text'}
              onClick={() => item.onClick(row)}
            >
              {item.name}
            </Button>
          </p>
        )
      } else {
        // 放入Popover的button
        hideList.push(
          <p key={item.name} style={{ margin: 0 }}>
            <Button
              style={item.style}
              type={item.type || 'text'}
              onClick={() => item.onClick(row)}
            >
              {item.name}
            </Button>
          </p>
        )
      }
    }
  })
  hideList.length <= 1 ? hideList.pop() : showList.pop()

  if (hideList.length > 0) {
    const content = <div>{hideList}</div>
    showList.push(
      <Popover
        getPopupContainer={() =>
          fullScreenRef ? fullScreenRef.current : document.body
        }
        content={content}
        placement="top"
        key="Popover"
      >
        <Button>· · ·</Button>
      </Popover>
    )
  }
  return showList
}
// 所有没有的展示无数据
export const getcolumns = list => {
  list.forEach(item => {
    if (!item.status) {
      item.render = text => {
        if (
          text === '' ||
          typeof text === 'undefined' ||
          // eslint-disable-next-line valid-typeof
          text === null
        ) {
          return '暂无数据'
        }
        return text
      }
    }
  })
  return list
}

//数字三位逗号隔开
export const formatNumber = nStr => {
  if (nStr === null || nStr === undefined) {
    return '暂无数据'
  }
  nStr += ''
  let x = nStr.split('.')
  let x1 = x[0]
  let x2 = x.length > 1 ? '.' + x[1] : ''
  var rgx = /(\d+)(\d{3})/
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, '$1' + ',' + '$2')
  }
  return x1 + x2
}
/**
 * 展开/全屏
 * @param {element} element 对应节点
 */
export const requestFullScreen = id => {
  const element = document.getElementById(id)
  var requestMethod =
    element.requestFullscreen ||
    element.webkitRequestFullscreen ||
    element.msRequestFullscreen ||
    element.mozRequestFullScreen
  if (requestMethod) {
    requestMethod.call(element)
  }
}

// 判断是否全屏
export function isFullscreenElement() {
  var isFull =
    document.fullscreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement ||
    document.mozFullScreenElement
  return !!isFull
}
// 退出/全屏
export function exitFullScreen() {
  var exitMethod =
    document.exitFullscreen ||
    document.webkitExitFullscreen ||
    document.msExitFullscreen ||
    document.mozCancelFullScreen
  if (exitMethod) {
    exitMethod.call(document)
  }
}
// 处理科学数字
export const getScienceNum = num => {
  let billion = 100000000
  let thousand = 10000
  let str = 0
  let unit = '万'
  if (num >= billion) {
    str = (num / billion).toFixed(2)
    unit = '亿'
  } else if (num >= thousand) {
    str = (num / thousand).toFixed(2)
    unit = '万'
  } else {
    return formatNumber(num)
  }
  return (
    <>
      {formatNumber(str)}
      <span style={{ fontSize: '0.8rem' }}>{unit}</span>
    </>
  )
}
// 处理编辑新增中的状态
export const getSubData = values => {
  const { sysn, status } = values
  if (sysn) {
    values.sysn = 1
  } else {
    values.sysn = 0
  }
  if (status) {
    values.status = 1
  } else {
    values.status = 0
  }
  return values
}
// js将数字转换为中文数字
export const convertToChinaNum = num => {
  // eslint-disable-next-line no-array-constructor
  var arr1 = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']

  // eslint-disable-next-line no-array-constructor
  var arr2 = [
    '',
    '十',
    '百',
    '千',
    '万',
    '十',
    '百',
    '千',
    '亿',
    '十',
    '百',
    '千',
    '万',
    '十',
    '百',
    '千',
    '亿',
  ]
  //可继续追加更高位转换值

  if (!num || isNaN(num)) {
    return '零'
  }

  var english = num.toString().split('')

  var result = ''

  for (var i = 0; i < english.length; i++) {
    var des_i = english.length - 1 - i //倒序排列设值

    result = arr2[i] + result

    var arr1_index = english[des_i]

    result = arr1[arr1_index] + result
  }

  //将【零千、零百】换成【零】 【十零】换成【十】

  result = result.replace(/零(千|百|十)/g, '零').replace(/十零/g, '十')

  //合并中间多个零为一个零

  result = result.replace(/零+/g, '零')

  //将【零亿】换成【亿】【零万】换成【万】

  result = result.replace(/零亿/g, '亿').replace(/零万/g, '万')

  //将【亿万】换成【亿】

  result = result.replace(/亿万/g, '亿')

  //移除末尾的零

  result = result.replace(/零+$/, '')

  //将【零一十】换成【零十】

  //result = result.replace(/零一十/g, '零十');

  //貌似正规读法是零一十

  //将【一十】换成【十】

  result = result.replace(/^一十/g, '十')

  return result
}

// 登陆之后跳转的第一个有权限的页面
export const getOnePath = (router, menuPromise) => {
  const oneRouter = getOnerouter(router)
  const pathList = getPath(oneRouter, menuPromise)
  return pathList
}
// 获取跳转的第一个页面路径
const getPath = (oneRouter, promistList) => {
  const data = oneRouter.find(item => {
    const findData = promistList.find(
      p => p.view === item.key && p.type === 10 && item.path
    )
    return findData ? true : false
  })
  return data ? data.path : ''
}
// 多维数组转为一维数组
const getOnerouter = (list, lists = []) => {
  list.forEach(item => {
    lists.push(item)
    if (item.children?.length) {
      getOnerouter(item.children, lists)
    }
  })
  return lists
}
// 根据view获取btnId
export const getBtnId = path => {
  const menus = getLocal('btns') ? JSON.parse(getLocal('btns')) : []
  const findData = menus.find(item => item.view === path && item.view)
  return findData ? findData.menuId : ''
}
