import {
  _payTypeDetails,
  _addPayType,
  _editePayType,
  _getPayTypeList,
  _removePayType,
} from 'servers/operation/payType'
import { changeList } from '../../../Selectors/Common/index'
// 获取支付类型列表
export const getPayTypeList = async (data = {}) => {
  const res = await _getPayTypeList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除支付类型
export const removePayType = async (data = {}) => {
  const res = await _removePayType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改支付类型
export const editePayType = async (data = {}) => {
  const res = await _editePayType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增支付类型
export const addPayType = async (data = {}) => {
  const res = await _addPayType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取支付类型详情
export const payTypeDetails = async (data = {}) => {
  const res = await _payTypeDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
