/* eslint-disable array-callback-return */
import { Checkbox } from 'antd'
import styles from 'components/Checkbox/index.module.less'
import React, { useEffect, useState } from 'react'
import { convertToChinaNum } from 'utils/Tools'
const Index = props => {
  const { updateTatus, height } = props
  // 后台返回的所有权限列表
  const [permissionList, setPermissionList] = useState([])
  const [arrayIndex, setArrayIndex] = useState(0)
  const [width, setWidth] = useState(0)
  useEffect(() => {
    console.log(props.permissionList)
    setPermissionList(props.permissionList)
    setArrayIndex(props.ArrayIndex)
    getWidth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取当前组件的宽度
  const getWidth = () => {
    const id = document.getElementById('box')
    const width = id.offsetWidth
    if (width > props.ArrayIndex * 300) {
      setWidth(width)
    } else {
      setWidth(props.ArrayIndex * 300)
    }
  }
  // 生成标题
  const getTitle = () => {
    const titleList = []
    for (let i = 0; i < arrayIndex; i++) {
      titleList.push(
        <span
          style={{
            width: `${100 / arrayIndex}%`,
            border: i === arrayIndex - 1 ? 'none' : '',
          }}
        >{`${convertToChinaNum(i + 1)}级菜单`}</span>
      )
    }
    return titleList
  }
  const checkChildrenChecked = (list, checked) => {
    list.forEach(item => {
      if (!item.disabled) {
        item['checked'] = checked
      }
      // 只勾选页面，不勾选按钮
      item['children'] && checkChildrenChecked(item['children'], checked)
    })
  }
  // 权限勾选
  const checkboxChoose = (checkIndex, checked) => {
    // 权限列表深拷贝
    let newPermissionList = JSON.parse(JSON.stringify(permissionList))

    // 下标数组，后面将每一项对应成选中数据
    let checkIndexList = ('' + checkIndex).split('-')

    let curData = null // 用来存储点击项
    let curList = newPermissionList

    // 选中下标替换成对应的选中数据
    checkIndexList.forEach((index, i) => {
      checkIndexList[i] = curList[index]
      curData = curList[index]
      curList = curData['children']
    })

    // 修改当前项选中状态
    curData['checked'] = checked

    // 递归修改所有子级的选中状态
    if (curData['children']) {
      checkChildrenChecked(curData['children'], checked)
    }

    // 反向循环。根据子级的选中状态修改自身的状态
    for (let i = checkIndexList.length - 2; i >= 0; i--) {
      let flag = false
      checkIndexList[i]['children'].find(t => {
        if (t['checked'] === true) {
          flag = true
        }
        if (t.type === 0) {
          flag = true
        }
        return flag
      })
      checkIndexList[i]['checked'] = flag
    }
    setPermissionList(newPermissionList)
    props.getCheckList(newPermissionList)
  }
  // 递归生成组织选择列表
  const generatePermissionChooseTable = (
    list = permissionList,
    index = 1,
    checkIndex = '',
    count = props.ArrayIndex
  ) => {
    const clist = []
    let num = 0
    let cnum = 0
    let hNum = 0
    list.forEach((item, i) => {
      if (!item.hidden) {
        num = num + 1
      }
      if (!item.children?.length) {
        if (!item.hidden) {
          hNum = hNum + 1
        }
        clist.push(
          <>
            <Checkbox
              onChange={() =>
                checkboxChoose(
                  checkIndex !== '' ? checkIndex + '-' + i : i,
                  !item.checked
                )
              }
              checked={item && item.checked}
              disabled={item.disabled}
              className={updateTatus === true ? styles.notList : styles.none}
            >
              {item && item.name}
            </Checkbox>
            <span
              className={
                updateTatus === false && item.hidden === false
                  ? styles.notName
                  : styles.none
              }
            >
              {item && item.name}
            </span>
          </>
        )
      } else {
        cnum = cnum + 1
      }
    })
    return (
      <div className={`${styles.nodefault} `}>
        <div
          className={`${index === 1 && hNum > 0 ? styles.borB : ''} ${
            index !== 1 && index !== arrayIndex ? styles.borR : ''
          }`}
          style={{
            display: 'flex',
            width: `${index === 1 ? '100%' : `${(1 / count) * 100}%`}`,
          }}
        >
          <div
            key="de"
            style={{ width: `${index === 1 ? (1 / count) * 100 : '100'}%` }}
            className={`
              ${clist.length === 0 ? styles.none : ''}
              ${index === 1 ? styles.borR : ''}
          `}
          >
            {clist}
          </div>
          {clist.length > 0 && getnotList(index, arrayIndex)}
        </div>
        {list.map((item, i) => {
          let data = item
          if (item.children) {
            return (
              <div
                key={data && data.menuCode}
                style={{
                  display: item.hidden === true ? 'none' : 'flex',
                }}
                className={`${styles.permission1} ${styles.borB} ${
                  index !== 1 && item.children ? styles.borT : ''
                }`}
              >
                <div
                  style={{
                    width: `${(1 / count) * 100}%`,
                    display: item.hidden === true ? 'none' : 'block',
                  }}
                  className={`${styles.borR}`}
                >
                  <Checkbox
                    onChange={() =>
                      checkboxChoose(
                        checkIndex !== '' ? checkIndex + '-' + i : i,
                        !data.checked
                      )
                    }
                    checked={data && data.checked}
                    disabled={item.disabled}
                    className={updateTatus === true ? '' : styles.none}
                  >
                    {data && data.name}
                  </Checkbox>
                  <span
                    className={
                      updateTatus === false ? styles.notName : styles.none
                    }
                  >
                    {item && item.name}
                  </span>
                </div>
                <div className={styles.clist}>
                  {item.children &&
                    generatePermissionChooseTable(
                      item.children,
                      index + 1,
                      checkIndex !== '' ? checkIndex + '-' + i : i,
                      count - 1
                    )}
                </div>
              </div>
            )
          }
        })}
      </div>
    )
  }
  // 补全没有children
  const getnotList = (index, num) => {
    const list = []
    for (let i = index; i < num - 1; i++) {
      list.push(
        <div style={{ width: `${100 / num}%` }} className={styles.borR}></div>
      )
    }
    return list
  }
  return (
    <div
      className={styles.permissionContent}
      style={{ height: arrayIndex > 0 ? `${height ? height : 500}px` : '0px' }}
      id="box"
    >
      <div style={{ flex: 1, overflow: 'auto', width: `${width}px` }}>
        {arrayIndex > 0 && (
          <div className={styles.tableHeader} style={{ marginBottom: '0' }}>
            {getTitle()}
          </div>
        )}
        <div>{generatePermissionChooseTable()}</div>
      </div>
    </div>
  )
}
export default Index
