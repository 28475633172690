import { Button } from 'antd'
import React from 'react'
const statusList = [
  {
    type: 0,
    name: '待生效',
  },
  {
    type: 5,
    name: '生效中',
  },
  {
    type: 10,
    name: '已过期',
  },
  {
    type: 15,
    name: '已作废',
  },
]
const columns = eventData => {
  const { handEdite, handRemove } = eventData
  return [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 80,
    },
    {
      title: '公司',
      dataIndex: 'company',
      key: 'company',
      ellipsis: true,
      render: row => {
        return row?.name || ''
      },
    },
    {
      title: '渠道商',
      dataIndex: 'channelMch',
      key: 'channelMch',
      ellipsis: true,
      render: row => {
        return row?.name || ''
      },
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: '编号',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
    },
    {
      title: '合同',
      dataIndex: 'address',
      key: 'address',
      ellipsis: true,
    },
    {
      title: '有效期',
      // dataIndex: 'stime',
      key: 'stime',
      ellipsis: true,
      render: row => {
        return `${row.stime}-${row.etime}`
      },
    },
    {
      title: '时间',
      dataIndex: 'ctime',
      key: 'ctime',
      ellipsis: true,
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      render: status => {
        const findData = statusList.find(item => item.type === status)
        return findData?.name || status
      },
    },
    {
      title: '操作',
      key: 'change',
      fixed: 'right',
      width: 140,
      render: row => {
        return (
          <>
            <Button type="link" onClick={() => handEdite(row)}>
              编辑
            </Button>
            <Button type="link" onClick={() => handRemove(row)}>
              删除
            </Button>
          </>
        )
      },
    },
  ]
}
export { columns, statusList }
