import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取题目列表
export const _getQuestionTitleList = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.question.questionTitleList,
    params: params,
  })
}
// 删除题目
export const _removeQuestionTitle = async params => {
  const path = btnApiList.answerDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.ADVERSRIING.question.questionTitleInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改题目
export const _editeQuestionTitle = async params => {
  const path = btnApiList.answerEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.ADVERSRIING.question.questionTitleInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增题目
export const _addQuestionTitle = async params => {
  const path = btnApiList.answerAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.question.questionTitleInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取题目详情
export const _questionTitleDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.question.questionTitleInfo}/${params}`,
  })
}
// 获取多语言
export const _questionTitleContent = async (id, lang) => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.question.questionTitleContent}/${id}/${lang}`,
  })
}
// 编辑内容
export const _editeTitleContent = async data => {
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.question.questionTitleContent,
    data: data,
  })
}
