import Api from 'apis/Api.js'
import request from 'utils/Request'

// 获取角色列表
export const _getRoleList = async params => {
  return await request({
    method: 'GET',
    url: Api.SYSTEM.role.roleList,
    params: params,
  })
}
// 删除角色
export const _removeRole = async params => {
  return await request({
    method: 'DELETE',
    url: `${Api.SYSTEM.role.roleInfo}${params}`,
  })
}
// 修改角色
export const _editeRole = async params => {
  return await request({
    method: 'PUT',
    url: Api.SYSTEM.role.roleInfo,
    data: params,
  })
}
// 新增角色
export const _addRole = async params => {
  return await request({
    method: 'POST',
    url: Api.SYSTEM.role.roleInfo,
    data: params,
  })
}
// 获取角色详情
export const _roleDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.SYSTEM.role.roleInfo}${params}`,
  })
}
// 角色关联菜单
export const _setRoleMenu = async params => {
  return await request({
    method: 'POST',
    url: Api.SYSTEM.role.setRoleMenu,
    data: params,
  })
}
// 获取角色关联菜单
export const _getRoleMenu = async params => {
  return await request({
    method: 'GET',
    url: Api.SYSTEM.role.setRoleMenu,
    params: params,
  })
}
