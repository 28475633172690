import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取邮件类型列表
export const _getMailboxTypeList = async params => {
  return await request({
    method: 'GET',
    url: Api.OPERATION.mailboxType.mailboxTypeList,
    params: params,
  })
}
// 删除邮件类型
export const _removeMailboxType = async params => {
  const path = btnApiList.mailboxTypeDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.OPERATION.mailboxType.mailboxTypeInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改邮件类型
export const _editeMailboxType = async params => {
  const path = btnApiList.mailboxTypeEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.OPERATION.mailboxType.mailboxTypeInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增邮件类型
export const _addMailboxType = async params => {
  const path = btnApiList.mailboxTypeAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.OPERATION.mailboxType.mailboxTypeInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取邮件类型详情
export const _mailboxTypeDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.mailboxType.mailboxTypeInfo}/${params}`,
  })
}
