// 引入常量
import { PAGE_PATH, Router as routerList } from 'apis/Router'
import 'constants/static'
import Mock from 'mock/index'
import React from 'react'
import { Provider } from 'react-redux'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import promiseMiddleware from 'redux-promise'
import thunk from 'redux-thunk'
import Login from './Pages/Login/Containers/Index'
// 引入 reducer
import RouterGuard from 'components/RouterGuard/index'
import RootReducer from 'reduxRoot/rootReducer'
import { getGuardRouterList } from 'utils/Help'
import './App.less'

const RouterGuardData = getGuardRouterList(routerList)

// 引入mock模块
if (process.env.REACT_APP_ENV === 'mock') {
  Mock.start() // 并且执行初始化函数
}
// 引入mock模块end
const store = createStore(
  RootReducer,
  composeWithDevTools(applyMiddleware(thunk, promiseMiddleware))
)

const App = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <Router>
          <Switch>
            <Route path={PAGE_PATH.login} component={Login}></Route>
            <RouterGuard routerConfig={RouterGuardData}></RouterGuard>
          </Switch>
        </Router>
      </Provider>
    </div>
  )
}

export default App
