export const changeList = res => {
  if (res.result !== 200) {
    return res
  }
  const { data, page } = res
  if (!data) {
    return res
  }
  data.forEach((item, index) => {
    item.index = page?.limit * (page?.page - 1) + index + 1
  })
  return res
}
