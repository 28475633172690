import { Button, Image } from 'antd'
import ButtonGroup, { TipButton } from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React from 'react'
const columns = eventData => {
  const { handEdite, handRemove } = eventData
  return [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 70,
    },
    {
      title: '商户类型',
      dataIndex: 'paymentMchType',
      key: 'paymentMchType',
      render: row => row?.name || '',
    },
    {
      title: 'ICON',
      dataIndex: 'icon',
      key: 'icon',
      render: text => {
        return text ? <Image src={text} width={24} /> : text
      },
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: '代码',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
    },
    {
      title: '排序',
      dataIndex: 'seq',
      key: 'seq',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 70,
      render: status => {
        return status === 1 ? '启用' : '禁用'
      },
    },
    {
      title: '操作',
      key: 'change',
      fixed: 'right',
      width: 140,
      render: row => {
        return (
          <>
            <ButtonGroup
              onClick={() => handEdite(row)}
              btnId={btnApiList.payTypeEdite}
              type="link"
            >
              编辑
            </ButtonGroup>
            <TipButton
              title="你确定要该支付类型吗？"
              onConfirm={() => handRemove(row)}
              okText="确定"
              cancelText="取消"
              btnId={btnApiList.payTypeDelete}
            >
              <Button type="link">删除</Button>
            </TipButton>
          </>
        )
      },
    },
  ]
}
const dataSource = [
  {
    key: '1',
    index: 1,
    id: 1,
  },
  {
    key: '2',
    index: 2,
    id: 2,
  },
]
export { columns, dataSource }
