import {
  _addPayment,
  _adsPaymentListStatus,
  _editePayment,
  _getPaymentList,
  _paymentDetails,
  _removePayment,
} from 'servers/operation/payment'
import { changeList } from '../../../Selectors/Common/index'
// 获取支付商户列表
export const getPaymentList = async (data = {}) => {
  const res = await _getPaymentList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除支付商户
export const removePayment = async (data = {}) => {
  const res = await _removePayment(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改支付商户
export const editePayment = async (data = {}) => {
  const res = await _editePayment(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增支付商户
export const addPayment = async (data = {}) => {
  const res = await _addPayment(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取支付商户详情
export const paymentDetails = async (data = {}) => {
  const res = await _paymentDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 是否默认收款
export const adsPaymentListStatus = async id => {
  const res = await _adsPaymentListStatus(id)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
