// servers/system

// Selectors

// Actions

// Action Creators

// init data
import {
  _addRole,
  _editeRole,
  _getRoleList,
  _getRoleMenu,
  _removeRole,
  _roleDetails,
  _setRoleMenu,
} from 'servers/system/role'
import { changeList } from '../../../Selectors/Common/index'
// 角色列表
export const getRoleList = async (data = {}) => {
  const res = await _getRoleList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除角色
export const removeRole = async (data = {}) => {
  const res = await _removeRole(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改角色
export const editeRole = async (data = {}) => {
  const res = await _editeRole(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增角色
export const addRole = async (data = {}) => {
  const res = await _addRole(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取角色详情
export const roleDetails = async (data = {}) => {
  const res = await _roleDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 角色关联菜单
export const setRoleMenu = async (data = {}) => {
  const res = await _setRoleMenu(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
//
export const getRoleMenu = async (data = {}) => {
  const res = await _getRoleMenu(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
