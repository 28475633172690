import { Button, Col, Form, Modal, Row, Select, Space, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { adsEmail } from 'reduxs/advertising/advertising'
import { getMailBoxList } from 'reduxs/operation/mailBox'
import styles from './index.module.less'
const { Option } = Select
const MailManage = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [emailList, setEmailList] = useState([])
  useEffect(() => {
    getDetails()
    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getDetails = () => {
    const { email, emailTitle } = editeData
    form.setFieldsValue({ email: email, emailTitle: emailTitle })
  }
  // 获取邮箱列表
  const getList = async () => {
    const res = await getMailBoxList({ status: 1, paging: false })
    if (res.result === 200) {
      const { data } = res
      setEmailList(data || [])
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = async values => {
    const { adsId } = editeData
    values.adsId = adsId
    const res = await adsEmail(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改邮箱成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title="邮箱设置"
      open={editeData.type ? true : false}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{ status: true }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="名称">{editeData.title}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="代码">{editeData.code}</Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="邮箱"
                name="email"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入邮箱',
                  },
                ]}
              >
                <Select placeholder="请输入邮箱">
                  {emailList.map((item, index) => (
                    <Option value={item.email} key={index}>
                      {item.email}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/* <Col span={24}>
              <Form.Item
                label="邮件标题"
                name="emailTitle"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入邮件标题',
                  },
                ]}
              >
                <Input placeholder="请输入邮件标题" />
              </Form.Item>
            </Col> */}
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default MailManage
