import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { getCompanyList } from 'reduxs/operation/company'
import {
  addDomainInfo,
  domainInfoDetails,
  editeInfoDomain,
} from 'reduxs/operation/domain'
import { getSubData } from 'utils/Tools'
import styles from './add.module.less'
const { TextArea } = Input
const { Option } = Select
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [companyList, setCompanyList] = useState([])
  useEffect(() => {
    getCompany()
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const { domainInfoId } = editeData
    if (domainInfoId) {
      const res = await domainInfoDetails(domainInfoId)
      if (res.result === 200) {
        const { data } = res
        data.status = data.status === 1 ? true : false
        form.setFieldsValue(data)
      }
    }
  }
  // 获取公司列表
  const getCompany = async () => {
    setLoading(true)
    const res = await getCompanyList({ status: 5, paging: false })
    setLoading(false)
    if (res.result === 200) {
      const { data } = res
      setCompanyList(data || [])
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { domainInfoId } = editeData
    const data = getSubData(values)
    data.name = data.domain
    data.sysn = undefined
    if (domainInfoId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 编辑
  const editeList = async values => {
    const { domainInfoId } = editeData
    values.domainInfoId = domainInfoId
    const res = await editeInfoDomain(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改域名成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addDomainInfo(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增域名成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title={editeData.id ? '编辑域名' : '新增域名'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{ status: true }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="域名"
                name="domain"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入域名',
                  },
                ]}
              >
                <Input placeholder="请输入域名" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="公司"
                name="companyId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择公司',
                  },
                ]}
              >
                <Select placeholder="请选择公司">
                  {companyList.map((item, index) => (
                    <Option value={item.companyId} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="状态"
                name="status"
                required={true}
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="说明" name="descr">
                <TextArea rows={4} placeholder="请输入说明" maxLength={120} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
