import { Button, Form, Input, Select, Space, Spin, Table, message } from 'antd'
import ButtonGroup from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getMenuList, removeMenu } from 'reduxs/system/menu'
import AddModal from '../Compontents/AddModal'
import { columns } from './_help'
import styles from './index.module.less'
const { Option } = Select
let options = {}
const OrgMange = () => {
  const [editeData, setEditeData] = useState({}) // 编辑一行的数据
  const [loading, setLoading] = useState(false)
  const [pageData, setPageData] = useState([]) // 列表
  useEffect(() => {
    options = {}
    getList()
  }, [])
  // 获取列表
  const getList = async () => {
    setLoading(true)
    const res = await getMenuList({ ...options, tree: true, paging: false })
    setLoading(false)
    if (res.result === 200) {
      const { data } = res
      setPageData(data || [])
      // setPages(page)
    }
  }
  // 删除
  const handRemove = async row => {
    const { menuId } = row
    const res = await removeMenu(menuId)
    setLoading(false)
    if (res.result === 200) {
      message.success('删除成功~')
      getList()
    }
  }
  // 编辑
  const handEdite = row => {
    const data = { ...row, _status: true }
    setEditeData(data)
  }
  // 新增
  const handAdd = () => {
    const data = { _status: true }
    setEditeData(data)
  }
  // 事件合集
  const eventData = {
    handEdite,
    handRemove,
  }
  //
  const handSuccess = () => {
    setEditeData({})
    getList()
  }
  // 搜索
  const onFinish = values => {
    options = {
      ...values,
    }
    getList()
  }
  return (
    <div className="pageContainer">
      <Spin tip="加载中" spinning={loading}>
        {editeData._status && (
          <AddModal
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
          />
        )}

        <Form onFinish={onFinish}>
          <Form.Item name="searchKey" className="formItem">
            <Input placeholder="请搜索" style={{ width: '200px' }} />
          </Form.Item>
          <Form.Item name="status" className="formItem">
            <Select placeholder="请选择" style={{ width: '200px' }} allowClear>
              <Option value={1}>启用</Option>
              <Option value={0}>禁用</Option>
            </Select>
          </Form.Item>
          <Form.Item className="formItem">
            <Space>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <ButtonGroup
                onClick={() => handAdd()}
                btnId={btnApiList.menuManageAdd}
              >
                新增
              </ButtonGroup>
            </Space>
          </Form.Item>
        </Form>
        <div className={styles.table}>
          <Table
            dataSource={pageData}
            rowKey={item => item.menuId} //React规范中的array都必须有key
            columns={columns(eventData)}
            size="small"
            pagination={false}
          />
        </div>
      </Spin>
    </div>
  )
}
export default withRouter(OrgMange)
