import {
  _addQuestionTitle,
  _editeQuestionTitle,
  _getQuestionTitleList,
  _questionTitleContent,
  _questionTitleDetails,
  _editeTitleContent,
  _removeQuestionTitle,
} from 'servers/advertising/questionTitle'
import { changeList } from '../../../Selectors/Common/'
// 获取题目列表
export const getQuestionTitleList = async (data = {}) => {
  const res = await _getQuestionTitleList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除题目
export const removeQuestionTitle = async (data = {}) => {
  const res = await _removeQuestionTitle(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改题目
export const editeQuestionTitle = async (data = {}) => {
  const res = await _editeQuestionTitle(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增题目
export const addQuestionTitle = async (data = {}) => {
  const res = await _addQuestionTitle(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取题目详情
export const questionTitleDetails = async (data = {}) => {
  const res = await _questionTitleDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 题目多语言
export const questionTitleContent = async (id, lang) => {
  const res = await _questionTitleContent(id, lang)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
export const editeTitleContent = async data => {
  const res = await _editeTitleContent(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
