import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Tabs,
  message,
} from 'antd'
import UploadIcon from 'components/UploadIcon'
import React, { useEffect, useState } from 'react'
import {
  editeAnswContent,
  questionAnswContent,
} from 'reduxs/advertising/questionAnsw'
import { getLangList } from 'reduxs/operation/lang'
import styles from './index.module.less'
const { TextArea } = Input
const LangModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, state, data } = props
  const [loading, setLoading] = useState(false)
  const [langList, setLangList] = useState([])
  const [activeData, setActiveData] = useState({}) // 当前激活的语言
  const [imageUrl, setImageUrl] = useState('')
  useEffect(() => {
    getLang()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取语言列表
  const getLang = async () => {
    const res = await getLangList({ paging: false, status: 1 })
    if (res.result === 200) {
      const data = res.data || []
      data.forEach(item => {
        item.label = item.name
        item.key = item.languageId
      })
      if (data.length) {
        setActiveData(data[0])
        getContent(data[0])
      }
      setLangList(data)
    }
  }
  // 获取语言详情
  const getContent = async data => {
    form.resetFields()
    const { code } = data
    const { questionAnswId } = editeData
    const res = await questionAnswContent(questionAnswId, code)
    if (res.result === 200) {
      const { data } = res
      setImageUrl(data.icon || '')
      if (data) {
        form.setFieldsValue(data)
      } else {
        form.resetFields()
      }
    }
  }

  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = async values => {
    const { code } = activeData
    const { questionAnswId } = editeData
    const data = {
      ...values,
      lang: code,
      questionAnswId: questionAnswId,
    }
    data.icon = imageUrl
    const res = await editeAnswContent(data)
    setLoading(false)
    if (res.result === 200) {
      message.success('答案多语言编辑成功~')
      // handSuccess()
    }
  }
  // 语言切换
  const langChange = key => {
    const findData = langList.find(item => item.languageId * 1 === key * 1)
    if (findData) {
      setActiveData(findData)
      getContent(findData)
    }
  }
  // 上传图片成功
  const imgSuccess = str => {
    setImageUrl(str)
  }
  return (
    <Modal
      width={600}
      title="题目多语言"
      open={editeData?.langStatus}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{ status: true }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="测评分类">{state?.clasz?.name}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="名称">{state?.title}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="维度" name="questionConditId1">
                {data.wd?.name}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="基准" name="questionConditId2">
                {data.jz?.name}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="题目">{data.title}</Form.Item>
            </Col>
            <Col span={24}>
              <Tabs
                activeKey={activeData?.languageId}
                tabPosition="top"
                items={langList}
                onChange={langChange}
              />
            </Col>
            <Col span={24}>
              <Form.Item label="ICON" name="icon">
                <UploadIcon handSuccess={imgSuccess} imageUrl={imageUrl} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="标题"
                name="title"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入标题',
                  },
                ]}
              >
                <Input placeholder="请输入标题" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="说明" name="descr">
                <TextArea rows={4} placeholder="请输入说明" maxLength={120} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default LangModal
