const contentList = [
  {
    title: '标题',
    name: 'content1',
  },
  {
    title: '内容',
    name: 'content2',
  },
  {
    title: '解析',
    name: 'content3',
  },
  {
    title: '建议',
    name: 'content4',
  },
  {
    title: '含义',
    name: 'content5',
  },
  {
    title: '补充',
    name: 'content6',
  },
  {
    title: '其他',
    name: 'content7',
  },
]
export { contentList }
