import AdvertIndex from 'pages/AdvertManage/AdvertIndex/Containers/Index' // 投放管理
import AdvertiOrderDetails from 'pages/AdvertManage/AdvertiOrder/Containers/Details' // 投放订单详情
import AdvertisingOrder from 'pages/AdvertManage/AdvertiOrder/Containers/Index' //投放订单
import Analysis from 'pages/AdvertManage/Analysis/Containers/Index' //解析方式
import AnswerManage from 'pages/AdvertManage/AnswerManage/Containers/Index' // 答案管理
import Attribution from 'pages/AdvertManage/Attribution/Containers/Index' //归因管理
import BusinessType from 'pages/AdvertManage/BusinessType/Containers/Index' //业务类型
import EquityMange from 'pages/AdvertManage/EquityMange/Containers/Index' // 权益管理
import EquityType from 'pages/AdvertManage/EquityType/Containers/Index' // 权益类型
import PayOrder from 'pages/AdvertManage/PayOrder/Containers/Index' //订单管理
import ProgrammeManage from 'pages/AdvertManage/ProgrammeManage/Containers/Index' // 方案管理
import Question from 'pages/AdvertManage/Question/Containers/Index' //题库管理
import QuestionEmail from 'pages/AdvertManage/QuestionEmail/Containers/Index' //题库管理邮箱配置
import ResultManage from 'pages/AdvertManage/ResultManage/Containers/Index' // 结果管理
import SubjectManage from 'pages/AdvertManage/SubjectManage/Containers/Index' // 题目管理
import OpeRouter from './OpeRouter'
import SystemRouter from './SystemRouter'
// import releaseIndex from 'pages/IndexFrom/Release/Containers/Index' // 发版次数明细

export const PAGE_PATH = {
  login: '/login',
  index: {
    index: '/',
    advertising: '/index/advertising/advertising', // 投放管理
    programme: '/index/advertising/programme', // 方案管理
    payOrder: '/index/advertising/payOrder',
    advertisingOrder: '/index/advertising/advertisingOrder', // 投放订单
    advertiOrderDetails: '/index/advertising/advertiOrderDetails', // 投放订单详情
    attribution: '/index/advertising/attribution', // 归因管理
    question: '/index/advertising/question', // 题库管理
    questionEmail: '/index/advertising/questionEmail', // 题库管理邮箱配置
    subject: '/index/advertising/subject', // 题目管理
    answer: '/index/advertising/answer', // 答案管理
    result: '/index/advertising/result', // 结果管理
    analysis: '/index/advertising/analysis', // 解析方式
    equityMange: '/index/advertising/equityMange', // 权益管理
    equityType: '/index/advertising/equityType', // 权益类型
    businessType: '/index/advertising/businessType', // 业务类型
  },
}

// 侧边菜单栏白名单
export const whiteList = ['/index']

// 路由
export const Router = [
  {
    name: '投放管理',
    key: '/index/advertising',
    exact: true,
    children: [
      {
        name: '投放管理',
        key: '/index/advertising/manage',
        path: PAGE_PATH.index.advertising,
        component: AdvertIndex,
        exact: true,
        breadcrumb: [{ name: '投放管理' }, { name: '投放管理' }],
        children: [
          {
            name: '方案管理',
            key: '/index/advertising/programme',
            path: PAGE_PATH.index.programme,
            component: ProgrammeManage,
            exact: true,
            breadcrumb: [{ name: '投放管理' }, { name: '方案管理' }],
          },
        ],
      },
      {
        name: '订单管理',
        key: '/index/advertising/order',
        exact: true,
        children: [
          {
            name: '投放订单',
            key: '/index/advertising/advertisingOrder',
            path: PAGE_PATH.index.advertisingOrder,
            component: AdvertisingOrder,
            exact: true,
            breadcrumb: [
              { name: '投放管理' },
              { name: '订单管理' },
              { name: '投放订单' },
            ],
          },
          {
            name: '订单详情',
            key: '/index/advertising/advertiOrderDetails',
            path: PAGE_PATH.index.advertiOrderDetails,
            component: AdvertiOrderDetails,
            exact: true,
            breadcrumb: [
              { name: '投放管理' },
              { name: '订单管理' },
              { name: '订单详情' },
            ],
          },
          {
            name: '支付订单',
            key: '/index/advertising/payOrder',
            path: PAGE_PATH.index.payOrder,
            component: PayOrder,
            exact: true,
            breadcrumb: [
              { name: '投放管理' },
              { name: '订单管理' },
              { name: '支付订单' },
            ],
          },
        ],
      },
      {
        name: '归因管理',
        key: '/index/advertising/attribution',
        path: PAGE_PATH.index.attribution,
        component: Attribution,
        exact: true,
        breadcrumb: [{ name: '投放管理' }, { name: '归因管理' }],
      },
      {
        name: '题库管理',
        key: '/index/advertising/question',
        exact: true,
        children: [
          {
            name: '题库管理',
            key: '/index/question/index',
            path: PAGE_PATH.index.question,
            component: Question,
            exact: true,
            breadcrumb: [
              { name: '投放管理' },
              { name: '题库管理' },
              { name: '题库管理' },
            ],
            children: [
              {
                name: '题目管理',
                key: '/index/question/subject',
                path: PAGE_PATH.index.subject,
                component: SubjectManage,
                exact: true,
                hiddren: true,
                breadcrumb: [
                  { name: '投放管理' },
                  { name: '题库管理' },
                  { name: '题目管理' },
                ],
              },
              {
                name: '邮箱配置',
                key: '/index/question/questionEmail',
                path: PAGE_PATH.index.questionEmail,
                component: QuestionEmail,
                exact: true,
                hiddren: true,
                breadcrumb: [
                  { name: '投放管理' },
                  { name: '题库管理' },
                  { name: '邮箱配置' },
                ],
              },
              {
                name: '答案管理',
                key: '/index/question/answer',
                path: PAGE_PATH.index.answer,
                component: AnswerManage,
                exact: true,
                hiddren: true,
                breadcrumb: [
                  { name: '投放管理' },
                  { name: '题库管理' },
                  { name: '题目管理' },
                  { name: '答案管理' },
                ],
              },
              {
                name: '结果管理',
                key: '/index/question/result',
                path: PAGE_PATH.index.result,
                component: ResultManage,
                exact: true,
                hiddren: true,
                breadcrumb: [
                  { name: '投放管理' },
                  { name: '题库管理' },
                  { name: '结果管理' },
                ],
              },
            ],
          },
          {
            name: '解析方式',
            key: '/index/question/analysis',
            path: PAGE_PATH.index.analysis,
            component: Analysis,
            exact: true,
            breadcrumb: [
              { name: '投放管理' },
              { name: '题库管理' },
              { name: '解析方式' },
            ],
          },
        ],
      },
      {
        name: '权益管理',
        key: '/index/advertising/equity',
        exact: true,
        children: [
          {
            name: '权益管理',
            key: '/index/equity/index',
            path: PAGE_PATH.index.equityMange,
            component: EquityMange,
            exact: true,
            breadcrumb: [
              { name: '投放管理' },
              { name: '权益管理' },
              { name: '权益管理' },
            ],
          },
          {
            name: '权益类型',
            key: '/index/equity/type',
            path: PAGE_PATH.index.equityType,
            component: EquityType,
            exact: true,
            breadcrumb: [
              { name: '投放管理' },
              { name: '权益管理' },
              { name: '权益类型' },
            ],
          },
        ],
      },
      {
        name: '业务类型',
        key: '/index/advertising/businessType',
        path: PAGE_PATH.index.businessType,
        component: BusinessType,
        exact: true,
        breadcrumb: [{ name: '投放管理' }, { name: '业务类型' }],
      },
    ],
  },
  ...OpeRouter,
  ...SystemRouter,
]

export default Router
