import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Radio,
  Row,
  Space,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { editeInfo, userInfo } from 'reduxs/login'
import styles from './index.module.less'
const Info = props => {
  const [form] = Form.useForm()
  const { infoData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [info, setInfo] = useState({})
  const [postList, setPostList] = useState([]) // 岗位列表
  const [orgList, setOrgList] = useState([]) // 岗位列表
  useEffect(() => {
    getUserInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取个人信息
  const getUserInfo = async () => {
    const res = await userInfo()
    if (res.result === 200) {
      const { data } = res
      const { orgs, posts } = data
      form.setFieldsValue(data)
      setInfo(data)
      setPostList((posts || []).map(item => item.name))
      setOrgList((orgs || []).map(item => item.name))
    }
  }
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = async values => {
    const res = await editeInfo(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('个人信息修改成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title="个人信息"
      open={infoData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinishFailed={() => setLoading(false)}
          onFinish={onFinish}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="登陆账号">{info.account?.account}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="工号">{info.number}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="部门">{orgList.join('、')}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="岗位">{postList.join('、')}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="姓名"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入姓名',
                  },
                ]}
              >
                <Input placeholder="请输入姓名" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="性别" name="sex">
                <Radio.Group>
                  <Radio value={1}>男</Radio>
                  <Radio value={2}>女</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="手机号"
                name="phone"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入手机号',
                  },
                ]}
              >
                <Input placeholder="请输入手机号" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="邮箱" name="email">
                <Input placeholder="请输入邮箱" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default Info
