import React from 'react'
import styles from './index.module.less'
const payTypeList = [
  {
    type: 0,
    name: '已关闭',
  },
  {
    type: 5,
    name: '待支付',
  },
  {
    type: 10,
    name: '支付中',
  },
  {
    type: 15,
    name: '支付成功',
  },
  {
    type: 20,
    name: '支付失败',
  },
  {
    type: 25,
    name: '退款中',
  },
  {
    type: 30,
    name: '已退款',
  },
]
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 80,
  },
  {
    title: '支付ID',
    dataIndex: 'outTradeNo',
    key: 'outTradeNo',
    ellipsis: true,
    width: 150,
  },
  {
    title: '业务ID',
    dataIndex: 'orderNo',
    key: 'orderNo',
    ellipsis: true,
    width: 150,
  },
  {
    title: '投放名称',
    // dataIndex: 'title',
    key: 'title',
    ellipsis: true,
    render: row => {
      const { title, code } = row
      return `${title || ''} ${code || ''}`
      // return (
      //   <div>
      //     {row.title}
      //     <p>{row.code}</p>
      //   </div>
      // )
    },
  },
  {
    title: '国家',
    dataIndex: 'countryCode',
    key: 'countryCode',
    ellipsis: true,
  },
  {
    title: '渠道',
    dataIndex: 'ct',
    key: 'ct',
    ellipsis: true,
    render: ct => {
      return ct?.name || ''
    },
  },
  {
    title: '渠道账户',
    key: 'accountName',
    ellipsis: true,
    render: row => {
      const { accountName, accountId } = row
      return `${accountName || ''} ${accountId || ''}`
      // return (
      //   <div>
      //     {row.accountName}
      //     <p>{row.accountId}</p>
      //   </div>
      // )
    },
  },
  {
    title: '订单金额',
    key: 'payCurrencySymbol',
    ellipsis: true,
    render: row => {
      return `${row.payCurrencySymbol} ${row.payMoney}`
    },
  },
  {
    title: '支付状态',
    dataIndex: 'payStatus',
    key: 'payStatus',
    width: 100,
    ellipsis: true,
    render: status => {
      const findData = payTypeList.find(item => item.type === status)
      return findData ? (
        <span className={status === 15 ? styles.success : ''}>
          {findData.name}
        </span>
      ) : (
        status
      )
    },
  },
  {
    title: '支付类型',
    dataIndex: 'paymentType',
    key: 'paymentType',
    ellipsis: true,
  },
  {
    title: '支付商户',
    dataIndex: 'mchName',
    key: 'mchName',
    ellipsis: true,
  },
  {
    title: '用户',
    dataIndex: 'userAccount',
    key: 'userAccount',
    ellipsis: true,
  },
  {
    title: '用户IP',
    dataIndex: 'ip',
    key: 'ip',
    ellipsis: true,
  },
  {
    title: '下单时间',
    dataIndex: 'payTime',
    key: 'payTime',
    ellipsis: true,
  },
  {
    title: '支付时间',
    dataIndex: 'paySucTime',
    key: 'paySucTime',
    ellipsis: true,
  },
]
export { columns, payTypeList }
