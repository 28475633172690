import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  message,
  InputNumber,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { addLang, editeLang, langDetails } from 'reduxs/operation/lang'
import { getSubData } from 'utils/Tools'
import styles from './add.module.less'
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const { languageId } = editeData
    if (languageId) {
      const res = await langDetails(languageId)
      if (res.result === 200) {
        const { data } = res
        data.status = data.status === 1 ? true : false
        form.setFieldsValue(data)
      }
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { languageId } = editeData
    const data = getSubData(values)
    data.sysn = undefined
    if (languageId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 编辑
  const editeList = async values => {
    const { languageId } = editeData
    values.languageId = languageId
    const res = await editeLang(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改货币成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addLang(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增货币成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title={editeData.languageId ? '编辑语言' : '新增语言'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{
            status: true,
          }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="代码"
                name="code"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入代码',
                  },
                ]}
              >
                <Input
                  placeholder="请输入代码"
                  disabled={editeData.languageId}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="语言"
                name="lang"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入语言',
                  },
                ]}
              >
                <Input placeholder="请输入语言" />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item label="排序" name="seq">
                <InputNumber min={0} defaultValue={0} precision={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="状态"
                name="status"
                required={true}
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
