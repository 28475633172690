import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Space,
  Switch,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { getChannelBack, setChannelBack } from 'reduxs/operation/channelAcc'
import styles from './add.module.less'
const ContentModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const { channelAccountId } = editeData
    if (channelAccountId) {
      const res = await getChannelBack(channelAccountId)
      if (res.result === 200) {
        const { data } = res
        if (!data) {
          return
        }
        data.js = data.js ? true : false
        data.api = data.api ? true : false
        form.setFieldsValue(data)
      }
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = async values => {
    const { channelAccountId } = editeData
    values.js = values.js ? 1 : 0
    values.api = values.api ? 1 : 0
    values.channelAccountId = channelAccountId
    const res = await setChannelBack(values)
    if (res.result === 200) {
      message.success('配置成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title="广告配置"
      open={editeData?.contnetStatus}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{ api: true, js: true }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="名称">{editeData.name}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="代码">{editeData.accountId}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="渠道">{editeData?.channelType}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="投放方式">{editeData?.putin}</Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="业务域名">{editeData?.domain}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="API归因" name="api" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="JS归因" name="js" valuePropName="checked">
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="API地址" name="apiUrl">
                <Input placeholder="请输入API地址" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="JS地址" name="jsUrl">
                <Input placeholder="请输入API地址" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Head注入" name="headJs">
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Foot注入" name="footJs">
                <Input placeholder="请输入" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="归因地址">
                <Input.Group compact>
                  <Form.Item name="param1" noStyle>
                    <Input
                      placeholder="请输入渠道API归因参数1"
                      style={{ marginBottom: '24px' }}
                    />
                  </Form.Item>
                  <Form.Item name="param2" noStyle>
                    <Input
                      placeholder="请输入渠道API归因参数2"
                      style={{ marginBottom: '24px' }}
                    />
                  </Form.Item>
                  <Form.Item name="param3" noStyle>
                    <Input
                      placeholder="请输入渠道API归因参数3"
                      style={{ marginBottom: '24px' }}
                    />
                  </Form.Item>
                  <Form.Item name="param4" noStyle>
                    <Input
                      placeholder="请输入渠道API归因参数3"
                      style={{ marginBottom: '24px' }}
                    />
                  </Form.Item>
                  <Form.Item name="param5" noStyle>
                    <Input
                      placeholder="请输入渠道API归因参数4"
                      style={{ marginBottom: '24px' }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default ContentModal
