import {
  _addBenefit,
  _benefitContent,
  _benefitDetails,
  _editeBenefit,
  _editeBenefitContent,
  _getBenefitList,
  _removeBenefit,
} from 'servers/advertising/benefit'
import { changeList } from '../../../Selectors/Common'
// 获取权益管理列表
export const getBenefitList = async (data = {}) => {
  const res = await _getBenefitList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除权益管理
export const removeBenefit = async (data = {}) => {
  const res = await _removeBenefit(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改权益管理
export const editeBenefit = async (data = {}) => {
  const res = await _editeBenefit(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增权益管理
export const addBenefit = async (data = {}) => {
  const res = await _addBenefit(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取权益管理详情
export const benefitDetails = async (data = {}) => {
  const res = await _benefitDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取权益内容详情
export const benefitContent = async (id, lang) => {
  const res = await _benefitContent(id, lang)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改权益内容
export const editeBenefitContent = async (data = {}) => {
  const res = await _editeBenefitContent(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
