import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  addChannelAcc,
  channelAccDetails,
  editeChannelAcc,
} from 'reduxs/operation/channelAcc'
import { getSubData } from 'utils/Tools'
import { getOptions } from '../Containers/_help'
import styles from './add.module.less'
const { TextArea } = Input
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, typeData, handSuccess } = props
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const { channelAccountId } = editeData
    if (channelAccountId) {
      const res = await channelAccDetails(channelAccountId)
      if (res.result === 200) {
        const { data } = res
        data.status = data.status === 1 ? true : false
        form.setFieldsValue(data)
      }
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { channelAccountId } = editeData
    const data = getSubData(values)
    data.sysn = undefined
    if (channelAccountId) {
      editeList(values)
      return
    }
    addList(values)
  }
  // 编辑
  const editeList = async values => {
    const { channelAccountId } = editeData
    values.channelAccountId = channelAccountId
    const res = await editeChannelAcc(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改渠道账户成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addChannelAcc(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增渠道账户成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title={editeData.channelAccountId ? '编辑渠道账户' : '新增渠道账户'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{ status: true }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="ID"
                name="accountId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入ID',
                  },
                ]}
              >
                <Input placeholder="请输入ID" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="渠道"
                name="channelType"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择渠道',
                  },
                ]}
              >
                <Select placeholder="请选择渠道">
                  {getOptions(typeData[0]?.data || [], 'code')}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="渠道商"
                name="channelMchId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择渠道商',
                  },
                ]}
              >
                <Select placeholder="请选择渠道商">
                  {getOptions(typeData[1]?.data || [], 'channelMchId')}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="业务公司"
                name="companyId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择业务公司',
                  },
                ]}
              >
                <Select placeholder="请选择业务公司">
                  {getOptions(typeData[2]?.data || [], 'companyId')}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="合同"
                name="companyContractId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择合同',
                  },
                ]}
              >
                <Select placeholder="请选择合同">
                  {getOptions(typeData[3]?.data || [], 'companyContractId')}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="投放方式"
                name="putin"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择投放方式',
                  },
                ]}
              >
                <Select placeholder="请选择投放方式">
                  {getOptions(typeData[5]?.data || [], 'val')}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="业务域名"
                name="domainId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择业务域名',
                  },
                ]}
              >
                <Select placeholder="请选择业务域名">
                  {getOptions(typeData[4]?.data || [], 'domainId', 'domain')}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="状态"
                name="status"
                valuePropName="checked"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="说明" name="descr">
                <TextArea rows={4} placeholder="请输入说明" maxLength={255} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
