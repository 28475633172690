const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    fixed: 'left',
    width: 80,
  },
  {
    title: '分类',
    dataIndex: 'clasz',
    key: 'clasz',
    ellipsis: true,
    render: row => {
      return row?.name
    },
  },
  {
    title: '名称',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
  },
  {
    title: '解析方式',
    dataIndex: 'at',
    key: 'at',
    ellipsis: true,
    render: at => {
      return at?.name
    },
  },
  {
    title: '题目',
    key: 'tms',
    ellipsis: true,
    render: row => {
      const { wds, jzs, tms } = row
      return `${wds}/${jzs}/${tms}`
    },
  },
  {
    title: '时间',
    dataIndex: 'ctime',
    key: 'ctime',
    ellipsis: true,
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    width: 70,
    render: status => {
      return status === 1 ? '启用' : '下架'
    },
  },
]
const dataSource = [
  {
    key: '1',
    index: 1,
  },
  {
    key: '2',
    index: 2,
  },
]
export { columns, dataSource }
