import {
  Button,
  Cascader,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'

import {
  addProtocol,
  editeProtocol,
  protocolDetails,
} from 'reduxs/operation/protocol'

import styles from './add.module.less'
const { Option } = Select
const AddModal = props => {
  const [form] = Form.useForm()
  const {
    editeData,
    handleCancel,
    handSuccess,
    typeList,
    companList,
    businessType,
    domainList,
  } = props
  console.log(domainList)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getDetails()
    // getEnumList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const { companyProtocolId } = editeData
    if (companyProtocolId) {
      const res = await protocolDetails(companyProtocolId)
      if (res.result === 200) {
        const { data } = res
        data.status = data.status === 1 ? true : false
        if (data.claszId) {
          const parentdIds = getParentdIds(
            getOneList(businessType),
            data.claszId
          )
          data.claszId = parentdIds
        }

        form.setFieldsValue(data)
      }
    }
  }
  const getOneList = (list, lists = []) => {
    list.forEach(item => {
      lists.push(item)
      if (item.children?.length) {
        getOneList(item.children, lists)
      }
    })
    return lists
  }
  const getParentdIds = (list, parentId, ids = []) => {
    list.forEach(item => {
      if (item.claszId === parentId) {
        ids.unshift(item.claszId)
        if (item.parentId) {
          getParentdIds(list, item.parentId, ids)
        }
      }
    })
    return ids
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { companyProtocolId } = editeData
    values.status = values.status ? 1 : 0
    const { claszId } = values
    if (claszId?.length) {
      values.claszId = claszId[claszId.length - 1]
    }
    if (companyProtocolId) {
      editeList(values)
      return
    }
    addList(values)
  }
  // 编辑
  const editeList = async values => {
    const { companyProtocolId } = editeData
    values.companyProtocolId = companyProtocolId
    const res = await editeProtocol(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改合同成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addProtocol(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增协议成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title={editeData.companyProtocolId ? '编辑协议' : '新增协议'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{ status: true }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="公司"
                name="companyId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择公司',
                  },
                ]}
              >
                <Select placeholder="请选择公司">
                  {companList.map((item, index) => (
                    <Option value={item.companyId} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="业务类型"
                name="claszId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择公司',
                  },
                ]}
              >
                <Cascader
                  options={businessType}
                  placeholder="请选择"
                  changeOnSelect={false}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="协议类型"
                name="type"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择类型',
                  },
                ]}
              >
                <Select placeholder="请选择类型">
                  {typeList.map((item, index) => (
                    <Option value={`${item.val}`} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="业务域名"
                name="domainId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择业务域名',
                  },
                ]}
              >
                <Select placeholder="请选择业务域名">
                  {domainList.map((item, index) => (
                    <Option value={item.domainId} key={index}>
                      {item.domain}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="排序" name="seq">
                <InputNumber min={0} defaultValue={0} precision={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="状态"
                name="status"
                valuePropName="checked"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
