import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  message,
} from 'antd'
import UploadIcon from 'components/UploadIcon'
import React, { useEffect, useState } from 'react'
import {
  addQuestionResult,
  editeQuestionResult,
  questionResultDetails,
} from 'reduxs/advertising/questionResult'
import styles from './index.module.less'
const { TextArea } = Input
const AddEditeModal = props => {
  const [form] = Form.useForm()
  const {
    editeData,
    handleCancel,
    handSuccess,
    state,
    jzActiveData,
    wdActiveData,
    activeData,
  } = props
  const { isUpdate, questionResultId } = editeData
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [codeRequre, setCodeRequre] = useState(true)
  const [jzData, setJzData] = useState(jzActiveData)
  const [wdData, setwdData] = useState(wdActiveData)
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    if (!isUpdate) {
      return
    }
    const res = await questionResultDetails(questionResultId)
    if (res.result === 200) {
      const { data } = res
      setJzData(data.jz || {})
      setwdData(data.wd || {})
      setCodeRequre(data.sysn ? false : true)
      data.sysn = data.sysn === 1 ? true : false
      setImageUrl(data.icon)
      form.setFieldsValue(data)
    }
  }

  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { questionInfoId } = state
    values.status = 1
    values.sysn = values.sysn ? 1 : 0
    values.icon = imageUrl
    values.questionInfoId = questionInfoId
    values.resultType = activeData.key
    values.questionConditId1 = wdData?.questionConditId
    values.questionConditId2 = jzData?.questionConditId
    if (questionResultId) {
      editeList(values)
      return
    }
    addList(values)
  }
  // 编辑
  const editeList = async values => {
    values.questionResultId = questionResultId
    const res = await editeQuestionResult(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改题目成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addQuestionResult(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增结果成功~')
      handSuccess()
    }
  }
  // 上传图片成功
  const imgSuccess = str => {
    setImageUrl(str)
  }
  // 监听系统编号变化
  const handBox = e => {
    const { checked } = e.target
    setCodeRequre(!checked)
  }
  return (
    <Modal
      width={800}
      title={isUpdate ? '编辑通用/综合结果' : '新增通用/综合结果'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{ status: true }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="测评分类">{state?.clasz?.name}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="名称">{state?.title}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="维度">{wdData?.name}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="基准">{jzData?.name}</Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="ICON" name="icon">
                <UploadIcon handSuccess={imgSuccess} imageUrl={imageUrl} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="标题"
                name="title"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="分数" required>
                <Input.Group compact>
                  <Form.Item
                    name="minScore"
                    required={true}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: '请输入最低分',
                      },
                    ]}
                  >
                    <Input
                      placeholder="请输入最低分"
                      style={{ width: '46%' }}
                    />
                  </Form.Item>
                  <Form.Item noStyle>
                    <span className={styles.jianju}>-</span>
                  </Form.Item>
                  <Form.Item
                    name="maxScore"
                    required={true}
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: '请输入最高分',
                      },
                    ]}
                  >
                    <Input
                      placeholder="请输入最高分"
                      style={{ width: '46%' }}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="编号" key={codeRequre}>
                <Input.Group compact>
                  <Form.Item
                    name="code"
                    required={codeRequre ? true : false}
                    noStyle
                    rules={[
                      {
                        required: codeRequre,
                        message: '请输入编号',
                      },
                    ]}
                  >
                    <Input placeholder="请输入编号" style={{ width: '50%' }} />
                  </Form.Item>
                  <Form.Item
                    name="sysn"
                    className={styles.boxItem}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={handBox}>系统编号</Checkbox>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="说明" name="descr">
                <TextArea rows={4} placeholder="请输入说明" maxLength={120} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="排序" name="seq">
                <InputNumber min={0} defaultValue={0} precision={0} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddEditeModal
