import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取题库列表
export const _getQuestionList = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.question.questionList,
    params: params,
  })
}
// 删除题库
export const _removeQuestion = async params => {
  const path = btnApiList.questionDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.ADVERSRIING.question.questionInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改题库
export const _editeQuestion = async params => {
  const path = btnApiList.questionEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.ADVERSRIING.question.questionInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增题库
export const _addQuestion = async params => {
  const path = btnApiList.questionAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.question.questionInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取题库详情
export const _questionDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.question.questionInfo}/${params}`,
  })
}
// 获取题库内容
export const _getContent = async (id, lang) => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.question.questionContent}/${id}/${lang}`,
  })
}
// 题库内容编辑
export const _updateContent = async params => {
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.question.questionContent,
    data: params,
  })
}
// 维度基准列表
export const _questionConditList = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.question.questionConditList,
    params: params,
  })
}
// 新增维度
export const _addQuestionCondit = async params => {
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.question.questionConditInfo,
    data: params,
  })
}
// 编辑维度多语言
export const _questionConditInfoContent = async params => {
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.question.questionConditInfoContent,
    data: params,
  })
}
// 获取维度多语言
export const _getQuestionConditInfoContent = async (id1, id2) => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.question.questionConditInfoContent}/${id1}/${id2}`,
  })
}
// 获取维度详情
export const _questionCondDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.question.questionConditInfo}/${params}`,
  })
}
// 编辑维度
export const _editeQuestionCondit = async params => {
  return await request({
    method: 'PUT',
    url: Api.ADVERSRIING.question.questionConditInfo,
    data: params,
  })
}
// 删除维度
export const _deleteQuestionCondit = async params => {
  return await request({
    method: 'DELETE',
    url: `${Api.ADVERSRIING.question.questionConditInfo}/${params}`,
  })
}
// 题库邮箱列表
export const _questionMailList = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.advertising.questionMailList,
    params: params,
  })
}
// 题库邮箱类型新增
export const _questionMailAdd = async params => {
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.advertising.questionMailInfo,
    data: params,
  })
}
// 邮箱编辑
export const _questionMailEdite = async params => {
  return await request({
    method: 'PUT',
    url: Api.ADVERSRIING.advertising.questionMailInfo,
    data: params,
  })
}
// 题库邮箱详情
export const _questionMailDetail = async (id1, id2) => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.advertising.questionMailInfo}/${id1}/${id2}`,
  })
}
//
export const _questionMailLang = async (id1, id2) => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.advertising.contentInfo}/${id1}/${id2}`,
  })
}
// 题库邮箱内容编辑
export const _editeLang = async data => {
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.advertising.contentInfo,
    data: data,
  })
}
// 邮箱类型删除
export const _deleteqMailInfo = async (id1, id2) => {
  return await request({
    method: 'DELETE',
    url: `${Api.ADVERSRIING.advertising.questionMailInfo}/${id1}/${id2}`,
  })
}
