import Icon, * as icons from '@ant-design/icons'
import { Button, Col, Modal, Row, Space } from 'antd'
import React, { useState } from 'react'
import styles from './add.module.less'
const iconList = Object.keys(icons).filter(
  item => typeof icons[item] === 'object'
)
const AddModal = props => {
  const { iconData, handleCancel, iconsStr, handSuccess } = props
  const [active, setActive] = useState(iconsStr || '')
  const handleSubmit = () => {
    handSuccess(active)
  }
  // 选择icons
  const handIcons = item => {
    setActive(item)
  }
  return (
    <Modal
      width={600}
      title="选择图标"
      open={iconData?.status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button key="submit" type="primary" onClick={handleSubmit}>
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.iconData}>
        <Row>
          {iconList.map(item => {
            return (
              <Col span={3}>
                <div
                  className={active === item ? styles.activeIcon : styles.icons}
                  onClick={() => handIcons(item)}
                >
                  <Icon
                    component={icons[item]}
                    style={{
                      fontSize: '20px',
                    }}
                  />
                </div>
              </Col>
            )
          })}
        </Row>
      </div>
    </Modal>
  )
}
export default AddModal
