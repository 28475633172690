import {
  Button,
  Cascader,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { getClaszList } from 'reduxs/advertising/clasz'
import {
  addQuestion,
  editeQuestion,
  questionDetails,
} from 'reduxs/advertising/question'
import { getSubData } from 'utils/Tools'
import styles from './index.module.less'
const { Option } = Select
const AddEditeModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess, classType, analy } = props
  const { isUpdate, questionInfoId } = editeData
  const [loading, setLoading] = useState(false)
  const [detailsLoading, setDetailsloading] = useState(false)
  const [detailsData, setDetailsData] = useState({})
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取类型
  const getType = async claszId => {
    const res = await getClaszList({ status: 1, paging: false })
    if (res.result === 200) {
      const parentIds = getParentId(res.data || [], claszId)
      return parentIds
    }
  }
  // 获取其父级
  const getParentId = (list, id, ids = []) => {
    list.forEach(item => {
      if (item.claszId === id) {
        ids.unshift(item.claszId)
        if (item.parentId) {
          getParentId(list, item.parentId, ids)
        }
      }
    })
    return ids
  }
  // 获取详情
  const getDetails = async () => {
    if (!isUpdate) {
      setDetailsloading(false)
      return
    }
    setDetailsloading(true)
    const res = await questionDetails(questionInfoId)
    if (res.result === 200) {
      const { data } = res
      const { claszId } = data
      const patentId = await getType(claszId)
      data.status = data.status === 1 ? true : false
      data.claszId = patentId
      setDetailsData(data)
      form.setFieldsValue(data)
      setDetailsloading(false)
    } else {
      setDetailsloading(false)
    }
  }

  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { claszId } = values
    if (claszId) {
      values.claszId = claszId[claszId.length - 1]
    }
    const { questionInfoId } = editeData
    const data = getSubData(values)
    data.sysn = undefined
    if (questionInfoId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 编辑
  const editeList = async values => {
    const { questionInfoId } = editeData
    values.questionInfoId = questionInfoId
    const res = await editeQuestion(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改题库成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addQuestion(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增题库成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title={isUpdate ? '编辑题库' : '新增题库'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Spin tip="加载中" spinning={detailsLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={() => setLoading(false)}
            initialValues={{ status: true }}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="测评分类"
                  name="claszId"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请选择测评分类',
                    },
                  ]}
                >
                  <Cascader
                    options={classType}
                    placeholder="请选择"
                    changeOnSelect={false}
                    style={{ minWidth: '200px' }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="名称"
                  name="title"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请输入名称',
                    },
                  ]}
                >
                  <Input placeholder="请输入名称" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="解析方式"
                  name="analyType"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请输入解析方式',
                    },
                  ]}
                >
                  <Select placeholder="请输入解析方式">
                    {analy.map((item, index) => (
                      <Option value={`${item.analyType}`} key={index}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="报告页数" name="reports">
                  <Input placeholder="请输入报告页数" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="测评耗时" name="times">
                  <Input placeholder="请输入耗时" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="状态"
                  name="status"
                  valuePropName="checked"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请选择状态',
                    },
                  ]}
                >
                  <Switch />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="题目">
                  {isUpdate
                    ? `${detailsData.wds}/${detailsData.jzs}/${detailsData.tms}`
                    : '0'}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </Modal>
  )
}
export default AddEditeModal
