import { CloseOutlined } from '@ant-design/icons'
import { Image, Upload, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { handleUpload } from 'utils/AliOss'

import styles from './index.module.less'
const imgTypeList = [
  {
    label: 'png',
    type: 'image/png',
    icon: 'ImageIcon',
  },
  {
    label: 'jpg',
    type: 'image/jpg',
    icon: 'ImageIcon',
  },
  {
    label: 'jpeg',
    type: 'image/jpeg',
    icon: 'ImageIcon',
  },
  {
    label: 'svg',
    type: 'image/svg+xml',
    icon: 'ImageIcon',
  },
  {
    label: 'webp',
    type: 'image/webp',
    icon: 'ImageIcon',
  },
  {
    label: 'icon',
    type: 'image/x-icon',
    icon: 'ImageIcon',
  },
]
// const s3 = new AWS.S3({
//   accessKeyId: 'AKIAXYKJVQA2TTX4BXD5',
//   secretAccessKey: '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',
//   region: 'ap-southeast-1', // 替换为你的S3区域
// })
const UploadIcon = props => {
  const { imageUrl, handSuccess } = props
  const [icon, setIcon] = useState('')
  useEffect(() => {
    setIcon(imageUrl)
  }, [imageUrl])
  //附件上传
  const uploadProps = {
    accept: 'image/*',
    showUploadList: false,
    customRequest: async fileObj => {
      const { file } = fileObj
      const res = await handleUpload(file)
      handSuccess(res.url)
    },

    beforeUpload: async file => {
      console.log(file)
      const imgData = imgTypeList.find(item => item.type === file.type)
      if (!imgData) {
        message.error('只能上传jpg/png图片~')
        return false
      }
      return true
    },
  }
  // 删除icon
  const removeIcon = e => {
    e.stopPropagation()
    handSuccess('')
  }
  return (
    <Upload
      {...uploadProps}
      showUploadList={false}
      // beforeUpload={file => uploadFile(file)}
      // customRequest={file => uploadFile(file)}
      action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
      onRemove={() => handSuccess('')}
    >
      {icon ? (
        <div className={styles.content}>
          <Image src={icon} alt="avatar" width={24} preview={false} />
          <span className={styles.remove} onClick={removeIcon}>
            <CloseOutlined />
          </span>
        </div>
      ) : (
        <div className={styles.content}></div>
      )}
    </Upload>
  )
}
export default UploadIcon
