// servers/system

// Selectors

// Actions

// Action Creators

// init data
import {
  _btns,
  _editeInfo,
  _getBiurl,
  _getfildUrl,
  _login,
  _logout,
  _menus,
  _updatePwd,
  _userInfo,
} from 'servers/login'
const initialState = {}

// Reducer
export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    //...
    default:
      return state
  }
}

//异步action
// 登录
export const login = async (data = {}) => {
  const res = await _login(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取个人信息
export const userInfo = async (data = {}) => {
  const res = await _userInfo(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改个人信息
export const editeInfo = async (data = {}) => {
  const res = await _editeInfo(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 退出登陆
export const logout = async (data = {}) => {
  const res = await _logout(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取按钮权限
export const btns = async (data = {}) => {
  const res = await _btns(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取菜单权限
export const menus = async (data = {}) => {
  const res = await _menus(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取菜单权限
export const getfildUrl = async (data = {}) => {
  const res = await _getfildUrl(data)
  console.log(res)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改密码
export const updatePwd = async (data = {}) => {
  const res = await _updatePwd(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取biurl
export const getBiurl = async (data = {}) => {
  const res = await _getBiurl(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
