import {
  _addList,
  _editeList,
  _getDetails,
  _getList,
  _removeList,
} from 'servers/operation/regionManage'
import { changeList } from '../../../Selectors/Common/index'
// 获取地区列表
export const getList = async (data = {}, url = '') => {
  const res = await _getList(data, url)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除支付类型
export const removeList = async (data = {}, path) => {
  const res = await _removeList(data, path)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改支付类型
export const editeList = async (data = {}, url, path) => {
  const res = await _editeList(data, url, path)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增支付类型
export const addList = async (data = {}, url, path) => {
  const res = await _addList(data, url, path)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取地区详情
export const getDetails = async (data = {}, url) => {
  const res = await _getDetails(data, url)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
