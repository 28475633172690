import { Button, Form, Modal, Space, Table, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { equityFreeList } from 'reduxs/advertising/benefitType'
import styles from './add.module.less'
const AddModal = props => {
  const [form] = Form.useForm()
  const { handleCancel, status, giveList, handSuccess } = props
  const [pageData, setPageData] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([]) // 选中的数据
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const ids = (giveList || []).map(item => item.adsId)
    setSelectedRowKeys(ids)
    setLoading(true)
    const res = await equityFreeList({ paging: false, status: 1 })
    setLoading(false)
    if (res.result === 200) {
      const list = res.data || []
      list.forEach((item, index) => (item.index = index + 1))
      setPageData(list)
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    if (!selectedRowKeys.length) {
      return message.error('请选择~')
    }
    form.submit()
    const list = pageData.filter(item => selectedRowKeys.includes(item.adsId))
    handSuccess(list)
  }
  // table可选中
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    type: 'radio',
    onChange: selectedRowKeys => {
      setSelectedRowKeys(selectedRowKeys)
    },
  }
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 80,
    },
    {
      title: '分类',
      dataIndex: 'clasz',
      key: 'clasz',
      render: row => {
        return row?.name
      },
    },
    {
      title: '名称',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '管理员',
      dataIndex: 'adminName',
      key: 'adminName',
    },
    {
      title: '时间',
      dataIndex: 'ctime',
      key: 'ctime',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: status => {
        return status === 1 ? '启用' : '下架'
      },
    },
  ]
  return (
    <Modal
      width={800}
      title="选择测评题"
      open={status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button key="submit" type="primary" onClick={handleSubmit}>
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Table
          dataSource={pageData || []}
          rowKey={item => item.adsId} //React规范中的array都必须有key
          columns={columns}
          size="small"
          pagination={false}
          rowSelection={rowSelection}
          loading={loading}
        />
      </div>
    </Modal>
  )
}
export default AddModal
