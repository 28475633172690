import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Spin,
  Switch,
} from 'antd'
import UploadIcon from 'components/UploadIcon'
import React, { useEffect, useState } from 'react'
import {
  addPlan,
  editePlan,
  planDetails,
} from 'reduxs/advertising/advertisingPlan'
import styles from './index.module.less'
const { TextArea } = Input
const { Option } = Select
const AddEditeModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess, countryList, state } = props
  const { adsPlanId } = editeData
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const [detailsLoading, setDetailsLoading] = useState(false)
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    if (!adsPlanId) {
      return
    }
    setDetailsLoading(true)
    const res = await planDetails(adsPlanId)
    setDetailsLoading(false)
    if (res.result === 200) {
      const { data } = res
      data.sysn = data.sysn ? true : false
      form.setFieldsValue(data)
    }
  }

  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    values.icon = imageUrl
    values.status = values.status ? 1 : 0
    values.adsId = state.adsId
    if (adsPlanId) {
      editeList(values)
      return
    }
    addList(values)
  }
  // 编辑
  const editeList = async values => {
    values.adsPlanId = adsPlanId
    const res = await editePlan(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改方案成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addPlan(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增方案成功~')
      handSuccess()
    }
  }
  // 上传图片成功
  const imgSuccess = str => {
    setImageUrl(str)
  }
  return (
    <Modal
      width={650}
      title={adsPlanId ? '编辑方案' : '新增方案'}
      open={editeData._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Spin tip="加载中" spinning={detailsLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={() => setLoading(false)}
            initialValues={{ status: true }}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="国家"
                  name="countryId"
                  required
                  rules={[
                    {
                      required: true,
                      message: '请选择国家',
                    },
                  ]}
                >
                  <Select
                    placeholder="请选择国家"
                    showSearch
                    filterOption={(input, option) => {
                      return (
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      )
                    }}
                  >
                    {countryList.map((item, index) => (
                      <Option key={index} value={item.countryId}>
                        {`${item.name} (${item.code})`}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="ICON" name="icon">
                  <UploadIcon handSuccess={imgSuccess} imageUrl={imageUrl} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="名称"
                  name="title"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请输入名称',
                    },
                  ]}
                >
                  <Input placeholder="请输入名称" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="销量" name="sales">
                  <InputNumber
                    min={0}
                    defaultValue={0}
                    precision={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="价格"
                  name="price"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请输入价格',
                    },
                  ]}
                >
                  <Input placeholder="请输入价格" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="原价" name="orgPrice">
                  <InputNumber
                    min={0}
                    defaultValue={0}
                    precision={0}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="说明" name="descr">
                  <TextArea rows={4} placeholder="请输入说明" maxLength={120} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="排序" name="seq">
                  <InputNumber min={0} defaultValue={0} precision={0} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="状态"
                  name="status"
                  required={true}
                  valuePropName="checked"
                  rules={[
                    {
                      required: true,
                      message: '请选择状态',
                    },
                  ]}
                >
                  <Switch />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </Modal>
  )
}
export default AddEditeModal
