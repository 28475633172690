import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取业务公司列表
export const _getCompanyList = async params => {
  return await request({
    method: 'GET',
    url: Api.OPERATION.company.companyList,
    params: params,
  })
}
// 删除业务公司
export const _removeCompany = async params => {
  const path = btnApiList.companyDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.OPERATION.company.companyInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改业务公司
export const _editeCompany = async params => {
  const path = btnApiList.companyEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.OPERATION.company.companyInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增业务公司
export const _addCompany = async params => {
  const path = btnApiList.companyAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.OPERATION.company.companyInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取业务公司详情
export const _companyDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.company.companyInfo}/${params}`,
  })
}
