import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  message,
} from 'antd'
import UploadIcon from 'components/UploadIcon'
import React, { useEffect, useState } from 'react'
import {
  addDomain,
  domainDetails,
  editeDomain,
  getDomainInfoList,
} from 'reduxs/operation/domain'
import { getSubData } from 'utils/Tools'
import styles from './add.module.less'
const { Option } = Select
const { TextArea } = Input
const AddModal = props => {
  const { handSuccess } = props
  const [form] = Form.useForm()
  const { editeData, handleCancel } = props
  const [loading, setLoading] = useState(false)
  const [domainList, setDomainList] = useState([]) // 域名列表
  const [imageUrl, setImageUrl] = useState('')
  useEffect(() => {
    getDomain()
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const { domainId } = editeData
    if (domainId) {
      const res = await domainDetails(domainId)
      if (res.result === 200) {
        const { data } = res
        setImageUrl(data.icon)
        data.status = data.status === 1 ? true : false
        form.setFieldsValue(data)
      }
    }
  }
  const getDomain = async () => {
    const res = await getDomainInfoList({ status: 1, paging: false })
    if (res.result === 200) {
      setDomainList(res.data || [])
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { domainId } = editeData
    const data = getSubData(values)
    data.icon = imageUrl
    data.sysn = undefined
    if (domainId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 编辑
  const editeList = async values => {
    const { domainId } = editeData
    values.domainId = domainId
    const res = await editeDomain(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改业务域名成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addDomain(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增业务域名成功~')
      handSuccess()
    }
  }
  // 上传图片成功
  const imgSuccess = str => {
    setImageUrl(str)
  }
  return (
    <Modal
      width={600}
      title={editeData.id ? '编辑业务域名' : '新增业务域名'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{ status: true }}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="ICON" name="icon">
                <UploadIcon handSuccess={imgSuccess} imageUrl={imageUrl} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="状态"
                name="status"
                valuePropName="checked"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="业务域名" required>
                <Input.Group compact>
                  <Form.Item
                    name="protocol"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: '请选择协议',
                      },
                    ]}
                  >
                    <Select placeholder="请选择协议">
                      <Option value="https://" key="https">
                        https://
                      </Option>
                      <Option value="http://" key="http">
                        http://
                      </Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="prefix" noStyle>
                    <Input
                      placeholder="请输入"
                      style={{ marginLeft: '10px', width: '98px' }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="domainInfoId"
                    noStyle
                    rules={[
                      {
                        required: true,
                        message: '请选择域名',
                      },
                    ]}
                  >
                    <Select
                      placeholder="请选择域名"
                      style={{ marginRight: '10px', width: '200px' }}
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {domainList.map((item, index) => (
                        <Option value={item.domainInfoId} key={index}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="说明" name="descr">
                <TextArea rows={4} placeholder="请输入说明" maxLength={120} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
