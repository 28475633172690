import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取配置列表
export const _getConfigList = async params => {
  return await request({
    method: 'GET',
    url: Api.SYSTEM.config.configList,
    params: params,
  })
}
// 删除配置
export const _removeConfig = async params => {
  const path = btnApiList.allocationDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.SYSTEM.config.configInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改配置
export const _editeConfig = async params => {
  const path = btnApiList.allocationEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.SYSTEM.config.configInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增配置
export const _addConfig = async params => {
  const path = btnApiList.allocationAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.SYSTEM.config.configInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取配置详情
export const _configDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.SYSTEM.config.configInfo}/${params}`,
  })
}
