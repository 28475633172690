import { Button, Switch } from 'antd'
import ButtonGroup, { TipButton } from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React from 'react'
const columns = eventData => {
  const { handEdite, handRemove, handDefStatus } = eventData
  return [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 70,
    },
    {
      title: '类型',
      dataIndex: 'paymentMchType',
      key: 'paymentMchType',
      ellipsis: true,
      render: row => row.name,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: '应用ID',
      dataIndex: 'appId',
      key: 'appId',
      ellipsis: true,
    },
    {
      title: 'ID',
      dataIndex: 'mchId',
      key: 'mchId',
      ellipsis: true,
    },
    {
      title: '支付域名',
      dataIndex: 'payDomain',
      key: 'payDomain',
      ellipsis: true,
    },
    {
      title: '支付方式',
      key: 'payType',
      width: 100,
      ellipsis: true,
      render: row => {
        const { outside, within } = row
        const list = []
        if (outside) {
          list.push('站外支付')
        }
        if (within) {
          list.push('站内支付')
        }
        return list.join('、')
      },
    },
    {
      title: '时间',
      dataIndex: 'utime',
      key: 'utime',
      ellipsis: true,
    },
    {
      title: '默认收款',
      key: 'defStatus',
      render: row => {
        return (
          <Switch checked={row.defStatus} onChange={() => handDefStatus(row)} />
        )
      },
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 70,
      render: status => {
        return status === 1 ? '启用' : '禁用'
      },
    },
    {
      title: '操作',
      key: 'change',
      fixed: 'right',
      width: 140,
      render: row => {
        return (
          <>
            <ButtonGroup
              onClick={() => handEdite(row)}
              btnId={btnApiList.merchantEdite}
              type="link"
            >
              编辑
            </ButtonGroup>
            <TipButton
              title="你确定要该商户吗？"
              onConfirm={() => handRemove(row)}
              okText="确定"
              cancelText="取消"
              btnId={btnApiList.merchantDelete}
            >
              <Button type="link">删除</Button>
            </TipButton>
          </>
        )
      },
    },
  ]
}
const dataSource = [
  {
    key: '1',
    index: 1,
    id: 1,
  },
  {
    key: '2',
    index: 2,
    id: 2,
  },
]
export { columns, dataSource }
