import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'
// 获取菜单列表
export const _getMenuList = async params => {
  return await request({
    method: 'GET',
    url: Api.SYSTEM.menu.getMenuList,
    params: params,
  })
}
// 删除菜单
export const _removeMenu = async params => {
  const path = btnApiList.menuManageDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.SYSTEM.menu.removeMenu}${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取菜单详情
export const _menuDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.SYSTEM.menu.removeMenu}${params}`,
  })
}
// 修改菜单
export const _editeMenu = async params => {
  const path = btnApiList.menuManageEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.SYSTEM.menu.removeMenu,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增菜单
export const _addMenu = async params => {
  const path = btnApiList.menuManageAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.SYSTEM.menu.removeMenu,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
