import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取岗位列表
export const _getPostList = async params => {
  return await request({
    method: 'GET',
    url: Api.SYSTEM.post.postList,
    params: params,
  })
}
// 删除岗位
export const _removePost = async params => {
  const path = btnApiList.postManageDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.SYSTEM.post.postInfo}${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改岗位
export const _editePost = async params => {
  const path = btnApiList.postManageEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.SYSTEM.post.postInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增岗位
export const _addPost = async params => {
  const path = btnApiList.postManageAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.SYSTEM.post.postInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取岗位详情
export const _postDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.SYSTEM.post.postInfo}${params}`,
  })
}
