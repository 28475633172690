import {
  Button,
  Cascader,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Table,
  message,
} from 'antd'
import ButtonGroup from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getBenefitList, removeBenefit } from 'reduxs/advertising/benefit'
import { getBenefitTypeList } from 'reduxs/advertising/benefitType'
import { getClaszList } from 'reduxs/advertising/clasz'
import AddModal from '../Compontents/AddModal'
import ContentModal from '../Compontents/ContentModal'
import { columns } from './_help'
const { Option } = Select
let options = {}
const EquityMange = () => {
  const [editeData, setEditeData] = useState({}) // 编辑一行的数据
  const [loading, setLoading] = useState(false)
  const [pages, setPages] = useState({}) // 页码相关
  const [pageData, setPageData] = useState([]) // 列表
  const [businessType, setBusinessType] = useState([]) // 业务分类
  const [benefitType, setBenefitType] = useState([]) // 权益类型
  const [giveList, setGiveList] = useState([])
  useEffect(() => {
    options = { page: 1, limit: 10 }
    getList()
    getBtypeList()
    getBenefitType()
  }, [])
  // 获取列表
  const getList = async () => {
    setLoading(true)
    const res = await getBenefitList(options)
    setLoading(false)
    if (res.result === 200) {
      const { data, page } = res
      setPageData(data || [])
      if (page) {
        setPages(page || 0)
      }
    }
  }
  // 获取业务分类
  const getBtypeList = async () => {
    const res = await getClaszList({ status: 1, paging: false, tree: true })
    if (res.result === 200) {
      const { data } = res
      setBusinessType(data || [])
    }
  }
  // 获取权益类型
  const getBenefitType = async () => {
    const res = await getBenefitTypeList({ paging: false, status: 1 })
    if (res.result === 200) {
      const { data } = res
      const list = (data || []).filter(item => item.benefitType === 'ZSCPT') // 赠送题型
      setGiveList(list)
      setBenefitType(data || [])
    }
  }

  // 删除
  const handRemove = async row => {
    const { benefitId } = row
    const res = await removeBenefit(benefitId)
    if (res.result === 200) {
      message.success('删除成功~')
      getList()
    }
  }
  // 编辑
  const handEdite = row => {
    const data = { ...row, _status: true }
    setEditeData(data)
  }
  // 新增
  const handAdd = () => {
    const data = { _status: true }
    setEditeData(data)
  }
  // 内容
  const handContent = row => {
    const data = { ...row, contnetStatus: true }
    setEditeData(data)
  }
  // 事件合集
  const eventData = {
    handEdite,
    handRemove,
    handContent,
  }
  // 监听table变化
  const changePage = page => {
    options.page = page.current
    options.limit = page.pageSize
    getList()
  }
  // 搜索
  const onFinish = values => {
    const { claszId } = values
    if (claszId?.length) {
      values.claszId = claszId[claszId.length - 1]
    }
    options = { page: 1, limit: 10, ...values }
    getList()
  }
  // 新增编辑成功
  const handSuccess = () => {
    setEditeData({})
    getList()
  }
  return (
    <div className="pageContainer">
      <Spin tip="加载中" spinning={loading}>
        {editeData._status && (
          <AddModal
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
            businessType={businessType}
            benefitType={benefitType}
            giveList={giveList}
          />
        )}
        {editeData.contnetStatus && (
          <ContentModal
            editeData={editeData}
            handleCancel={() => setEditeData({})}
            handSuccess={handSuccess}
          />
        )}

        <Form onFinish={onFinish}>
          <Form.Item name="searchKey" className="formItem">
            <Input placeholder="请搜索" style={{ width: '200px' }} />
          </Form.Item>
          <Form.Item name="claszId" className="formItem">
            {/* <Select placeholder="请选择" style={{ width: '200px' }} allowClear>
              {businessType.map((item, index) => (
                <Option value={item.claszId} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select> */}
            <Cascader
              options={businessType}
              placeholder="请选择"
              changeOnSelect={false}
              style={{ minWidth: '200px' }}
            />
          </Form.Item>
          <Form.Item name="benefitTypeId" className="formItem">
            <Select placeholder="请选择" style={{ width: '200px' }} allowClear>
              {benefitType.map((item, index) => (
                <Option value={item.benefitTypeId} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="status" className="formItem">
            <Select placeholder="请选择" style={{ width: '200px' }} allowClear>
              <Option value={1}>启用</Option>
              <Option value={0}>禁用</Option>
            </Select>
          </Form.Item>
          <Form.Item className="formItem">
            <Space>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <ButtonGroup onClick={handAdd} btnId={btnApiList.equityAdd}>
                新增
              </ButtonGroup>
            </Space>
          </Form.Item>
        </Form>
        <Table
          dataSource={pageData}
          rowKey={item => item.benefitId} //React规范中的array都必须有key
          columns={columns(eventData)}
          size="small"
          onChange={changePage}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            total: pages.count || 0,
            showTotal: total => `共${total}条`,
            defaultPageSize: 10,
            pageSize: options.limit,
            defaultCurrent: 1,
            current: options.page,
            // pageSizeOptions: [10, 30, 50],
            position: ['bottomLeft'],
          }}
        />
      </Spin>
    </div>
  )
}
export default withRouter(EquityMange)
