import {
  Button,
  DatePicker,
  Form,
  Input,
  Select,
  Space,
  Spin,
  Table,
  message,
} from 'antd'
import ButtonGroup from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { payOrderList, payOrderListLoad } from 'reduxs/advertising/common'
import { getChannelAccList } from 'reduxs/operation/channelAcc'
import { getChannelTypeList } from 'reduxs/operation/channelType'
import { downloadFile } from 'utils/Tools'
import { columns, payTypeList } from './_help'
const { Option } = Select
let options = {}
const { RangePicker } = DatePicker
const PayOrder = () => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [pages, setPages] = useState({}) // 页码相关
  const [pageData, setPageData] = useState([]) // 列表
  const [typeList, setTypeList] = useState([]) // 渠道类型
  const [accountList, setAccountList] = useState([]) // 渠道账户类型
  const [loadLoading, setLoadLoading] = useState(false)
  useEffect(() => {
    options = { page: 1, limit: 10 }
    getList()
    getType()
    getAccount()
  }, [])
  // 获取支付订单列表
  const getList = async () => {
    setLoading(true)
    const res = await payOrderList({ ...options })
    setLoading(false)
    if (res.result === 200) {
      const { data, page } = res
      setPageData(data || [])
      if (page) {
        setPages(page || 0)
      }
    }
  }
  // 获取渠道类型
  const getType = async () => {
    const res = await getChannelTypeList({ paging: false })
    if (res.result === 200) {
      const { data } = res
      setTypeList(data || [])
    }
  }
  // 获取渠道账户
  const getAccount = async () => {
    const res = await getChannelAccList({ paging: false })
    if (res.result === 200) {
      const { data } = res
      setAccountList(data || [])
    }
  }
  // 搜索
  const onFinish = values => {
    if (values.time?.length) {
      values.stime = moment(values.time[0]).format('YYYY-MM-DD HH:mm:ss')
      values.etime = moment(values.time[1]).format('YYYY-MM-DD HH:mm:ss')
      delete values.time
    }
    options = {
      page: 1,
      limit: 10,
      ...values,
    }
    getList()
  }
  // 监听table变化
  const changePage = page => {
    options.page = page.current
    options.limit = page.pageSize
    getList()
  }
  // 下载
  const handDownLoad = async () => {
    setLoadLoading(true)
    const formData = form.getFieldsValue()
    if (formData.time?.length) {
      formData.stime = moment(formData.time[0]).format('YYYY-MM-DD HH:mm:ss')
      formData.etime = moment(formData.time[1]).format('YYYY-MM-DD HH:mm:ss')
      delete formData.time
    }
    const { timeType, stime, etime } = formData
    if (!timeType || !stime || !etime) {
      setLoadLoading(false)
      return message.error('请选择时间类型和时间范围~')
    }
    const data = { ...formData, page: undefined, limit: undefined }
    const res = await payOrderListLoad(data)
    setLoadLoading(false)
    try {
      downloadFile(res)
      message.success('下载成功')
    } catch (error) {
      message.success('失败成功')
    }
  }
  return (
    <div className="pageContainer">
      <Spin tip="加载中" spinning={loading}>
        <Form onFinish={onFinish} form={form}>
          <Form.Item name="searchKey" className="formItem">
            <Input placeholder="请搜索" style={{ width: '200px' }} />
          </Form.Item>
          <Form.Item name="channelType" className="formItem">
            <Select placeholder="请选择" allowClear>
              {typeList.map((item, index) => (
                <Option value={item.code} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="channelAccountId" className="formItem">
            <Select placeholder="请选择" allowClear>
              {accountList.map((item, index) => (
                <Option value={item.channelAccountId} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="payStatus" className="formItem">
            <Select placeholder="请选择" allowClear>
              {payTypeList.map((item, index) => (
                <Option value={item.type} key={index}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name="timeType" className="formItem">
            <Select placeholder="请选择" allowClear>
              <Option value="CREATE" key="CREATE">
                下单时间
              </Option>
              <Option value="PAY" key="PAY ">
                支付时间
              </Option>
            </Select>
          </Form.Item>
          <Form.Item name="time" className="formItem">
            <RangePicker showTime allowClear style={{ width: '250px' }} />
          </Form.Item>
          <Form.Item className="formItem">
            <Space>
              <Button type="primary" htmlType="submit">
                查询
              </Button>
              <ButtonGroup
                onClick={handDownLoad}
                loading={loadLoading}
                btnId={btnApiList.payOrderLoad}
              >
                下载
              </ButtonGroup>
            </Space>
          </Form.Item>
        </Form>
        <Table
          dataSource={pageData}
          rowKey={item => item.orgId} //React规范中的array都必须有key
          columns={columns}
          size="small"
          scroll={{
            x: 2000,
            y: 560,
          }}
          onChange={changePage}
          pagination={{
            showSizeChanger: true,
            showQuickJumper: true,
            total: pages.count || 0,
            showTotal: total => `共${total}条`,
            defaultPageSize: 10,
            pageSize: options.limit,
            defaultCurrent: 1,
            current: options.page,
            // pageSizeOptions: [10, 30, 50],
            position: ['bottomLeft'],
          }}
        />
      </Spin>
    </div>
  )
}
export default withRouter(PayOrder)
