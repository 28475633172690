import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取字典列表
export const _getEnunList = async params => {
  return await request({
    method: 'GET',
    url: Api.SYSTEM.enun.enunList,
    params: params,
  })
}
// 删除字典
export const _removeEnun = async params => {
  const path = btnApiList.dicManageDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.SYSTEM.enun.enunInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改字典
export const _editeEnun = async params => {
  const path = btnApiList.dicManageEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.SYSTEM.enun.enunInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增字典
export const _addEnun = async params => {
  let path = btnApiList.dicManageAdd
  const { parentId } = params
  if (!parentId) {
    path = btnApiList.dicManageType
  }
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.SYSTEM.enun.enunInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取字典详情
export const _enunDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.SYSTEM.enun.enunInfo}/${params}`,
  })
}
// 获取通用字段
export const _enumCodeList = async params => {
  return await request({
    method: 'GET',
    url: `${Api.SYSTEM.enun.enumCodeList}/${params}`,
  })
}
