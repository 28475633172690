import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import AreaComponent from '../Compontents/AreaComponent/Index'
import CityComponent from '../Compontents/CityComponent/Index'
import ProvinceComponent from '../Compontents/ProvinceComponent/Index'
import styles from './index.module.less'
const regionList = [
  {
    name: '省',
    value: 'province',
  },
  {
    name: '市',
    value: 'city',
  },
  {
    name: '区',
    value: 'area',
  },
]
const PayMerchant = () => {
  const [active, setActive] = useState('province')
  return (
    <div className="pageContainer">
      <div className={styles.content}>
        {regionList.map(item => (
          <div
            className={active === item.value ? styles.active : styles.listItem}
            onClick={() => setActive(item.value)}
          >
            {item.name}
          </div>
        ))}
      </div>
      {active === 'province' && <ProvinceComponent />}
      {active === 'city' && <CityComponent />}
      {active === 'area' && <AreaComponent />}
    </div>
  )
}
export default withRouter(PayMerchant)
