import { Form, Input, Select } from 'antd'
import Api from 'apis/Api.js'
import React, { useEffect, useState } from 'react'
import { getCountryList } from 'reduxs/operation/country'
import { getList as _getList } from 'reduxs/operation/regionManage'
const { Option } = Select
const provinceUrl = Api.OPERATION.regionManage.provinceList
const cityurl = Api.OPERATION.regionManage.cityList
const areaUrl = Api.OPERATION.regionManage.areaList
const options = { paging: false, status: 1 }
const Address = props => {
  const { _form, editeData, label } = props
  const [provinceList, setProvinceList] = useState([]) // 省列表
  const [countryList, setCountryList] = useState([]) // 国家列表
  const [cityList, setCityList] = useState([]) // 市列表
  const [areaList, setAreaList] = useState([]) // 市列表
  useEffect(() => {
    getCountry()
    // getProvince()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    getProvince(editeData.countryId, true)
    getcity(editeData.provinceId, true)
    getArea(editeData.cityId, true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editeData])
  // 获取国家列表
  const getCountry = async () => {
    const res = await getCountryList(options)
    if (res.result === 200) {
      const { data } = res
      setCountryList(data)
    }
  }

  // 获取省列表
  const getProvince = async (e, status) => {
    if (!e) {
      return
    }
    const res = await _getList({ ...options, countryId: e }, provinceUrl)
    if (res.result === 200) {
      const { data } = res
      setProvinceList(data || [])
      if (!status) {
        removeList(['provinceId', 'cityId', 'areaId'])
      }
    }
  }
  // 获取市列表
  const getcity = async (e, status) => {
    if (!e) {
      return
    }
    const res = await _getList({ ...options, provinceId: e }, cityurl)
    if (res.result === 200) {
      const { data } = res
      setCityList(data || [])
      if (!status) {
        removeList(['cityId', 'areaId'])
      }
    }
  }
  //获取区
  const getArea = async (e, status) => {
    if (!e) {
      return
    }
    const res = await _getList({ ...options, cityId: e }, areaUrl)
    if (res.result === 200) {
      const { data } = res
      if (!status) {
        removeList(['areaId'])
      }
      setAreaList(data || [])
    }
  }
  // 删除表单中的其他输入
  const removeList = list => {
    const formData = _form.getFieldsValue()
    list.forEach(item => {
      formData[item] = undefined
    })
    _form.setFieldsValue(formData)
  }
  return (
    <Form.Item label={label || '注册地'} required>
      <Input.Group compact>
        <Form.Item
          name="countryId"
          noStyle
          rules={[
            {
              required: true,
              message: '请选择国家',
            },
          ]}
        >
          <Select
            placeholder="请选择国家"
            style={{ marginRight: '10px', width: '98px' }}
            onChange={getProvince}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {countryList.map((item, index) => (
              <Option value={item.countryId} key={index}>
                {`${item.name} (${item.code})`}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="provinceId"
          noStyle
          rules={[
            {
              required: true,
              message: '请选择省',
            },
          ]}
        >
          <Select
            placeholder="请选择省"
            style={{ marginRight: '10px', width: '98px' }}
            onChange={getcity}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {provinceList.map((item, index) => (
              <Option value={item.provinceId} key={index}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="cityId"
          noStyle
          rules={[
            {
              required: true,
              message: '请选择市',
            },
          ]}
        >
          <Select
            placeholder="请选择市"
            style={{ marginRight: '10px', width: '98px' }}
            onChange={getArea}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {cityList.map((item, index) => (
              <Option value={item.cityId} key={index}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="areaId"
          noStyle
          rules={[
            {
              required: true,
              message: '请选择区',
            },
          ]}
        >
          <Select
            placeholder="请选择区"
            style={{ marginRight: '10px', width: '98px' }}
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {areaList.map((item, index) => (
              <Option value={item.areaId} key={index}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Input.Group>
    </Form.Item>
  )
}
export default Address
