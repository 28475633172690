import { Image } from 'antd'
import React from 'react'
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 80,
  },
  {
    title: '维度',
    dataIndex: 'wd',
    key: 'wd',
    ellipsis: true,
    render: row => {
      return row?.name
    },
  },
  {
    title: '基准',
    dataIndex: 'jz',
    key: 'jz',
    ellipsis: true,
    render: row => {
      return row?.name
    },
  },
  {
    title: 'ICON',
    dataIndex: 'icon',
    key: 'icon',
    render: icon => {
      return icon ? <Image src={icon} width={24} /> : null
    },
  },
  {
    title: '标题',
    key: 'title',
    ellipsis: true,
    dataIndex: 'title',
  },
  {
    title: '答案',
    dataIndex: 'answs',
    key: 'answs',
    ellipsis: true,
  },
  {
    title: '分数',
    key: 'minScore',
    ellipsis: true,
    render: row => {
      const { minScore, maxScore } = row
      return `${minScore}-${maxScore}`
    },
  },
  {
    title: '排序',
    dataIndex: 'seq',
    key: 'seq',
  },
]
const dataSource = [
  {
    key: '1',
    index: 1,
  },
  {
    key: '2',
    index: 2,
  },
]
export { columns, dataSource }
