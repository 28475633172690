import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取支付类型列表
export const _getPayTypeList = async params => {
  return await request({
    method: 'GET',
    url: Api.OPERATION.payManage.payTypeList,
    params: params,
  })
}
// 删除支付类型
export const _removePayType = async params => {
  const path = btnApiList.payTypeDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.OPERATION.payManage.payTypeInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改支付类型
export const _editePayType = async params => {
  const path = btnApiList.payTypeEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.OPERATION.payManage.payTypeInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增支付类型
export const _addPayType = async params => {
  const path = btnApiList.payTypeAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.OPERATION.payManage.payTypeInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取支付类型详情
export const _payTypeDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.payManage.payTypeInfo}/${params}`,
  })
}
