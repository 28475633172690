import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  addQuestionCondit,
  editeQuestionCondit,
  questionCondDetails,
} from 'reduxs/advertising/question'
import { getSubData } from 'utils/Tools'
import styles from './index.module.less'
const { TextArea } = Input
const AddModal = props => {
  const [form] = Form.useForm()
  const { addData, handleCancel, handSuccess, editeData } = props
  console.log(addData)
  const [loading, setLoading] = useState(false)
  const [codeRequre, setCodeRequre] = useState(true)
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const { questionConditId } = addData
    if (!questionConditId) {
      return
    }
    const res = await questionCondDetails(questionConditId)
    if (res.result === 200) {
      const { data } = res
      setCodeRequre(data.sysn ? false : true)
      data.sysn = data.sysn === 1 ? true : false
      form.setFieldsValue(data)
    }
  }

  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { questionInfoId, conditType, questionConditId } = addData
    const data = getSubData(values)
    data.conditType = conditType
    data.status = undefined
    data.questionInfoId = questionInfoId
    if (questionConditId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 编辑
  const editeList = async values => {
    const { questionConditId } = addData
    values.questionConditId = questionConditId
    const res = await editeQuestionCondit(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addQuestionCondit(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增成功~')
      handSuccess()
    }
  }
  // 监听系统编号变化
  const handBox = e => {
    const { checked } = e.target
    setCodeRequre(!checked)
  }
  return (
    <Modal
      width={600}
      title={addData?.questionConditId ? '编辑维度/基准' : '新增维度/基准'}
      open={addData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{ status: true }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="测评分类">{editeData.clasz?.name}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="名称">{editeData.title}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="代码" key={codeRequre}>
                <Input.Group compact>
                  <Form.Item
                    name="code"
                    required={codeRequre ? true : false}
                    noStyle
                    rules={[
                      {
                        required: codeRequre,
                        message: '请输入编号',
                      },
                    ]}
                  >
                    <Input
                      placeholder="请输入编号"
                      style={{
                        width: '50%',
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="sysn"
                    className={styles.boxItem}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={handBox}>系统编号</Checkbox>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="排序" name="seq">
                <InputNumber min={0} defaultValue={0} precision={0} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="说明" name="descr">
                <TextArea rows={4} placeholder="请输入说明" maxLength={120} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
