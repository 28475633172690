import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 登录
export const _getPageList = async params => {
  return await request({
    method: 'GET',
    url: Api.SYSTEM.account.index,
    params: params,
  })
}
// 新增账号
export const _addAccount = async params => {
  const path = btnApiList.staffAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.SYSTEM.account.addAccount,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 删除账号
export const _removeAccount = async params => {
  const path = btnApiList.staffDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.SYSTEM.account.removeAccount}/${params}`,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取账号详情
export const _accountInfo = async params => {
  return await request({
    method: 'GET',
    url: `${Api.SYSTEM.account.accountInfo}${params}`,
  })
}
// 重置密码
export const _resetPwd = async params => {
  const path = btnApiList.staffReset
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: `${Api.SYSTEM.account.resetPwd}${params}`,
  })
}
// 修改员工
export const _editeAccount = async params => {
  return await request({
    method: 'PUT',
    url: Api.SYSTEM.account.editeAccount,
    data: params,
  })
}
// 账号关联角色
export const _setAccountRole = async params => {
  const path = btnApiList.staffRole
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.SYSTEM.account.accountRole,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取账号关联的角色
export const _getAccountRole = async params => {
  return await request({
    method: 'GET',
    url: Api.SYSTEM.account.accountRole,
    params: params,
  })
}
