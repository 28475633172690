import { Breadcrumb, Layout } from 'antd'
import Router from 'apis/Router'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { getLocal } from 'utils/Help'
import Content from './Components/Content/Index'
import MianHeader from './Components/MianHeader/Index'
import Sider from './Components/Sider/Index'
const Index = props => {
  const { history } = props
  const pathName = window.location.pathname
  const [breadcrumb, setBreadcrumb] = useState([]) // 面包屑
  const [collapsed, setCollapsed] = useState(false)
  const toggle = collapsed => {
    setCollapsed(!collapsed)
  }

  useEffect(() => {
    getBreadcrumbs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName])
  useEffect(() => {
    getToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 查询是否有token

  const getToken = () => {
    const token = getLocal('token')
    if (!token) {
      history.push('/login')
    }
  }
  // 获取路由是否有侧边栏
  const getBreadcrumbs = () => {
    const list = findAllParent(Router, pathName)
    if (list.length) {
      setBreadcrumb(list[0].breadcrumb || [])
    }
  }
  const findAllParent = (list, pathName, menus = []) => {
    list.forEach(item => {
      if (item.path === pathName) {
        menus.push(item)
      }
      if (item.children?.length) {
        findAllParent(item.children, pathName, menus)
      }
    })
    return menus
  }
  return (
    <Layout style={{ background: '#f0f2f5' }}>
      <MianHeader />
      <Layout>
        <Sider collapsed={collapsed} toggle={params => toggle(params)} />
        <Layout
          className="pageLayout"
          style={{ marginLeft: collapsed ? '64px' : '160px' }}
        >
          <div style={{ padding: '10px 0' }}>
            <Breadcrumb>
              {breadcrumb.map((item, index) => (
                <Breadcrumb.Item key={index}>{item.name}</Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </div>
          <Content />
        </Layout>
      </Layout>
    </Layout>
  )
}
export default withRouter(Index)
