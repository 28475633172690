import {
  _addDomain,
  _addDomainInfo,
  _domainDetails,
  _domainInfoDetails,
  _editeDomain,
  _editeInfoDomain,
  _getDomainInfoList,
  _getDomainList,
  _removeDomain,
  _removeInfoDomain,
} from 'servers/operation/domain'
import { changeList } from '../../../Selectors/Common/index'
// 获取业务域名列表
export const getDomainList = async (data = {}) => {
  const res = await _getDomainList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除业务域名
export const removeDomain = async (data = {}) => {
  const res = await _removeDomain(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改业务域名
export const editeDomain = async (data = {}) => {
  const res = await _editeDomain(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增业务域名
export const addDomain = async (data = {}) => {
  const res = await _addDomain(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取业务域名详情
export const domainDetails = async (data = {}) => {
  const res = await _domainDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}

// 获取域名列表
export const getDomainInfoList = async (data = {}) => {
  const res = await _getDomainInfoList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除域名列表
export const removeInfoDomain = async (data = {}) => {
  const res = await _removeInfoDomain(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改域名列表
export const editeInfoDomain = async (data = {}) => {
  const res = await _editeInfoDomain(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增域名列表
export const addDomainInfo = async (data = {}) => {
  const res = await _addDomainInfo(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取域名列表详情
export const domainInfoDetails = async (data = {}) => {
  const res = await _domainInfoDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
