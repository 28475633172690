import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取权益类型列表
export const _getBenefitTypeList = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.benefit.benefitTypeList,
    params: params,
  })
}
// 删除权益类型
export const _removeBenefitType = async params => {
  const path = btnApiList.equityTypeDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.ADVERSRIING.benefit.benefitTypeInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改权益类型
export const _editeBenefitType = async params => {
  const path = btnApiList.equityTypeEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.ADVERSRIING.benefit.benefitTypeInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增权益类型
export const _addBenefitType = async params => {
  const path = btnApiList.equityTypeAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.benefit.benefitTypeInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取权益类型详情
export const _benefitTypeDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.benefit.benefitTypeInfo}/${params}`,
  })
}
// 绑定测评题列表
export const _equityFreeList = async params => {
  return await request({
    method: 'GET',
    url: Api.equityFreeList,
    params: params,
  })
}
