import {
  _addProtocol,
  _editeProContent,
  _editeProtocol,
  _getProtocolList,
  _protocolDetails,
  _removeProtocol,
  _getProContent,
} from 'servers/operation/protocol'
import { changeList } from '../../../Selectors/Common/index'
// 获取业务协议
export const getProtocolList = async (data = {}) => {
  const res = await _getProtocolList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除协议
export const removeProtocol = async (data = {}) => {
  const res = await _removeProtocol(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改协议
export const editeProtocol = async (data = {}) => {
  const res = await _editeProtocol(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增协议
export const addProtocol = async (data = {}) => {
  const res = await _addProtocol(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取协议详情
export const protocolDetails = async (data = {}) => {
  const res = await _protocolDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 编辑协议内容
export const editeProContent = async (data = {}) => {
  const res = await _editeProContent(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取内容
export const getProContent = async (id, lang) => {
  const res = await _getProContent(id, lang)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
