import { Button } from 'antd'
import React from 'react'
import styles from './index.module.less'
const payTypeList = [
  {
    type: 0,
    name: '已关闭',
  },
  {
    type: 5,
    name: '待支付',
  },
  {
    type: 10,
    name: '支付中',
  },
  {
    type: 15,
    name: '支付成功',
  },
  {
    type: 20,
    name: '支付失败',
  },
  {
    type: 25,
    name: '退款中',
  },
  {
    type: 30,
    name: '已退款',
  },
]
const columns = eventData => {
  const { handDetail } = eventData
  return [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 60,
    },
    {
      title: '线索ID',
      dataIndex: 'reqid',
      key: 'reqid',
      ellipsis: true,
      width: 150,
    },
    {
      title: '业务ID',
      // dataIndex: 'orderNo',
      key: 'orderNo',
      ellipsis: true,
      width: 180,
      render: row => (
        <span className={styles.orderNo} onClick={() => handDetail(row)}>
          {row.orderNo}
        </span>
      ),
    },
    {
      title: '投放名称',
      key: 'title',
      ellipsis: true,
      width: 140,
      render: row => {
        const { title, code } = row
        return `${title} ${code}`
        // return (
        //   <div>
        //     {row.title}
        //     <p>{row.code}</p>
        //   </div>
        // )
      },
    },
    {
      title: '国家',
      dataIndex: 'countryCode',
      key: 'countryCode',
      width: 60,
      ellipsis: true,
    },
    {
      title: '语言',
      dataIndex: 'lang',
      key: 'lang',
      width: 60,
      ellipsis: true,
    },
    {
      title: '投放方式',
      dataIndex: 'putinVo',
      key: 'putinVo',
      ellipsis: true,
      render: ct => ct?.name,
    },
    {
      title: '渠道',
      dataIndex: 'ct',
      key: 'ct',
      ellipsis: true,
      render: ct => {
        return ct?.name || ''
      },
    },
    {
      title: '渠道账户',
      key: 'accountName',
      ellipsis: true,
      render: row => {
        const { accountName, accountId } = row
        return `${accountName} ${accountId}`
        // return (
        //   <div>
        //     {row.accountName}
        //     <p>{row.accountId}</p>
        //   </div>
        // )
      },
    },
    {
      title: '支付方案',
      key: 'planName',
      ellipsis: true,
      render: row => {
        const { planName, planPrice } = row
        return `${planName || ''} ${planPrice ? `$${planPrice}` : ''}`
        // return (
        //   <div>
        //     {row.planName}
        //     <p>{`${row.planPrice}`}</p>
        //   </div>
        // )
      },
    },
    {
      title: '支付金额',
      key: 'orderCurrencySymbol',
      ellipsis: true,
      render: row => {
        return `${row.orderCurrencySymbol} ${row.orderMoney}`
      },
    },
    {
      title: '支付状态',
      dataIndex: 'payStatus',
      ellipsis: true,
      key: 'payStatus',
      render: status => {
        const findData = payTypeList.find(item => item.type === status)
        return findData ? (
          <span className={status === 15 ? styles.success : ''}>
            {findData.name}
          </span>
        ) : (
          status
        )
      },
    },
    {
      title: '支付类型',
      dataIndex: 'paymentType',
      key: 'paymentType',
      ellipsis: true,
    },
    {
      title: '免费',
      dataIndex: 'free',
      key: 'free',
      width: 80,
      render: status => (status === 0 ? '否' : '是'),
    },
    {
      title: '来源',
      dataIndex: 'source',
      key: 'source',
      ellipsis: true,
    },
    {
      title: '支付商户',
      dataIndex: 'mchName',
      key: 'mchName',
      ellipsis: true,
    },
    {
      title: '用户',
      dataIndex: 'userAccount',
      key: 'userAccount',
      ellipsis: true,
    },
    {
      title: '用户IP',
      dataIndex: 'ip',
      key: 'ip',
      ellipsis: true,
    },
    {
      title: '创建时间',
      dataIndex: 'ctime',
      key: 'ctime',
      ellipsis: true,
    },
    {
      title: '支付时间',
      dataIndex: 'paySucTime',
      key: 'paySucTime',
      ellipsis: true,
    },
  ]
}
const benefitColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 70,
  },
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: '测评题ID',
    dataIndex: 'freeId',
    key: 'freeId',
  },
  {
    title: '测评题',
    dataIndex: 'freeTitle',
    key: 'freeTitle',
  },
  {
    title: '剩余次数',
    dataIndex: 'frees',
    key: 'frees',
  },
]
const emailColumns = handReissue => {
  return [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 70,
    },
    {
      title: 'ID',
      dataIndex: 'adsOrderEmailId',
      key: 'adsOrderEmailId',
    },
    {
      title: '发送状态',
      dataIndex: 'status',
      key: 'status',
      render: status => {
        if (status === 0) {
          return '等待'
        } else if (status === 5) {
          return '成功'
        } else {
          return '失败'
        }
      },
    },
    {
      title: '邮件类型',
      dataIndex: 'emailType',
      key: 'emailType',
    },
    {
      title: '标题',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: '发送邮箱',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: '目标邮箱',
      dataIndex: 'toEmail',
      key: 'toEmail',
    },
    {
      title: '创建时间',
      dataIndex: 'ctime',
      key: 'ctime',
    },
    {
      title: '发送时间',
      dataIndex: 'utime',
      key: 'utime',
    },
    {
      title: '操作',
      key: 'change',
      render: row => {
        const { status } = row
        return status === 10 ? (
          <Button type="link" onClick={() => handReissue(row)}>
            补发
          </Button>
        ) : null
      },
    },
  ]
}
const payColumns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 70,
  },
  {
    title: '支付ID',
    dataIndex: 'outTradeNo',
    key: 'outTradeNo',
  },
  {
    title: '支付状态',
    dataIndex: 'payStatus',
    key: 'payStatus',
    render: status => {
      const findData = payTypeList.find(item => item.type === status)
      return findData?.name || ''
    },
  },
  {
    title: '支付方案',
    dataIndex: 'payingMoney',
    key: 'payingMoney',
    render: text => `$ ${text}`,
  },
  {
    title: '订单金额',
    key: 'payCurrencySymbol',
    render: row => {
      return `${row.payCurrencySymbol} ${row.payMoney || 0}`
    },
  },
  {
    title: '商户类型',
    dataIndex: 'paymentMchType',
    key: 'paymentMchType',
  },
  {
    title: '支付类型',
    dataIndex: 'paymentType',
    key: 'paymentType',
  },
  {
    title: '支付方式',
    dataIndex: 'paymentWay',
    key: 'paymentWay',
  },
  {
    title: '发起时间',
    dataIndex: 'ctime',
    key: 'ctime',
  },
  {
    title: '成功时间',
    dataIndex: 'paySucTime',
    key: 'paySucTime',
  },
  {
    title: '支付设备',
    key: 'systemGroup',
    render: row => {
      const { systemGroup, browserGroup, deviceType } = row
      return `${systemGroup || ''} ${browserGroup || ''} ${deviceType || ''}`
    },
  },
]
export { benefitColumns, columns, emailColumns, payColumns, payTypeList }
