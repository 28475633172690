import { Button, Cascader, Image, message, Modal, Space, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { setPlanBenefit } from 'reduxs/advertising/advertisingPlan'
import { getBenefitList } from 'reduxs/advertising/benefit'
import { getClaszList } from 'reduxs/advertising/clasz'
import styles from './index.module.less'
let options = {}
const ListModal = props => {
  const { status, handleCancel, handSuccess, editeData, checkList } = props
  const [loading, setLoading] = useState(false)
  const [pageData, setPageData] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [businessType, setBusinessType] = useState([]) // 业务分类
  const [detailsLoading, setDetailsLoading] = useState(false)
  useEffect(() => {
    options = {}
    getList()
    getBType()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取业务类型
  const getBType = async () => {
    const res = await getClaszList({
      status: 1,
      paging: false,
      tree: true,
    })

    if (res.result === 200) {
      const { data } = res
      setBusinessType(data || [])
    }
  }
  const getList = async () => {
    setDetailsLoading(true)
    const ids = (checkList || []).map(item => item.benefitId)
    const res = await getBenefitList({
      paging: false,
      status: 1,
      notin: ids.join(','),
      ...options,
    })
    setDetailsLoading(false)
    if (res.result === 200) {
      const list = (res.data || []).filter(
        item => !ids.includes(item.benefitId)
      )
      list.forEach((item, index) => (item.index = index + 1))
      setPageData(list || [])
    }
  }
  // 编辑确定
  const handleSubmit = async () => {
    const dataList = [...pageData]
    if (!selectedRowKeys.length) {
      return message.error('请选择数据')
    }
    setLoading(true)
    const list = []
    selectedRowKeys.forEach(item => {
      const findData = dataList.find(data => data.benefitId === item)
      if (findData) {
        list.push(findData)
      }
    })
    const data = {
      adsPlanId: editeData.adsPlanId,
      benefits: list.map(item => {
        return { benefitId: item.benefitId, seq: 0 }
      }),
    }
    const res = await setPlanBenefit(data)
    setLoading(false)
    if (res.result === 200) {
      message.success('添加成功~')
      handSuccess()
    }
  }
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
    },
    {
      title: '分类',
      dataIndex: 'clasz',
      key: 'clasz',
      render: row => {
        return row?.name
      },
    },
    {
      title: '权益类型',
      dataIndex: 'benefitType',
      key: 'benefitType',
      render: row => {
        return row?.name
      },
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ICON',
      dataIndex: 'icon',
      key: 'icon',
      render: icon => {
        return icon ? <Image src={icon} width={24} /> : ''
      },
    },
    {
      title: '价值',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: '说明',
      dataIndex: 'descr',
      key: 'descr',
    },
    {
      title: '排序',
      dataIndex: 'seq',
      key: 'seq',
    },
  ]
  // table可选中
  const rowSelection = {
    selectedRowKeys: selectedRowKeys,
    onChange: selectedRowKeys => {
      setSelectedRowKeys(selectedRowKeys)
    },
  }
  // 树选择
  const handChage = e => {
    if (e?.length) {
      options = {
        claszId: e[e.length - 1],
      }
      getList()
    } else {
      options = {}
      getList()
    }
  }
  return (
    <Modal
      width={1000}
      title="方案权益"
      open={status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <div className={styles.search} style={{ marginBottom: '20px' }}>
          <Cascader
            options={businessType}
            placeholder="请选择"
            changeOnSelect={false}
            style={{ minWidth: '150px' }}
            onChange={handChage}
          />
        </div>
        <Table
          dataSource={pageData}
          rowSelection={rowSelection}
          rowKey={item => item.benefitId} //React规范中的array都必须有key
          columns={columns}
          size="small"
          pagination={false}
          key={[]}
          loading={detailsLoading}
        />
      </div>
    </Modal>
  )
}
export default ListModal
