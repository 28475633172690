// servers/system

// Selectors

// Actions

// Action Creators

// init data
import {
  _addCurrency,
  _currencyDetails,
  _editeCurrency,
  _getCurrencyList,
  _removeCurrency,
} from 'servers/operation/currency'
import { changeList } from '../../../Selectors/Common/index'
// 获取货币列表
export const getCurrencytionList = async (data = {}) => {
  const res = await _getCurrencyList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除货币
export const removeCurrencytion = async (data = {}) => {
  const res = await _removeCurrency(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改货币
export const editeCurrencytion = async (data = {}) => {
  const res = await _editeCurrency(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增货币
export const addCurrencytion = async (data = {}) => {
  const res = await _addCurrency(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取货币详情
export const currencytionDetails = async (data = {}) => {
  const res = await _currencyDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
