import { Layout } from 'antd'
import Router from 'apis/Router'
import React from 'react'
import { Switch } from 'react-router-dom'
import { getAllRoutes } from 'utils/Help'

const { Content } = Layout

const CContent = () => {
  //将router数组生成全部的Route在路由系统进行注册
  const routes = getAllRoutes(Router)
  return (
    <>
      <Content>
        <Switch>{routes}</Switch>
      </Content>
    </>
  )
}
export default CContent
