import {
  Button,
  Cascader,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { getPageList } from 'reduxs/system/account'
import { enumCodeList } from 'reduxs/system/enun'
import { addOrg, editeOrg, getOrgList, orgDetails } from 'reduxs/system/org'
import { getSubData } from 'utils/Tools'
import styles from './add.module.less'
const { TextArea } = Input
const allOpt = {
  value: 0,
  label: '顶级组织',
}
const { Option } = Select
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [treeList, setTreeList] = useState([])
  const [director, setDirector] = useState([]) // 负责人
  const [typeList, setTypeList] = useState([]) // 机构类型
  const [codeRequre, setCodeRequre] = useState(true)
  useEffect(() => {
    getTreeList()
    getTreeOneList()
    getDirector()
    getCode()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 获取负责人字典
  const getCode = async () => {
    const res = await enumCodeList('ORG_TYPE') // 类型
    if (res.result === 200) {
      setTypeList(res.data || [])
    }
  }
  // 获取负责人类别
  const getDirector = async () => {
    const res = await getPageList({ paging: false, status: 1 })
    if (res.result === 200) {
      setDirector(res.data || [])
    }
  }
  const getTreeOneList = async () => {
    const res = await getOrgList({ paging: false, status: 1 })
    if (res.result === 200) {
      getDetails(res.data || [])
    }
  }
  // 获取上个页面传递过来的详情
  const getDetails = async treeList => {
    if (editeData?.orgId) {
      const { orgId } = editeData
      const res = await orgDetails(orgId)
      if (res.result === 200) {
        const { data } = res
        setCodeRequre(data.sysn ? false : true)
        const parentdIds = getParentdIds(treeList, data.parentId)
        data.parentId = [
          ...parentdIds,
          ...(data.parentId ? [] : [data.parentId]),
        ]
        form.setFieldsValue(data)
      }
    }
  }
  const getParentdIds = (list, parentId, ids = []) => {
    list.forEach(item => {
      if (item.orgId === parentId) {
        ids.unshift(item.orgId)
        if (item.parentId) {
          getParentdIds(list, item.parentId, ids)
        }
      }
    })
    return ids
  }
  // 获取直接上级
  const getTreeList = async () => {
    const res = await getOrgList({ tree: true, status: 1 })
    if (res.result === 200) {
      const { data } = res
      const list = (data || []).filter(item => item.orgId !== editeData.orgId)
      const treeData = getTreeData(list)
      setTreeList([allOpt, ...treeData])
    }
  }
  const getTreeData = list => {
    list.forEach(item => {
      item.children = (item.children || []).filter(
        item => item.orgId !== editeData.orgId
      )
      if (item.children?.length) {
        getTreeData(item.children)
      }
    })
    return list
  }
  // 提交form表单
  const onFinish = values => {
    const { orgId } = editeData
    values.parentId = values.parentId[values.parentId.length - 1]
    const data = getSubData(values)
    if (orgId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 编辑
  const editeList = async values => {
    const { orgId } = editeData
    values.orgId = orgId
    const res = await editeOrg(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改组织成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addOrg(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增组织成功~')
      handSuccess()
    }
  }
  // 监听系统编号变化
  const handBox = e => {
    const { checked } = e.target
    setCodeRequre(!checked)
  }
  return (
    <Modal
      width={600}
      title={editeData.id ? '编辑架构' : '新增架构'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{
            status: true,
            sysn: false,
          }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="上级机构"
                name="parentId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择上级机构',
                  },
                ]}
              >
                <Cascader
                  options={treeList}
                  placeholder="请选择上级"
                  changeOnSelect
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="机构编号" key={codeRequre}>
                <Input.Group compact>
                  <Form.Item
                    name="code"
                    required={codeRequre ? true : false}
                    noStyle
                    rules={[
                      {
                        required: codeRequre,
                        message: '请输入编号',
                      },
                    ]}
                  >
                    <Input
                      placeholder="请输入编号"
                      style={{
                        width: '50%',
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="sysn"
                    className={styles.boxItem}
                    valuePropName="checked"
                  >
                    <Checkbox onChange={handBox}>系统编号</Checkbox>
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="机构名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入机构名称',
                  },
                ]}
              >
                <Input placeholder="请输入机构名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="机构类型"
                name="orgType"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入机构类型',
                  },
                ]}
              >
                <Select placeholder="请输入机构类型">
                  {typeList.map((item, index) => (
                    <Option value={item.val} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="负责人" name="directorId">
                <Select
                  showSearch
                  placeholder="请选择负责人"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {director.map((item, index) => (
                    <Option value={item.powerInfoId} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="状态"
                name="status"
                required={true}
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="说明" name="descr">
                <TextArea rows={4} placeholder="请输入说明" maxLength={120} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
