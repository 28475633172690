import { Tooltip } from 'antd'
import React from 'react'
import styles from './index.module.less'
const columns = handMore => {
  return [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      fixed: 'left',
      width: 70,
    },
    {
      title: 'ID',
      dataIndex: 'adsId',
      key: 'adsId',
      ellipsis: true,
      width: 70,
    },
    {
      title: '分类',
      dataIndex: 'clasz',
      key: 'clasz',
      ellipsis: true,
      width: 70,
      render: row => row?.name,
    },
    {
      title: '名称',
      dataIndex: 'title',
      key: 'title',
      width: 120,
      ellipsis: true,
    },
    {
      title: '代码',
      key: 'code',
      width: 100,
      ellipsis: true,
      dataIndex: 'code',
    },
    {
      title: '模式',
      dataIndex: 'profitTypeVo',
      key: 'profitTypeVo',
      width: 100,
      ellipsis: true,
      render: row => row?.name,
    },
    {
      title: '支付价',
      key: 'adsData',
      dataIndex: 'adsData',
      ellipsis: true,
      render: row => {
        const list = row?.prices || []
        return list.join('、')
      },
    },
    {
      title: '渠道类型',
      key: 'ct',
      dataIndex: 'ct',
      width: 100,
      ellipsis: true,
      render: row => row?.name,
    },
    {
      title: '渠道账户',
      key: 'channelAccount',
      dataIndex: 'channelAccount',
      width: 100,
      ellipsis: true,
      render: row => row?.name,
    },
    {
      title: '投放方式',
      key: 'putinVo',
      dataIndex: 'putinVo',
      ellipsis: true,
      width: 100,
      render: row => row?.name,
    },
    {
      title: '管理员',
      key: 'adminName',
      dataIndex: 'adminName',
      ellipsis: true,
      width: 100,
    },
    {
      title: '邮箱',
      key: 'email',
      dataIndex: 'email',
      ellipsis: true,
      width: 140,
    },
    {
      title: '成交金额',
      key: 'money',
      render: row => row.adsData?.money,
    },
    {
      title: '数据',
      key: 'data',
      ellipsis: true,
      width: 100,
      render: row => {
        const adsData = row?.adsData || {}
        return `${adsData.dts || 0}/${adsData.wts || 0}/${adsData.fas || 0}/${
          adsData.zfcjs || 0
        }`
      },
    },
    {
      title: '投放链接',
      key: 'domain',
      width: 200,
      render: row => {
        const { domain } = row
        if (domain) {
          return (
            <div className={styles.domainContent}>
              <Tooltip title={domain}>
                <span className={styles.domainName}>{domain}</span>
              </Tooltip>
              <span className={styles.mare} onClick={() => handMore(row)}>
                ...
              </span>
            </div>
          )
        }
      },
    },
    {
      title: '时间',
      dataIndex: 'ctime',
      key: 'ctime',
      ellipsis: true,
      width: 160,
    },
    {
      title: '状态',
      dataIndex: 'status',
      width: 70,
      key: 'status',
      render: status => (status === 1 ? '上架' : '下架'),
    },
  ]
}
const dataSource = [
  {
    key: '1',
    index: 1,
  },
  {
    key: '2',
    index: 2,
  },
]
const btnList = [
  {
    name: '新增',
    type: 'add',
    notIsSelect: true,
    status: 'manageAdd',
  },
  {
    name: '编辑',
    type: 'add',
    status: 'manageEdite',
  },
  {
    name: '语言管理',
    type: 'lang',
    status: 'manageLang',
  },
  {
    name: '方案管理',
    type: 'programme',
    status: 'manageProgramme',
  },
  {
    name: '支付商户',
    type: 'pay',
    status: 'managePay',
  },
  {
    name: '邮箱设置',
    type: 'email',
    status: 'manageEmail',
  },
  {
    name: '复制',
    type: 'copy',
    status: 'programmeCopy',
  },
  {
    name: '删除',
    type: 'delete',
    status: 'manageDelete',
  },
]
export { btnList, columns, dataSource }
