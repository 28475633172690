import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Space,
  Switch,
  message,
} from 'antd'
import UploadIcon from 'components/UploadIcon'
import React, { useEffect, useState } from 'react'
import {
  addMerchantType,
  editeMerchantType,
  merchantTypeDetails,
} from 'reduxs/operation/merchantType'
import { getSubData } from 'utils/Tools'
import styles from './add.module.less'
const { TextArea } = Input
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const { paymentMchTypeId } = editeData
    if (paymentMchTypeId) {
      const res = await merchantTypeDetails(paymentMchTypeId)
      if (res.result === 200) {
        const { data } = res
        setImageUrl(data.icon)
        data.status = data.status === 1 ? true : false
        form.setFieldsValue(data)
      }
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { paymentMchTypeId } = editeData
    const data = getSubData(values)
    data.icon = imageUrl
    data.sysn = undefined
    if (paymentMchTypeId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 编辑
  const editeList = async values => {
    const { paymentMchTypeId } = editeData
    values.paymentMchTypeId = paymentMchTypeId
    const res = await editeMerchantType(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改商户类型成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addMerchantType(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增商户类型成功~')
      handSuccess()
    }
  }
  // 上传图片成功
  const imgSuccess = url => {
    setImageUrl(url)
  }
  return (
    <Modal
      width={600}
      title={editeData.paymentMchTypeId ? '编辑商户类型' : '新增商户类型'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{
            status: true,
          }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="ICON" name="ICON">
                <UploadIcon handSuccess={imgSuccess} imageUrl={imageUrl} />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item
                label="名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="代码"
                name="code"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入代码',
                  },
                ]}
              >
                <Input placeholder="请输入代码" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="排序" name="seq">
                <InputNumber min={0} defaultValue={0} precision={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="状态"
                name="status"
                required={true}
                valuePropName="checked"
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="说明" name="descr">
                <TextArea rows={4} placeholder="请输入说明" maxLength={120} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
