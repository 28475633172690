import {
  _addChannelType,
  _channelTypeDetails,
  _editeChannelType,
  _getChannelTypeList,
  _removeChannelType,
} from 'servers/operation/channelType'
import { changeList } from '../../../Selectors/Common/index'
// 获取渠道类型列表
export const getChannelTypeList = async (data = {}) => {
  const res = await _getChannelTypeList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除渠道类型
export const removeChannelType = async (data = {}) => {
  const res = await _removeChannelType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改渠道类型
export const editeChannelType = async (data = {}) => {
  const res = await _editeChannelType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增渠道类型
export const addChannelType = async (data = {}) => {
  const res = await _addChannelType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取渠道类型详情
export const channelTypeDetails = async (data = {}) => {
  const res = await _channelTypeDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
