import {
  Button,
  Col,
  Form,
  Modal,
  Radio,
  Row,
  Table,
  Tabs,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  adsPayment,
  deletePayment,
  getPayment,
} from 'reduxs/advertising/advertising'
import { getMerchantTypeList } from 'reduxs/operation/merchantType'
import styles from './index.module.less'
const options = { status: 1, paging: false }
const MailManage = props => {
  const { editeData, handleCancel } = props
  const [typeList, setTypeList] = useState([])
  const [merList, setMerList] = useState([])
  const [loading, setLoading] = useState(false)
  const [activeData, setActiveData] = useState({})
  useEffect(() => {
    getList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取商户类型列表
  const getList = async () => {
    setLoading(true)
    const res = await getMerchantTypeList(options)
    if (res.result === 200) {
      const { data } = res
      const list = data || []
      list.forEach(item => {
        item.label = item.name
        item.key = item.paymentMchTypeId
      })
      setLoading(false)
      setTypeList(list || [])
      setActiveData(list.length ? list[0] : null)
      getMerchant(list.length ? list[0] : null)
    } else {
      setLoading(false)
    }
  }
  // 获取支付商户
  const getMerchant = async data => {
    if (!data) {
      return
    }
    const { paymentMchTypeId } = data
    const { adsId } = editeData
    const res = await getPayment(adsId, paymentMchTypeId)
    if (res.result === 200) {
      const list = res.data || []
      list.forEach((item, index) => {
        item.payType = item.within ? 'within' : item.outside ? 'outside' : null
        item.index = index + 1
        item.title = item.name
      })
      setMerList(list || [])
    }
  }
  // 选择站内还是站外
  const handPayType = (e, row) => {
    const { value } = e.target
    const { index } = row
    const list = [...merList]
    list[index - 1].payType = value
    setMerList(list)
  }
  // 绑定
  const handBinding = async row => {
    const { adsId } = editeData
    const { paymentMchId, paymentMchTypeId, payType } = row
    const data = { adsId, paymentMchId, paymentMchTypeId }
    if (payType === 'within') {
      data.within = 1
    } else {
      data.within = 0
    }
    if (payType === 'outside') {
      data.outside = 1
    } else {
      data.outside = 0
    }
    const res = await adsPayment(data)
    if (res.result === 200) {
      message.success('绑定成功~')
      getMerchant(activeData)
    }
  }
  // 解除绑定
  const removePayment = async row => {
    const { adsId } = editeData
    const { paymentMchTypeId } = row
    const res = await deletePayment(adsId, paymentMchTypeId)
    if (res.result === 200) {
      message.success('解除绑定成功~')
      getMerchant(activeData)
    }
  }
  // 切换table
  const onChange = key => {
    const data = typeList.find(item => item.key === key)
    getMerchant(data)
  }
  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'ID',
      dataIndex: 'mchId',
      key: 'mchId',
    },
    {
      title: '支付方式',
      key: 'payType',
      render: row => {
        return (
          <Radio.Group onChange={e => handPayType(e, row)} value={row.payType}>
            <Radio value="within">站内支付</Radio>
            <Radio value="outside">站外支付</Radio>
          </Radio.Group>
        )
      },
    },
    {
      title: '操作',
      key: 'changge',
      render: row => {
        const { sel } = row
        return sel ? (
          <Button type="link" onClick={() => removePayment(row)}>
            解除绑定
          </Button>
        ) : (
          <Button type="link" onClick={() => handBinding(row)}>
            绑定
          </Button>
        )
      },
    },
  ]
  return (
    <Modal
      width={800}
      title="支付商户"
      open={editeData.type ? true : false}
      onCancel={handleCancel}
      onOk={handleCancel}
      confirmLoading={true}
      footer={[]}
    >
      <div className={styles.formData}>
        <Form>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="名称">{editeData.title}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="代码">{editeData.code}</Form.Item>
            </Col>
            <Col span={24}>
              <Tabs
                activeKey={activeData?.languageId}
                tabPosition="top"
                items={typeList}
                onChange={onChange}
              />
            </Col>
            <Col span={24}>
              <Table
                dataSource={merList}
                rowKey={item => item.paymentMchId} //React规范中的array都必须有key
                columns={columns}
                size="small"
                pagination={false}
                loading={loading}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default MailManage
