const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
    width: 80,
  },
  {
    title: '类型',
    key: 'et',
    ellipsis: true,
    render: row => row?.name || '',
  },
  {
    title: '标题',
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
  },
  {
    title: '说明',
    key: 'descr',
    ellipsis: true,
    dataIndex: 'descr',
  },
]
export { columns }
