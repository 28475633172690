import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Switch,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  addMailboxType,
  editeMailboxType,
  mailboxTypeDetails,
} from 'reduxs/operation/mailboxType'
import { getSubData } from 'utils/Tools'
import styles from './add.module.less'
const { TextArea } = Input
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [detailsLoading, setDetailsLoading] = useState(false)
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取详情
  const getDetails = async () => {
    const { emailTypeId } = editeData
    if (emailTypeId) {
      setDetailsLoading(true)
      const res = await mailboxTypeDetails(emailTypeId)
      setDetailsLoading(false)
      if (res.result === 200) {
        const { data } = res
        data.status = data.status === 1 ? true : false
        form.setFieldsValue(data)
      }
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { emailTypeId } = editeData
    const data = getSubData(values)
    data.sysn = undefined
    if (emailTypeId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 编辑
  const editeList = async values => {
    const { emailTypeId } = editeData
    values.emailTypeId = emailTypeId
    const res = await editeMailboxType(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改邮件类型成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addMailboxType(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增邮件类型成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title={editeData.emailTypeId ? '编辑邮件类型' : '新增邮件类型'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Spin tip="加载中" spinning={detailsLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={() => setLoading(false)}
            initialValues={{
              status: true,
            }}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label="名称"
                  name="name"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请输入名称',
                    },
                  ]}
                >
                  <Input placeholder="请输入名称" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="代码"
                  name="emailType"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请输入代码',
                    },
                  ]}
                >
                  <Input
                    placeholder="请输入代码"
                    disabled={editeData?.emailTypeId}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="排序" name="seq">
                  <InputNumber min={0} defaultValue={0} precision={0} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="状态"
                  name="status"
                  valuePropName="checked"
                  required={true}
                  rules={[
                    {
                      required: true,
                      message: '请选择状态',
                    },
                  ]}
                >
                  <Switch defaultChecked />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="说明" name="descr">
                  <TextArea rows={4} placeholder="请输入说明" maxLength={120} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </Modal>
  )
}
export default AddModal
