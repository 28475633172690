import { Button, Col, Form, message, Modal, Row, Select, Space } from 'antd'
import React, { useEffect, useState } from 'react'
import { getAdvertisingList } from 'reduxs/advertising/advertising'
import { claszDef, claszDetails } from 'reduxs/advertising/clasz'
import { getCountryList } from 'reduxs/operation/country'
import { getLangList } from 'reduxs/operation/lang'
import styles from './add.module.less'
const { Option } = Select
let deOptions = { paging: false, status: 1 }
const DefaultMadle = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [country, setCountry] = useState([]) // 国家列表
  const [langList, setLangList] = useState([])
  const [adList, setAdlist] = useState([])
  useEffect(() => {
    getDetails()
    getCountry()
    getLang()
    getAdList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取国家列表
  const getCountry = async () => {
    const res = await getCountryList(deOptions)
    if (res.result === 200) {
      const { data } = res
      setCountry(data || [])
    }
  }
  // 获取语言列表
  const getLang = async () => {
    const res = await getLangList(deOptions)
    if (res.result === 200) {
      setLangList(res.data || [])
    }
  }
  const getAdList = async () => {
    setLoading(true)
    const res = await getAdvertisingList({
      ...deOptions,
      claszId: editeData.claszId,
    })
    setLoading(false)
    if (res.result === 200) {
      const { data } = res
      setAdlist(data || [])
    }
  }
  // 获取详情
  const getDetails = async () => {
    const { claszId } = editeData
    if (claszId) {
      const res = await claszDetails(claszId)
      if (res.result === 200) {
        const { data } = res
        // data.parentId = [parentId]
        form.setFieldsValue(data)
      }
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = async values => {
    const { claszId } = editeData
    const data = { ...values, claszId: claszId }
    const res = await claszDef(data)
    if (res.result === 200) {
      message.success('修改默认数据成功')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title="设置分类默认数据"
      open={editeData?.default}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{
            status: true,
          }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="名称">{editeData.name}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="代码">{editeData.code}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="国家" name="defCountry">
                <Select
                  placeholder="请选择国家"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {country.map((item, index) => (
                    <Option value={item.code} key={index}>
                      {`${item.name} (${item.code})`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="语言" name="defLang">
                <Select
                  placeholder="请选择语言"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {langList.map((item, index) => (
                    <Option value={item.code} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="投放" name="defAdsId">
                <Select
                  placeholder="请选择投放"
                  allowClear
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {adList.map((item, index) => (
                    <Option value={item.adsId} key={index}>
                      {item.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default DefaultMadle
