const statusList = [
  {
    type: 0,
    name: '待回传',
  },
  {
    type: 5,
    name: '成功',
  },
  {
    type: 10,
    name: '失败',
  },
]
const columns = [
  {
    title: '序号',
    dataIndex: 'index',
    key: 'index',
  },
  {
    title: '线索ID',
    dataIndex: 'reqid',
    key: 'reqid',
    ellipsis: true,
  },
  {
    title: '业务ID',
    dataIndex: 'orderId',
    key: 'orderId',
    ellipsis: true,
  },
  {
    title: '投放名称',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
  },
  {
    title: '渠道',
    dataIndex: 'ct',
    key: 'ct',
    ellipsis: true,
    render: ct => {
      return ct?.name || ''
    },
  },
  {
    title: '渠道账户',
    dataIndex: 'accountName',
    key: 'accountName',
    ellipsis: true,
  },
  {
    title: '归因事件',
    dataIndex: 'adEvent',
    key: 'adEvent',
    ellipsis: true,
  },
  {
    title: '归因代码',
    dataIndex: 'adCode',
    key: 'adCode',
    ellipsis: true,
  },
  {
    title: '归因路径',
    dataIndex: 'adUrl',
    key: 'adUrl',
    ellipsis: true,
  },
  {
    title: '归因时间',
    dataIndex: 'ctime',
    key: 'ctime',
    ellipsis: true,
  },
  {
    title: '状态',
    dataIndex: 'status',
    key: 'status',
    width: 100,
    ellipsis: true,
    render: status => {
      const findData = statusList.find(item => item.type === status)
      return findData ? findData.name : status
    },
  },
  {
    title: '描述',
    dataIndex: 'reqMsg',
    key: 'reqMsg',
    ellipsis: true,
  },
]
const dataSource = [
  {
    key: '1',
    index: 1,
  },
  {
    key: '2',
    index: 2,
  },
]
export { columns, dataSource }
