import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取渠道账户列表
export const _getChannelAccList = async params => {
  return await request({
    method: 'GET',
    url: Api.OPERATION.channel.channelAccList,
    params: params,
  })
}
// 删除渠道账户
export const _removeChannelAcc = async params => {
  const path = btnApiList.channelAccDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.OPERATION.channel.channelAccInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改渠道账户
export const _editeChannelAcc = async params => {
  const path = btnApiList.channelAccEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.OPERATION.channel.channelAccInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增渠道账户
export const _addChannelAcc = async params => {
  const path = btnApiList.channelAccAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.OPERATION.channel.channelAccInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取渠道账户详情
export const _channelAccDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.channel.channelAccInfo}/${params}`,
  })
}
// 配置渠道广告
export const _setChannelBack = async params => {
  return await request({
    method: 'POST',
    url: Api.OPERATION.channel.channelBack,
    data: params,
  })
}
// 获取渠道广告
export const _getChannelBack = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.channel.channelBack}/${params}`,
  })
}
