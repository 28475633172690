import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  message,
} from 'antd'
import Api from 'apis/Api.js'
import { InitialList } from 'constants/Options'
import { btnApiList } from 'constants/permisson'
import React, { useEffect, useState } from 'react'
import {
  addList as _addList,
  editeList as _editeList,
  getDetails as _getDetails,
  getList as _getList,
} from 'reduxs/operation/regionManage'
import { getSubData } from 'utils/Tools'
import styles from './add.module.less'
const provinceUrl = Api.OPERATION.regionManage.provinceList
const addPath = btnApiList.addCity
const editePath = btnApiList.editeCity
const url = Api.OPERATION.regionManage.cityInfo
const { Option } = Select
const CityModule = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [provinceList, setProvinceList] = useState([]) // 省列表
  useEffect(() => {
    getProvince()
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取省列表
  const getProvince = async () => {
    const res = await _getList({ paging: false, status: 1 }, provinceUrl)
    if (res.result === 200) {
      setProvinceList(res.data || [])
    }
  }
  // 获取详情
  const getDetails = async () => {
    const { districtCityId } = editeData
    if (districtCityId) {
      const urlStr = `${url}/${districtCityId}`
      const res = await _getDetails(districtCityId, urlStr)
      if (res.result === 200) {
        const { data } = res
        data.status = data.status === 1 ? true : false
        form.setFieldsValue(data)
      }
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { districtCityId } = editeData
    const data = getSubData(values)
    if (data.capital) {
      data.capital = 1
    } else {
      data.capital = 0
    }
    if (districtCityId) {
      editeList(data)
      return
    }
    addList(data)
  }
  // 编辑
  const editeList = async values => {
    const { districtCityId } = editeData
    values.districtCityId = districtCityId
    const res = await _editeList(values, url, editePath)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await _addList(values, url, addPath)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title={editeData.districtCityId ? '编辑市' : '新增市'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{
            status: true,
            capital: true,
          }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="省"
                name="provinceId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择省',
                  },
                ]}
              >
                <Select
                  placeholder="请选择省"
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {provinceList.map((item, index) => (
                    <Option value={item.provinceId} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}></Col>
            <Col span={12}>
              <Form.Item
                label="名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="代码"
                name="cityId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入代码',
                  },
                ]}
              >
                <Input placeholder="请输入代码" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="首字母"
                name="initial"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择首字母',
                  },
                ]}
              >
                <Select placeholder="请选择首字母">
                  {InitialList.map((item, index) => (
                    <Option value={item} key={index}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="省会"
                name="capital"
                valuePropName="checked"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择省会',
                  },
                ]}
              >
                <Switch />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="邮编" name="postCode">
                <Input placeholder="请输入邮编" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="经纬度">
                <Input.Group compact>
                  <Form.Item name="lon" noStyle>
                    <Input placeholder="请输入经度" style={{ width: '48%' }} />
                  </Form.Item>
                  <Form.Item noStyle>
                    <span style={{ width: '4%' }} className={styles.lastBorder}>
                      -
                    </span>
                  </Form.Item>
                  <Form.Item name="lat" noStyle>
                    <Input style={{ width: '48%' }} placeholder="请输入维度" />
                  </Form.Item>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="排序" name="seq">
                <InputNumber min={0} defaultValue={0} precision={0} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="状态"
                name="status"
                valuePropName="checked"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Switch />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default CityModule
