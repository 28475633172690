// servers/system

// Selectors

// Actions

// Action Creators

// init data
import {
  _addOrg,
  _editeOrg,
  _getOrgList,
  _orgDetails,
  _removeOrg,
} from 'servers/system/org'
import { changeList } from '../../../Selectors/Common/tree'
// 登录
export const getOrgList = async (data = {}) => {
  const res = await _getOrgList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res, 'orgId'))
  })
}
// 删除组织
export const removeOrg = async (data = {}) => {
  const res = await _removeOrg(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取组织详情
export const orgDetails = async (data = {}) => {
  const res = await _orgDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改组织
export const editeOrg = async (data = {}) => {
  const res = await _editeOrg(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增组织
export const addOrg = async (data = {}) => {
  const res = await _addOrg(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
