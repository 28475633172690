import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  message,
} from 'antd'
import UploadFile from 'components/UploadFile'
import moment from 'moment'
import React, { useEffect, useState } from 'react'

import {
  addContract,
  contractDetails,
  editeContract,
} from 'reduxs/operation/contract'
import { statusList } from '../Containers/_help'
import styles from './add.module.less'
const { Option } = Select
const { RangePicker } = DatePicker
const format = 'YYYY-MM-DD HH:mm:ss'
const AddModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess, companyList, channelList } =
    props
  const [loading, setLoading] = useState(false)
  const [fileList, setFileList] = useState([])
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 获取详情
  const getDetails = async () => {
    const { companyContractId } = editeData
    if (companyContractId) {
      const res = await contractDetails(companyContractId)
      if (res.result === 200) {
        const { data } = res
        setFileList(data.files || [])
        data.time = [moment(data.stime, format), moment(data.etime, format)]
        form.setFieldsValue(data)
      }
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    const { companyContractId } = editeData
    const { time } = values
    values.files = fileList
    if (time) {
      values.stime = moment(values.time[0]).format(format)
      values.etime = moment(values.time[1]).format(format)
      delete values.time
    }
    if (companyContractId) {
      editeList(values)
      return
    }
    addList(values)
  }
  // 编辑
  const editeList = async values => {
    const { companyContractId } = editeData
    values.companyContractId = companyContractId
    const res = await editeContract(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改合同成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await addContract(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增合同成功~')
      handSuccess()
    }
  }
  const handFile = str => {
    const list = [...fileList]
    list.push(str)
    setFileList(list)
  }
  // 删除文件
  const removeIcon = str => {
    const list = [...fileList].filter(item => item !== str)
    setFileList(list)
  }
  return (
    <Modal
      width={600}
      title={editeData.id ? '编辑合同' : '新增合同'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="公司"
                name="companyId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择公司',
                  },
                ]}
              >
                <Select placeholder="请选择公司">
                  {companyList.map((item, index) => (
                    <Option value={item.companyId} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="渠道商"
                name="channelMchId"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择渠道商',
                  },
                ]}
              >
                <Select placeholder="请选择渠道商">
                  {channelList.map((item, index) => (
                    <Option value={item.channelMchId} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="名称"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="编号"
                name="code"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入编号',
                  },
                ]}
              >
                <Input placeholder="请输入编号" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="有效期"
                name="time"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入有效期',
                  },
                ]}
              >
                <RangePicker showTime />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="状态"
                name="status"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择状态',
                  },
                ]}
              >
                <Select placeholder="请选择状态">
                  {statusList.map((item, index) => (
                    <Option value={item.type} key={index}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="合同" name="files">
                <UploadFile
                  handSuccess={handFile}
                  fileList={fileList}
                  removeIcon={removeIcon}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddModal
