import {
  _companyDetails,
  _addCompany,
  _editeCompany,
  _getCompanyList,
  _removeCompany,
} from 'servers/operation/company'
import { changeList } from '../../../Selectors/Common/index'
// 获取业务公司列表
export const getCompanyList = async (data = {}) => {
  const res = await _getCompanyList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除业务公司
export const removeCompany = async (data = {}) => {
  const res = await _removeCompany(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改业务公司
export const editeCompany = async (data = {}) => {
  const res = await _editeCompany(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增业务公司
export const addCompany = async (data = {}) => {
  const res = await _addCompany(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取业务公司详情
export const companyDetails = async (data = {}) => {
  const res = await _companyDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
