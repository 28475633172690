import {
  _addMailboxType,
  _editeMailboxType,
  _getMailboxTypeList,
  _mailboxTypeDetails,
  _removeMailboxType,
} from 'servers/operation/mailboxType'
import { changeList } from '../../../Selectors/Common/index'
// 获取邮件类型列表
export const getMailboxTypeList = async (data = {}) => {
  const res = await _getMailboxTypeList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除邮件类型
export const removeMailboxType = async (data = {}) => {
  const res = await _removeMailboxType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改邮件类型
export const editeMailboxType = async (data = {}) => {
  const res = await _editeMailboxType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增邮件类型
export const addMailboxType = async (data = {}) => {
  const res = await _addMailboxType(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取邮件类型详情
export const mailboxTypeDetails = async (data = {}) => {
  const res = await _mailboxTypeDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
