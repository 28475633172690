import { Button, Checkbox, Col, Form, Modal, Row, Space, message } from 'antd'
import React, { useEffect, useState } from 'react'
import {
  accountInfo,
  getAccountRole,
  setAccountRole,
} from 'reduxs/system/account'
import { getRoleList } from 'reduxs/system/role'
import styles from './add.module.less'
const RoleModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [detailsData, setDetailsData] = useState(null) // 详情数据
  const [orgList, setOrgList] = useState([]) // 部门
  const [postList, setPostList] = useState([]) // 部门
  const [roleList, setRoleList] = useState([]) // 角色列表
  useEffect(() => {
    getDetails()
    getRoleDetails()
    getOrg()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const getOrg = async () => {
    const res = await getRoleList({ paging: false, status: 1 })
    if (res.result === 200) {
      const data = res.data || []
      setRoleList(data)
    }
  }
  // 获取已关联的角色
  const getRoleDetails = async () => {
    const { powerInfoId } = editeData
    const res = await getAccountRole({ infoId: powerInfoId })
    if (res.result === 200) {
      const { data } = res
      form.setFieldsValue({
        roleIds: data || [],
      })
      console.log(res.data)
    }
  }
  // 获取详情
  const getDetails = async () => {
    if (editeData?.powerInfoId) {
      const { powerInfoId } = editeData
      const res = await accountInfo(powerInfoId)
      if (res.result === 200) {
        const { data } = res
        const { orgs, posts } = editeData
        setDetailsData(data)
        setOrgList((orgs || []).map(item => item.name))
        setPostList((posts || []).map(item => item.name))
      }
    }
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  const onFinish = async values => {
    setLoading(false)
    values.infoId = editeData.powerInfoId
    const res = await setAccountRole(values)
    if (res.result === 200) {
      message.success('关联角色成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={600}
      title="员工角色"
      open={editeData?.roleStatus}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="姓名">{detailsData?.name}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="工号">{detailsData?.number}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="登陆账号">{detailsData?.account}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="部门/岗位">
                {orgList.join('、')}/{postList.join('、')}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="角色" name="roleIds">
                <Checkbox.Group style={{ width: '100%' }}>
                  <Row>
                    {roleList.map((item, index) => (
                      <Col span={8} key={index}>
                        <Checkbox
                          value={item.roleId}
                          style={{ lineHeight: '32px' }}
                        >
                          {item.name}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default RoleModal
