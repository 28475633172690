import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取业务公司列表
export const _getProtocolList = async params => {
  return await request({
    method: 'GET',
    url: Api.OPERATION.company.protocolList,
    params: params,
  })
}
// 删除业务公司
export const _removeProtocol = async params => {
  const path = btnApiList.protocolDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.OPERATION.company.protocolInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改业务公司
export const _editeProtocol = async params => {
  const path = btnApiList.protocolEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.OPERATION.company.protocolInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增业务公司
export const _addProtocol = async params => {
  const path = btnApiList.protocolAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.OPERATION.company.protocolInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取业务公司详情
export const _protocolDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.company.protocolInfo}/${params}`,
  })
}
// 获取协议内容
export const _getProContent = async (id, lang) => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.company.protocolContent}/${id}/${lang}`,
  })
}
// 编辑协议内容
export const _editeProContent = async data => {
  return await request({
    method: 'POST',
    url: Api.OPERATION.company.protocolContent,
    data: data,
  })
}
