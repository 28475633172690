import { Button, Col, Form, Modal, Row, Space, Spin, message } from 'antd'
import Checkbox from 'components/Checkbox/Index'
import React, { useEffect, useState } from 'react'
import { getMenuList } from 'reduxs/system/menu'
import { getRoleMenu, setRoleMenu } from 'reduxs/system/role'
import styles from './add.module.less'
const ContentModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess } = props
  const [loading, setLoading] = useState(false)
  const [menuMist, setMenuList] = useState([])
  const [boxkey, setboxkey] = useState(false)
  const [arrayIndex, setArrayIndex] = useState(0)
  const [checkList, setCheckList] = useState([]) // 已选择的数据
  const [detailsLoading, setDetailsLoading] = useState(false)
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取已关联的菜单
  const getDetails = async () => {
    setDetailsLoading(true)
    const res = await getRoleMenu({ roleId: editeData.roleId })
    if (res.result === 200) {
      getMenu(res.data || [])
    } else {
      setDetailsLoading(false)
    }
  }
  const getMenu = async checkList => {
    const res = await getMenuList({ tree: true, status: 1, paging: false })
    setLoading(false)
    if (res.result === 200) {
      const { data } = res
      const { dataList, checkData } = getTreeList(data, checkList)
      const num = getCurrentTreeDeep({
        children: dataList,
      })
      setCheckList(checkData)
      setArrayIndex(num - 1)
      setMenuList(dataList)
      setboxkey(!boxkey)
      setDetailsLoading(false)
    } else {
      setDetailsLoading(false)
    }
  }
  // 组装树
  const getTreeList = (dataList = [], checkList = [], checkData = []) => {
    dataList.forEach(item => {
      if (checkList.includes(item.menuId)) {
        item.checked = true
        checkData.push(item.menuId)
      }
      if (item.children?.length) {
        getTreeList(item.children, checkList, checkData)
      }
    })
    return { dataList, checkData }
  }
  //求树的最大深度
  const getCurrentTreeDeep = node => {
    if (!node.children || node.children.length === 0) {
      return 1
    }
    const maxChildrenDepth = [...node.children].map(v => getCurrentTreeDeep(v))
    return 1 + Math.max(...maxChildrenDepth)
  }
  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  const onFinish = async () => {
    const data = {
      menuIds: checkList,
      roleId: editeData.roleId,
    }
    const res = await setRoleMenu(data)
    setLoading(false)
    if (res.result === 200) {
      message.success('关联成功~')
      handSuccess()
    }
  }
  const getCheckList = list => {
    const checkList = getList(list)
    setCheckList(checkList)
  }
  const getList = (list, clist = []) => {
    list.forEach(item => {
      if (item.checked === true) {
        item.resourceId = item.menuId
        item.hidden = false
        clist.push(item.menuId)
      }
      if (item.children) {
        getList(item.children, clist)
      }
    })
    return clist
  }
  return (
    <Modal
      width={1000}
      title="角色权限"
      open={editeData?.roleStatus}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Spin tip="加载中" spinning={detailsLoading}>
          <Form
            form={form}
            onFinish={onFinish}
            onFinishFailed={() => setLoading(false)}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item label="姓名">{editeData?.name}</Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="编号">{editeData?.code}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="说明">{editeData?.descr}</Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label="菜单权限" name="name">
                  <Checkbox
                    permissionList={menuMist}
                    key={boxkey}
                    ArrayIndex={arrayIndex}
                    updateTatus={true}
                    getCheckList={getCheckList}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </div>
    </Modal>
  )
}
export default ContentModal
