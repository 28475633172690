import {
  _addClasz,
  _claszDef,
  _claszDetails,
  _editeClasz,
  _getClaszList,
  _removeClasz,
} from 'servers/advertising/clasz'
import { changeList } from '../../../Selectors/Common/tree'
// 获取业务分类列表
export const getClaszList = async (data = {}) => {
  const res = await _getClaszList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res, 'claszId'))
  })
}
// 删除业务分类
export const removeClasz = async (data = {}) => {
  const res = await _removeClasz(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改业务分类
export const editeClasz = async (data = {}) => {
  const res = await _editeClasz(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增业务分类
export const addClasz = async (data = {}) => {
  const res = await _addClasz(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取业务分类详情
export const claszDetails = async (data = {}) => {
  const res = await _claszDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 业务分类默认
export const claszDef = async (data = {}) => {
  const res = await _claszDef(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
