import {
  _addPlan,
  _benefitSeq,
  _deletePlanBenefit,
  _editePlan,
  _editePlanContent,
  _getPlanList,
  _lanBenefit,
  _planContent,
  _planCopy,
  _planDetails,
  _planInfoSel,
  _removePlan,
  _setPlanBenefit,
} from 'servers/advertising/advertisingPlan'
import { changeList } from '../../../Selectors/Programme'
// 获取投放方案列表
export const getPlanList = async (data = {}) => {
  const res = await _getPlanList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res, data))
  })
}
// 删除投放方案
export const removePlan = async (data = {}) => {
  const res = await _removePlan(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改投放方案
export const editePlan = async (data = {}) => {
  const res = await _editePlan(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增投放方案
export const addPlan = async (data = {}) => {
  const res = await _addPlan(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取投放方案详情
export const planDetails = async (data = {}) => {
  const res = await _planDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 投放方案选中
export const planInfoSel = async (data = {}) => {
  const res = await _planInfoSel(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取方案内容
export const planContent = async (id, lang) => {
  const res = await _planContent(id, lang)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改方案内容
export const editePlanContent = async data => {
  const res = await _editePlanContent(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 绑定权益方案
export const setPlanBenefit = async data => {
  const res = await _setPlanBenefit(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 删除权益方案
export const deletePlanBenefit = async (id, data) => {
  const res = await _deletePlanBenefit(id, data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 权益方案列表
export const lanBenefit = async data => {
  const res = await _lanBenefit(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
//修改权益方案序号
export const benefitSeq = async (id1, id2, seq) => {
  const res = await _benefitSeq(id1, id2, seq)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 方案复制
export const planCopy = async data => {
  const res = await _planCopy(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
