import {
  Button,
  Col,
  Form,
  Image,
  InputNumber,
  Modal,
  Row,
  Table,
  message,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  deleteCountryType,
  getPaymentType,
  paymentTypeSeq,
} from 'reduxs/operation/country'
import { getPayTypeList } from 'reduxs/operation/payType'
import PayTypeModal from './PayTypeModal'
import styles from './add.module.less'
const PayModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel } = props
  const [status, setStatus] = useState(false)
  const [pageData, setPageData] = useState([])
  const [typeList, setTypeList] = useState([])
  const [number, setNumber] = useState(0)
  const [detailsLoading, setDetailsLoading] = useState(false)
  useEffect(() => {
    getPageData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取列表
  const getList = async list => {
    const res = await getPayTypeList({ status: 1, paging: false })
    if (res.result === 200) {
      const data = res.data || []
      const noList = []
      const isList = []
      data.forEach(item => {
        const findData = list.find(
          l => l.paymentType * 1 === item.paymentTypeId * 1
        )
        if (findData) {
          item.seq = findData.seq
          isList.push(item)
        } else {
          noList.push(item)
        }
      })
      noList.forEach((item, index) => (item.index = index + 1))
      isList.forEach((item, index) => (item.index = index + 1))
      setPageData(isList || [])
      setTypeList(noList || [])
    }
  }
  // 获取已添加的数据
  const getPageData = async () => {
    setDetailsLoading(true)
    const { countryId } = editeData
    const res = await getPaymentType(countryId)
    setDetailsLoading(false)
    if (res.result === 200) {
      const { data } = res
      getList(data || [])
    }
  }
  const addType = () => {
    setStatus(true)
  }
  // 添加成功
  const handSuccess = () => {
    setStatus(false)
    getPageData()
  }
  // 编辑
  const handUpdate = row => {
    const { index } = row
    const list = [...pageData]
    list.forEach(item => {
      item.isUpdate = false
      if (item.index === index) {
        item.isUpdate = true
        setNumber(item.seq)
      }
    })
    setPageData(list)
  }
  // 取消编辑
  const handBack = () => {
    const list = [...pageData]
    list.forEach(item => {
      item.isUpdate = false
    })
    setPageData(list)
  }
  // 监听input的变化
  const handSeq = e => {
    setNumber(e)
  }
  // 保存序号
  const handSave = async row => {
    setDetailsLoading(true)
    const { countryId } = editeData
    const { paymentMchTypeId, paymentTypeId } = row
    const res = await paymentTypeSeq(
      countryId,
      paymentMchTypeId,
      paymentTypeId,
      number
    )
    if (res.result === 200) {
      message.success('保存成功~')
      getPageData()
    } else {
      setDetailsLoading(false)
    }
  }
  // 删除类型
  const handDelete = async row => {
    setDetailsLoading(true)
    const { countryId } = editeData
    const { paymentMchTypeId, paymentTypeId } = row
    const res = await deleteCountryType(
      countryId,
      paymentMchTypeId,
      paymentTypeId
    )
    if (res.result === 200) {
      message.success('保存成功~')
      getPageData()
    } else {
      setDetailsLoading(false)
    }
  }
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 70,
    },
    {
      title: '商户类型',
      dataIndex: 'paymentMchType',
      key: 'paymentMchType',
      render: row => row?.name || '',
    },
    {
      title: 'ICON',
      dataIndex: 'icon',
      key: 'icon',
      render: text => {
        return text ? <Image src={text} width={24} /> : text
      },
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: '代码',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: '排序',
      key: 'seq',
      render: row => {
        const { isUpdate } = row
        return isUpdate ? (
          <InputNumber defaultValue={row.seq} onChange={e => handSeq(e, row)} />
        ) : (
          row.seq
        )
      },
    },
    {
      title: '操作',
      key: 'change',
      render: row => {
        const { isUpdate } = row
        return !isUpdate ? (
          <>
            <Button type="link" onClick={() => handUpdate(row)}>
              编辑
            </Button>
            <Button type="link" onClick={() => handDelete(row)}>
              删除
            </Button>
          </>
        ) : (
          <>
            <Button type="link" onClick={() => handSave(row)}>
              保存
            </Button>
            <Button type="link" onClick={() => handBack(row)}>
              取消
            </Button>
          </>
        )
      },
    },
  ]
  return (
    <Modal
      width={800}
      title="支付方式"
      open={editeData?.payStatus}
      onCancel={handleCancel}
      onOk={handleCancel}
      confirmLoading={true}
      footer={[]}
    >
      <div className={styles.formData}>
        {status && (
          <PayTypeModal
            status={status}
            editeData={editeData}
            handSuccess={handSuccess}
            typeList={typeList}
            handleCancel={() => setStatus(false)}
          />
        )}

        <Form form={form}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="名称">{editeData.name}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="图标">
                {editeData.icon ? (
                  <Image src={editeData.icon} width={24} />
                ) : (
                  ''
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="支付方式">
                <Button onClick={addType}>添加</Button>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Table
                dataSource={pageData}
                rowKey={item => item.paymentTypeId} //React规范中的array都必须有key
                columns={columns}
                size="small"
                loading={detailsLoading}
                // onChange={changePage}
                pagination={false}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default PayModal
