import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取商户类型列表
export const _getMerchantTypeList = async params => {
  return await request({
    method: 'GET',
    url: Api.OPERATION.payManage.paymentTypeList,
    params: params,
  })
}
// 删除商户类型
export const _removeMerchantType = async params => {
  const path = btnApiList.channelTypeDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.OPERATION.payManage.paymentTypeInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改商户类型
export const _editeMerchantType = async params => {
  const path = btnApiList.channelTypeEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.OPERATION.payManage.paymentTypeInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增商户类型
export const _addMerchantType = async params => {
  const path = btnApiList.channelTypeAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.OPERATION.payManage.paymentTypeInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取商户类型详情
export const _merchantTypeDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.payManage.paymentTypeInfo}/${params}`,
  })
}
