import {
  Button,
  Col,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
} from 'antd'
import React, { useEffect, useState } from 'react'
import {
  questionMailAdd,
  questionMailDetail,
  questionMailEdite,
} from 'reduxs/advertising/question'
import { getMailboxTypeList } from 'reduxs/operation/mailboxType'
import styles from './index.module.less'
const { TextArea } = Input
const { Option } = Select
const AddEditeModal = props => {
  const [form] = Form.useForm()
  const { editeData, handleCancel, handSuccess, state, questionInfoId } = props
  console.log(editeData)
  const { isUpdate, questionEmailId, emailType } = editeData
  const [loading, setLoading] = useState(false)
  const [typeList, setTypeList] = useState([])
  useEffect(() => {
    getDetails()
    getTyleList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取邮件类型列表
  const getTyleList = async () => {
    const res = await getMailboxTypeList({ status: 1, paging: false })
    if (res.result === 200) {
      setTypeList(res.data || [])
    }
  }
  // 获取详情
  const getDetails = async () => {
    if (!isUpdate) {
      return
    }
    const res = await questionMailDetail(questionInfoId, emailType)
    if (res.result === 200) {
      const { data } = res
      form.setFieldsValue(data)
    }
  }

  // 编辑确定
  const handleSubmit = () => {
    setLoading(true)
    form.submit()
  }
  // 提交form表单
  const onFinish = values => {
    values.questionInfoId = questionInfoId
    if (questionEmailId) {
      editeList(values)
      return
    }
    addList(values)
  }
  // 编辑
  const editeList = async values => {
    values.questionEmailId = questionEmailId
    const res = await questionMailEdite(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('修改邮件成功~')
      handSuccess()
    }
  }
  const addList = async values => {
    const res = await questionMailAdd(values)
    setLoading(false)
    if (res.result === 200) {
      message.success('新增邮件成功~')
      handSuccess()
    }
  }
  return (
    <Modal
      width={800}
      title={isUpdate ? '编辑邮件配置' : '新增邮件配置'}
      open={editeData?._status}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={true}
      footer={[
        <Space key="space">
          <Button key="back" onClick={handleCancel}>
            取消
          </Button>
          <Button
            key="submit"
            type="primary"
            onClick={handleSubmit}
            loading={loading}
          >
            确定
          </Button>
        </Space>,
      ]}
    >
      <div className={styles.formData}>
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={() => setLoading(false)}
          initialValues={{ status: true }}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item label="测评分类">{state?.clasz?.name}</Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="名称">{state?.title}</Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="类型"
                name="emailType"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请选择类型',
                  },
                ]}
              >
                <Select placeholder="请选择类型">
                  {typeList.map(item => (
                    <Option value={item.emailType} key={item.emailType}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="标题"
                name="name"
                required={true}
                rules={[
                  {
                    required: true,
                    message: '请输入名称',
                  },
                ]}
              >
                <Input placeholder="请输入名称" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="说明" name="descr">
                <TextArea rows={4} placeholder="请输入说明" maxLength={120} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  )
}
export default AddEditeModal
