import {
  _addChannelAcc,
  _channelAccDetails,
  _editeChannelAcc,
  _getChannelAccList,
  _removeChannelAcc,
  _setChannelBack,
  _getChannelBack,
} from 'servers/operation/channelAcc'
import { changeList } from '../../../Selectors/Common/index'
// 获取渠道账户列表
export const getChannelAccList = async (data = {}) => {
  const res = await _getChannelAccList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除渠道账户
export const removeChannelAcc = async (data = {}) => {
  const res = await _removeChannelAcc(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改渠道账户
export const editeChannelAcc = async (data = {}) => {
  const res = await _editeChannelAcc(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增渠道账户
export const addChannelAcc = async (data = {}) => {
  const res = await _addChannelAcc(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取渠道账户详情
export const channelAccDetails = async (data = {}) => {
  const res = await _channelAccDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 配置渠道广告
export const setChannelBack = async (data = {}) => {
  const res = await _setChannelBack(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 配置渠道广告
export const getChannelBack = async (data = {}) => {
  const res = await _getChannelBack(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
