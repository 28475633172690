import { Button, Select } from 'antd'
import ButtonGroup, { TipButton } from 'components/ButtonGroup'
import { btnApiList } from 'constants/permisson'
import React from 'react'
const { Option } = Select
const columns = eventData => {
  const { handEdite, handRemove, handContent } = eventData
  return [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 70,
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: 120,
    },
    {
      title: 'ID',
      dataIndex: 'accountId',
      key: 'accountId',
      ellipsis: true,
    },
    {
      title: '渠道',
      dataIndex: 'ct',
      key: 'ct',
      ellipsis: true,
      width: 100,
      render: row => row?.name,
    },
    {
      title: '渠道商',
      dataIndex: 'channelMch',
      key: 'channelMch',
      width: 100,
      ellipsis: true,
      render: row => {
        return row?.name
      },
    },
    {
      title: '业务公司',
      dataIndex: 'company',
      key: 'company',
      width: 100,
      ellipsis: true,
      render: row => {
        return row?.name
      },
    },
    {
      title: '合同',
      dataIndex: 'companyContract',
      key: 'companyContract',
      width: 100,
      ellipsis: true,
      render: row => {
        return row?.name
      },
    },
    {
      title: '投放方式',
      dataIndex: 'putinVo',
      key: 'putinVo',
      width: 100,
      ellipsis: true,
      render: row => row?.name,
    },
    {
      title: '业务域名',
      dataIndex: 'domain',
      key: 'domain',
      ellipsis: true,
      width: 200,
    },
    {
      title: '说明',
      dataIndex: 'descr',
      key: 'descr',
      ellipsis: true,
      width: 160,
    },
    {
      title: '时间',
      dataIndex: 'ctime',
      key: 'ctime',
      ellipsis: true,
      width: 160,
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      width: 70,
      render: status => {
        return status === 1 ? '启用' : '禁用'
      },
    },
    {
      title: '操作',
      key: 'changge',
      fixed: 'right',
      width: 240,
      render: row => {
        return (
          <>
            <ButtonGroup
              onClick={() => handEdite(row)}
              btnId={btnApiList.channelmMchEdite}
              type="link"
            >
              编辑
            </ButtonGroup>
            <ButtonGroup
              onClick={() => handContent(row)}
              btnId={btnApiList.channelmMchAdvert}
              type="link"
            >
              广告配置
            </ButtonGroup>
            <Button type="link" onClick={() => handContent(row)}>
              广告配置
            </Button>
            <TipButton
              title="你确定要该渠道商吗？"
              onConfirm={() => handRemove(row)}
              okText="确定"
              cancelText="取消"
              btnId={btnApiList.channelAccDelete}
            >
              <Button type="link">删除</Button>
            </TipButton>
          </>
        )
      },
    },
  ]
}
// 封装options
const getOptions = (list = [], key, name = 'name') => {
  if (!list || !list.length) {
    return []
  }
  return list.map((item, index) => (
    <Option value={item[key]} key={index}>
      {item[name]}
    </Option>
  ))
}
export { columns, getOptions }
