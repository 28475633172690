import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取渠道类型列表
export const _getChannelTypeList = async params => {
  return await request({
    method: 'GET',
    url: Api.OPERATION.channel.channelTypeList,
    params: params,
  })
}
// 删除渠道类型
export const _removeChannelType = async params => {
  const path = btnApiList.channelTypeDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.OPERATION.channel.channelTypeInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改渠道类型
export const _editeChannelType = async params => {
  const path = btnApiList.channelTypeEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.OPERATION.channel.channelTypeInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增渠道类型
export const _addChannelType = async params => {
  const path = btnApiList.channelTypeAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.OPERATION.channel.channelTypeInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取渠道类型详情
export const _channelTypeDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.OPERATION.channel.channelTypeInfo}/${params}`,
  })
}
