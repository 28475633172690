import { Button, message, Modal, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { getAdsLang } from 'reduxs/advertising/advertising'
import styles from './index.module.less'
const MoreModal = props => {
  const { editeData, handleCancel } = props
  const { adsId } = editeData
  const [tableData, setTabData] = useState([])
  const [detailsLoading, setDetailsLoading] = useState(false)
  useEffect(() => {
    getDetails()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // 获取语言列表
  const getDetails = async () => {
    setDetailsLoading(true)
    const res = await getAdsLang(adsId)
    setDetailsLoading(false)
    if (res.result === 200) {
      const list = res.data || []
      list.forEach((item, index) => {
        item.index = index + 1
      })
      setTabData(list)
    }
  }
  // 复制
  const handCopy = row => {
    const { url } = row
    var textarea = document.createElement('textarea')
    textarea.value = url
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)
    message.success('复制成功')
  }
  const columns = [
    {
      title: '序号',
      dataIndex: 'index',
      key: 'index',
      width: 70,
    },
    {
      title: '语言',
      dataIndex: 'name',
      key: 'name',
      width: 100,
    },
    {
      title: '链接',
      dataIndex: 'url',
      key: 'url',
    },
    {
      title: '操作',
      key: 'changge',
      fixed: 'right',
      width: 100,
      render: row => {
        return (
          <Button type="link" onClick={() => handCopy(row)}>
            复制
          </Button>
        )
      },
    },
  ]
  return (
    <Modal
      width={600}
      title="投放链接"
      open={editeData.type ? true : false}
      onCancel={handleCancel}
      onOk={handleCancel}
      confirmLoading={true}
      footer={[]}
    >
      <div className={styles.formData}>
        <Table
          dataSource={tableData}
          rowKey={item => item.lang} //React规范中的array都必须有key
          columns={columns}
          size="small"
          pagination={false}
          loading={detailsLoading}
        />
      </div>
    </Modal>
  )
}
export default MoreModal
