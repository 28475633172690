import OSS from 'ali-oss'
import { getfildUrl } from 'reduxs/login'

// 上传到aws
const handleUpload = async file => {
  console.log('开始了')
  if (!file) {
    console.log('没有更多的了')
    return
  }
  const res = await getfildUrl({})
  const data = res.result === 200 ? JSON.parse(res.data) : null
  if (!data) {
    console.log('没有')
    return
  }
  // AWS.config.update({
  //   accessKeyId: data.accessKeyId, // 替换为你的AWS访问密钥ID
  //   secretAccessKey: data.secretAccessKey, // 替换为你的AWS秘密访问密钥
  // })
  const fileName = `${Date.now()}`
  const client = new OSS({
    region: data.region,
    accessKeyId: data.accessKeyId,
    accessKeySecret: data.secretAccessKey,
    bucket: data.bucket,
  })
  try {
    const data = await client.put(fileName, file)
    const { url } = data
    if (url.startsWith('http:')) {
      data.url = url.replace(/http:/g, 'https:')
    }
    console.log(data)
    return data
    // 这里可以添加上传成功后的处理逻辑
  } catch (error) {
    console.error('Error uploading file:', error)
    // 这里可以添加上传失败后的处理逻辑
  }
}
export { handleUpload }
