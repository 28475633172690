import Api from 'apis/Api.js'
import { btnApiList } from 'constants/permisson'
import request from 'utils/Request'
import { getBtnId } from 'utils/Tools'

// 获取结果列表
export const _getQuestionResultList = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.question.questionResultList,
    params: params,
  })
}
// 删除结果
export const _removeQuestionResult = async params => {
  const path = btnApiList.answerDelete
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'DELETE',
    url: `${Api.ADVERSRIING.question.questionResultInfo}/${params}`,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 修改结果
export const _editeQuestionResult = async params => {
  const path = btnApiList.answerEdite
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'PUT',
    url: Api.ADVERSRIING.question.questionResultInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 新增结果
export const _addQuestionResult = async params => {
  const path = btnApiList.answerAdd
  const btnId = getBtnId(path)
  if (!btnId) {
    return { code: 0 }
  }
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.question.questionResultInfo,
    data: params,
    headers: {
      'btn-id': btnId,
    },
  })
}
// 获取结果详情
export const _QuestionResultDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.question.questionResultInfo}/${params}`,
  })
}
// 获取多语言
export const _questionResultContent = async (id, lang) => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.question.questionResultContent}/${id}/${lang}`,
  })
}
// 编辑内容
export const _editeResultContent = async data => {
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.question.questionResultContent,
    data: data,
  })
}
