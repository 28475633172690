export const changeList = res => {
  if (res.result !== 200) {
    return res
  }
  const { data } = res
  if (!data) {
    return res
  }
  const list = []
  data.forEach(item => {
    const adsPlan = item.adsPlan || []
    adsPlan.forEach(plan => {
      plan.countryName = item.name
      list.push(plan)
    })
  })
  list.forEach((item, index) => (item.index = index + 1))
  res.data = list
  return res
}
