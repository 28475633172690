import {
  _addContract,
  _contractDetails,
  _editeContract,
  _getContractList,
  _removeContract,
} from 'servers/operation/contract'
import { changeList } from '../../../Selectors/Common/index'
// 获取合同管理列表
export const getContractList = async (data = {}) => {
  const res = await _getContractList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除合同管理
export const removeContract = async (data = {}) => {
  const res = await _removeContract(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改合同管理
export const editeContract = async (data = {}) => {
  const res = await _editeContract(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增合同管理
export const addContract = async (data = {}) => {
  const res = await _addContract(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取合同管理详情
export const contractDetails = async (data = {}) => {
  const res = await _contractDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
