import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import React, { useEffect, useState } from 'react'
// import { DomEditor } from '@wangeditor/editor'
import { handleUpload } from 'utils/AliOss'
const WangEditor = ({ setValue, htmlValue, handChang }) => {
  // editor 实例
  const [editor, setEditor] = useState(null) // JS 语法
  // 编辑器内容
  const [html, setHtml] = useState('')
  useEffect(() => {
    setHtml(htmlValue)
  }, [htmlValue])

  // 工具栏配置
  const toolbarConfig = {
    // 'fontSize',
    // 'fontFamily',
    // 'lineHeight',
    // 'color',
    // 'bgColor',
    // 'bold',
    excludeKeys: [
      'group-more-style',
      'headerSelect', //正文
      'blockquote', //引号
      '|', //分隔线
      'underline',
      'italic',
      '|',
      '|',
      'bulletedList',
      'numberedList',
      'uploadImage',
      // 'image', // 插入图片
      'table', // 表格
      'undo', // 撤销
      'redo', // 重复
    ], // 移除不需要的工具栏按钮
  } // JS 语法

  const editorConfig = {
    // JS 语法
    placeholder: '请输入内容...',
    MENU_CONF: {},

    customConfig: {
      pasteFilterStyle: false,
    },
  }

  // 修改 uploadImage 菜单配置
  editorConfig.MENU_CONF['uploadImage'] = {
    // server: '/api/upload-image',
    // fieldName: 'custom-field-name',
    // 继续写其他配置...
    // base64LimitSize: 2 * 1024 * 1024, // 2M，小于2M的用base64

    // 单个文件上传成功之后
    onSuccess(file, res) {
      // JS 语法
      console.log(`${file.name} 上传成功`, res)
    },

    // 单个文件上传失败
    onFailed(file, res) {
      // JS 语法
      console.log(`${file.name} 上传失败`, res)
    },

    // 上传错误，或者触发 timeout 超时
    onError(file, err, res) {
      // JS 语法
      console.log(`${file.name} 上传出错`, err, res)
    },
    //【注意】不需要修改的不用写，wangEditor 会去 merge 当前其他配置
    async customUpload(file, insertFn) {
      const res = await handleUpload(file)
      insertFn(res.Location, file.name, res.url)
    },
  }

  editorConfig.MENU_CONF['uploadVideo'] = {
    // 上传视频的配置
    // 单个文件的最大体积限制，默认为 10M
    maxFileSize: 10 * 1024 * 1024, // 10M
    // 单个文件上传成功之后
    onSuccess(file, res) {
      // JS 语法
      console.log(`${file.name} 上传成功`, res)
    },

    // 单个文件上传失败
    onFailed(file, res) {
      // JS 语法
      console.log(`${file.name} 上传失败`, res)
    },

    // 上传错误，或者触发 timeout 超时
    onError(file, err, res) {
      // JS 语法
      console.log(`${file.name} 上传出错`, err, res)
    },

    async customUpload(file, insertFn) {
      const res = await handleUpload(file)
      insertFn(res.url, '')
    },
  }

  // 及时销毁 editor ，重要！
  useEffect(() => {
    return () => {
      if (editor == null) return
      editor.destroy()
      setEditor(null)
      setValue && setValue(null)
    }
  }, [editor, setValue])
  const onChange = editor => {
    const htmlContent = editor.getHtml()
    console.log(htmlContent)
    setHtml(htmlContent)
    handChang(htmlContent)
  }

  return (
    <>
      <div
        style={{
          border: '1px solid #ccc',
          zIndex: 100,
          fontSize: 16,
        }}
      >
        <Toolbar
          editor={editor}
          defaultConfig={toolbarConfig}
          mode="default"
          style={{ borderBottom: '1px solid #ccc' }}
        />
        <Editor
          defaultConfig={editorConfig}
          value={html}
          onCreated={setEditor}
          onChange={onChange}
          mode="simple"
          style={{
            minHeight: '80px',
            overflowY: 'hidden',
            lineHeight: 2,
            width: '100%',
          }}
        />
      </div>
      {/* <div style={{ marginTop: '15px' }}>{html}</div> */}
    </>
  )
}

export default WangEditor
