import {
  _QuestionResultDetails,
  _addQuestionResult,
  _editeQuestionResult,
  _editeResultContent,
  _getQuestionResultList,
  _questionResultContent,
  _removeQuestionResult,
} from 'servers/advertising/questionResult'
import { changeList } from '../../../Selectors/Common/'
// 获取结果列表
export const getQuestionResultList = async (data = {}) => {
  const res = await _getQuestionResultList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res))
  })
}
// 删除结果
export const removeQuestionResult = async (data = {}) => {
  const res = await _removeQuestionResult(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改结果
export const editeQuestionResult = async (data = {}) => {
  const res = await _editeQuestionResult(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增结果
export const addQuestionResult = async (data = {}) => {
  const res = await _addQuestionResult(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取结果详情
export const questionResultDetails = async (data = {}) => {
  const res = await _QuestionResultDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 结果多语言
export const questionResultContent = async (id, lang) => {
  const res = await _questionResultContent(id, lang)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
export const editeResultContent = async data => {
  const res = await _editeResultContent(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
