import Api from 'apis/Api.js'
import request from 'utils/Request'
// 补发邮件
export const _emaiSend = async data => {
  return await request({
    method: 'POST',
    url: Api.ADVERSRIING.advertising.emaiSend,
    data: data,
  })
}
// 解析
export const _analyzing = async id1 => {
  return await request({
    method: 'POST',
    url: `${Api.ADVERSRIING.advertising.analyzing}/${id1}`,
  })
}

// 获取归因列表
export const _backAbsList = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.backAbsList,
    params: params,
  })
}
// 获取投放订单
export const _adsOrderList = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.adsOrderList,
    params: params,
  })
}
// 投放订单详情
export const _adsOrderDetails = async params => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.adsOrderDetails}/${params}`,
  })
}
// 获取投放邮件列表
export const _adsOrderMail = async params => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.adsOrderMail}/${params}`,
  })
}
// 投放订单权益
export const _adsOrderBenefit = async params => {
  return await request({
    method: 'GET',
    url: `${Api.ADVERSRIING.adsOrderBenefit}/${params}`,
  })
}
//支付订单
export const _payOrderList = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.payOrderList,
    params: params,
  })
}
// 导出投放订单
export const _adsOrderListLoad = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.adsOrderListLoad,
    responseType: 'blob',
    params: params,
    timeout: 1000000000,
  })
}
// 导出支付订单
export const _payOrderListLoad = async params => {
  return await request({
    method: 'GET',
    url: Api.ADVERSRIING.payOrderListLoad,
    responseType: 'blob',
    params: params,
    timeout: 1000000000,
  })
}
