// servers/system

// Selectors

// Actions

// Action Creators

// init data
import {
  _addEnun,
  _editeEnun,
  _enunDetails,
  _getEnunList,
  _removeEnun,
  _enumCodeList,
} from 'servers/system/enun'
import { changeList } from '../../../Selectors/Common/tree'
// 获取字典列表
export const getEnunList = async (data = {}) => {
  const res = await _getEnunList(data)
  return new Promise(function (resolve) {
    resolve(changeList(res, 'enumId'))
  })
}
// 删除字典
export const removeEnun = async (data = {}) => {
  const res = await _removeEnun(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 修改字典
export const editeEnun = async (data = {}) => {
  const res = await _editeEnun(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 新增字典
export const addEnun = async (data = {}) => {
  const res = await _addEnun(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取字典详情
export const enunDetails = async (data = {}) => {
  const res = await _enunDetails(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
// 获取通用字段
export const enumCodeList = async (data = {}) => {
  const res = await _enumCodeList(data)
  return new Promise(function (resolve) {
    resolve(res)
  })
}
