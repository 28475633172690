import Api from 'apis/Api.js'
import request from 'utils/Request'

// 登录
export const _login = async params => {
  return await request({
    method: 'post',
    url: Api.LOGIN.login,
    data: params,
  })
}
// 获取个人信息
export const _userInfo = async params => {
  return await request({
    method: 'GET',
    url: Api.LOGIN.userInfo,
    params: params,
  })
}
// 修改个人信息
export const _editeInfo = async params => {
  return await request({
    method: 'PUT',
    url: Api.LOGIN.userInfo,
    data: params,
  })
}
// 退出登陆
export const _logout = async params => {
  return await request({
    method: 'post',
    url: Api.LOGIN.logout,
    data: params,
  })
}
// 获取按钮权限
export const _btns = async params => {
  return await request({
    method: 'GET',
    url: Api.LOGIN.btns,
    params: params,
  })
}
// 获取菜单权限
export const _menus = async params => {
  return await request({
    method: 'GET',
    url: Api.LOGIN.menus,
    params: params,
  })
}
// 获取上传到aws的参数
export const _getfildUrl = async () => {
  return await request({
    method: 'GET',
    url: Api.fildUrl,
  })
}
// 修改密码
export const _updatePwd = async params => {
  return await request({
    method: 'PUT',
    url: Api.LOGIN.updatePwd,
    data: params,
  })
}
// 获取biurl
export const _getBiurl = async params => {
  return await request({
    method: 'GET',
    url: Api.getBiurl,
    params: params,
  })
}
