// import releaseIndex from 'pages/IndexFrom/Release/Containers/Index' // 发版次数明细
import AhannelAccount from 'pages/OperationManage/AhannelAccount/Containers/Index' // 渠道账户管理
import AhannelMerchant from 'pages/OperationManage/AhannelMerchant/Containers/Index' // 渠道商管理
import AchannelType from 'pages/OperationManage/AhannelType/Containers/Index' // 渠道类型管理
import CompanyBusiness from 'pages/OperationManage/CompanyBusiness/Containers/Index' // 业务公司
import CompanyContract from 'pages/OperationManage/CompanyContract/Containers/Index' // 合同管理
import CompanyProtocol from 'pages/OperationManage/CompanyProtocol/Containers/Index' // 协议管理
import CurrencyManage from 'pages/OperationManage/CurrencyManage/Containers/Index' // 货币管理
import DomainList from 'pages/OperationManage/DomainList/Containers/Index' // 域名列表
import DomainManage from 'pages/OperationManage/DomainManage/Containers/Index' // 域名管理
import LangManage from 'pages/OperationManage/LangMange/Containers/Index' // 语言管理
import MailboxManage from 'pages/OperationManage/MailboxMange/Containers/Index' // 邮箱管理
import MailboxType from 'pages/OperationManage/MailboxType/Containers/Index' // 邮件类型
import MerchantType from 'pages/OperationManage/MerchantType/Containers/Index' // 商户类型管理
import PayMerchant from 'pages/OperationManage/PayMerchant/Containers/Index' // 支付商户管理
import PayType from 'pages/OperationManage/PayType/Containers/Index' // 支付类型管理
import RegionCountry from 'pages/OperationManage/RegionCountry/Containers/Index' // 国家管理
import RegionManage from 'pages/OperationManage/RegionManage/Containers/Index' // 地区管理
import RegionState from 'pages/OperationManage/RegionState/Containers/Index' // 大洲管理
export const PAGE_PATH = {
  operation: {
    channelAccount: '/operation/channelAccount', // 渠道账户
    channelMerchant: '/operation/channelMerchant', // 渠道商
    channelType: '/operation/channelType', // 渠道类型
    domainManage: '/operation/domainManage', // 域名管理
    domainList: '/operation/domainList', // 域名列表
    companyBusiness: '/operation/companyBusiness', // 业务公司
    companyContract: '/operation/companyContract', // 合同管理
    companyProtocol: '/operation/companyProtocol', // 协议管理
    payMerchant: '/operation/payMerchant', // 支付商户管理
    payType: '/operation/PayType', // 支付商户管理
    merchantType: '/operation/merchantType',
    regionManage: '/operation/regionManage', // 地区管理
    regionCountry: '/operation/regionCountry',
    regionState: '/operation/regionState', // 大洲管理
    mailboxManage: '/operation/mailboxManage', // 邮箱配置
    langManage: '/operation/langManage', // 语言管理
    mailboxType: '/operation/mailboxType', // 邮件类型
    currencyManage: '/operation/currencyManage', // 货币管理
  },
}

// 侧边菜单栏白名单
export const whiteList = ['/index']

// 路由
export const OpeRouter = [
  {
    name: '运维配置',
    key: '/operation',
    exact: true,
    children: [
      {
        name: '渠道管理',
        key: '/index/channel',
        exact: true,
        children: [
          {
            name: '渠道账户',
            key: '/index/channel/account',
            path: PAGE_PATH.operation.channelAccount,
            component: AhannelAccount,
            exact: true,
            breadcrumb: [
              { name: '运维配置' },
              { name: '渠道管理' },
              { name: '渠道账户' },
            ],
          },
          {
            name: '渠道商',
            key: '/index/channel/merchant',
            path: PAGE_PATH.operation.channelMerchant,
            component: AhannelMerchant,
            exact: true,
            breadcrumb: [
              { name: '运维配置' },
              { name: '渠道管理' },
              { name: '渠道商' },
            ],
          },
          {
            name: '渠道类型',
            key: '/index/channel/type',
            path: PAGE_PATH.operation.channelType,
            component: AchannelType,
            exact: true,
            breadcrumb: [
              { name: '运维配置' },
              { name: '渠道管理' },
              { name: '渠道类型' },
            ],
          },
        ],
      },
      {
        name: '域名管理',
        key: '/index/domain/manage',
        exact: true,
        children: [
          {
            name: '业务域名',
            key: '/index/domain/index',
            path: PAGE_PATH.operation.domainManage,
            component: DomainManage,
            exact: true,
            breadcrumb: [
              { name: '运维配置' },
              { name: '域名管理' },
              { name: '业务域名' },
            ],
          },
          {
            name: '域名列表',
            key: '/index/domain/domainList',
            path: PAGE_PATH.operation.domainList,
            component: DomainList,
            exact: true,
            breadcrumb: [
              { name: '运维配置' },
              { name: '域名管理' },
              { name: '域名列表' },
            ],
          },
        ],
      },
      {
        name: '公司管理',
        key: '/index/operation/company',
        exact: true,
        children: [
          {
            name: '业务公司',
            key: '/index/company/business',
            path: PAGE_PATH.operation.companyBusiness,
            component: CompanyBusiness,
            exact: true,
            breadcrumb: [
              { name: '运维配置' },
              { name: '公司管理' },
              { name: '业务公司' },
            ],
          },
          {
            name: '合同管理',
            key: '/index/company/contract',
            path: PAGE_PATH.operation.companyContract,
            component: CompanyContract,
            exact: true,
            breadcrumb: [
              { name: '运维配置' },
              { name: '公司管理' },
              { name: '合同管理' },
            ],
          },
          {
            name: '协议管理',
            key: '/index/company/protocol',
            path: PAGE_PATH.operation.companyProtocol,
            component: CompanyProtocol,
            exact: true,
            breadcrumb: [
              { name: '运维配置' },
              { name: '公司管理' },
              { name: '协议管理' },
            ],
          },
        ],
      },
      {
        name: '支付管理',
        key: '/index/pay/manage',
        exact: true,
        children: [
          {
            name: '支付商户',
            key: '/index/pay/merchant',
            path: PAGE_PATH.operation.payMerchant,
            component: PayMerchant,
            exact: true,
            breadcrumb: [
              { name: '运维配置' },
              { name: '支付管理' },
              { name: '支付商户' },
            ],
          },
          {
            name: '商户类型',
            key: '/index/company/type',
            path: PAGE_PATH.operation.merchantType,
            component: MerchantType,
            exact: true,
            breadcrumb: [
              { name: '运维配置' },
              { name: '支付管理' },
              { name: '商户类型' },
            ],
          },
          {
            name: '支付类型',
            key: '/index/pay/type',
            path: PAGE_PATH.operation.payType,
            component: PayType,
            exact: true,
            breadcrumb: [
              { name: '运维配置' },
              { name: '支付管理' },
              { name: '支付类型' },
            ],
          },
        ],
      },
      {
        name: '地区管理',
        key: '/index/region/region',
        exact: true,
        children: [
          {
            name: '地区管理',
            key: '/index/region/manageIndex',
            path: PAGE_PATH.operation.regionManage,
            component: RegionManage,
            exact: true,
            breadcrumb: [],
          },
          {
            name: '国家管理',
            key: '/index/region/country',
            path: PAGE_PATH.operation.regionCountry,
            component: RegionCountry,
            exact: true,
            breadcrumb: [
              { name: '运维配置' },
              { name: '地区管理' },
              { name: '国家管理' },
            ],
          },
          {
            name: '大洲管理',
            key: '/index/region/state',
            path: PAGE_PATH.operation.regionState,
            component: RegionState,
            exact: true,
            breadcrumb: [
              { name: '运维配置' },
              { name: '地区管理' },
              { name: '大洲管理' },
            ],
          },
        ],
      },
      {
        name: '邮箱管理',
        key: '/index/operation/mailbox',
        exact: true,
        children: [
          {
            name: '邮箱配置',
            key: '/index/operation/mailboxConfig',
            path: PAGE_PATH.operation.mailboxManage,
            component: MailboxManage,
            exact: true,
            breadcrumb: [{ name: '运维配置' }, { name: '邮箱配置' }],
          },
          {
            name: '邮件类型',
            key: '/index/operation/mailboxType',
            path: PAGE_PATH.operation.mailboxType,
            component: MailboxType,
            exact: true,
            breadcrumb: [
              { name: '运维配置' },
              { name: '邮箱配置' },
              { name: '邮件类型' },
            ],
          },
        ],
      },
      {
        name: '语言管理',
        key: '/index/operation/lang',
        path: PAGE_PATH.operation.langManage,
        component: LangManage,
        exact: true,
        breadcrumb: [{ name: '运维配置' }, { name: '语言管理' }],
      },
      {
        name: '货币管理',
        key: '/index/operation/currency',
        path: PAGE_PATH.operation.currencyManage,
        component: CurrencyManage,
        exact: true,
        breadcrumb: [{ name: '运维配置' }, { name: '货币管理' }],
      },
    ],
  },
]

export default OpeRouter
